import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE } from '../../../../../constants/Routes';
import { toggleSnackBar } from '../../../../../redux/Reducer/Utils';
import { resetPasswordViaToken } from '../../../../../services/Auth';
import { ResetPasswordValidationSchema } from '../../../../../utils/ValidationSchema/Auth';
import { Box, Grid, SnackBar, Typography } from '../../../../Containers/index';
const TwoFa = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().search;
  const token = new URLSearchParams(location).get('token');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [otp, setOtp] = useState('');
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: ResetPasswordValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      if (
        values.password.trim().toLowerCase() !==
        values.confirmPassword.trim().toLowerCase()
      ) {
        setIsLoading(false);

        setErrorMsg('Both_passwords_should_be_same');
        // dispatch(
        //   toggleSnackBar({
        //     isOpen: true,
        //     type: 'error',
        //     message: t('Both_passwords_should_be_same'),
        //   })
        // );
        return;
      }
      if (
        values.password.toLowerCase() === values.confirmPassword.toLowerCase()
      ) {
        const params = {
          token: token,
          new_password: values.password
        };
        try {
          let res = await resetPasswordViaToken(params);
          navigate(ROUTE.ROOT);
          dispatch(
            toggleSnackBar({
              isOpen: true,
              type: 'success',
              message: res?.data?.message
            })
          );
        } catch (error) {
          dispatch(
            toggleSnackBar({
              isOpen: true,
              type: 'error',
              message: error
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    }
  });
  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  return (
    <Box>
      <SnackBar />
      <Typography
        my={4}
        sx={{
          fontWeight: 900,
          fontSize: '24px',
          fontStyle: 'normal'
        }}
      >
        {t('Enter_Email_OTP')}
      </Typography>
      <Typography
        my={4}
        sx={{
          fontWeight: 400,
          fontSize: '16px',
          fontStyle: 'normal'
        }}
      >
        {t('Otp_Sub_Title')}
      </Typography>
      <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
        <OtpInput
          separator={
            <Grid
              sx={{
                lineHeight: '24px',
                fontWeight: 600,
                fontSize: '16px'
              }}
            >
              <strong>-</strong>
            </Grid>
          }
          onChange={handleChange}
          value={otp}
          numInputs={6}
          inputStyle={{
            width: '100%',
            height: '40px',
            margin: '0px 8px',
            borderRadius: '4px',
            border: '1px solid rgba(0,0,0,0.3)'
          }}
        />
      </Grid>
      {errorMsg !== '' && (
        <Box>
          <Box
            sx={{
              color: 'error.main',
              fontSize: '0.75rem',
              ml: 4
            }}
          >
            {t(errorMsg)}
          </Box>
        </Box>
      )}
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        fullWidth
        variant='contained'
        onClick={formik.handleSubmit}
        sx={{
          my: 2,
          textTransform: 'none',
          '& .MuiCircularProgress-root ': {
            color: '#ffff'
          }
        }}
      >
        {t('Submit')}
      </LoadingButton>
      <Grid container display='flex' justifyContent='space-between' mt={2}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Box
            color='primary.main'
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(ROUTE.ROOT);
            }}
          >
            {t('Back_to_login')}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TwoFa;
