import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
  Paper,
} from '../../../../Containers/index';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../../constants/Icons';
import { ROUTE } from '../../../../../constants/Routes';
import {
  handleBDF,
  handleBNR,
  handleBottomDrawer,
  handleChannel,
  handleDBG,
  handleRabcOne,
  handleRabcUsers,
  handleUNO,
} from '../../../../../redux/Reducer/Auth';
import LogoutModal from '../../../Auth/Components/Logout';
import { menu } from '../SideDrawer/Menu';
import BottomDrawer from './BottomDrawer';

const useStyles = makeStyles({
  list: {
    '& .MuiTypography-root ': {
      // fontWeight: 500,
      // fontSize: '14px',
    },
    '& .MuiListItemIcon-root , .MuiListItemText-root': {
      color: 'black',
    },
    '& .MuiSvgIcon-root': {
      fill: 'black',
    },
    '& .MuiBadge-badge': {
      color: 'red',
      backgroundColor: 'red',
      top: '2px',
      right: '6px',
    },
    '& .MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected': {
      backgroundColor: '#17236E',

      '& .MuiListItemIcon-root , .MuiListItemText-root': {
        color: '#ffff',
      },
      '& .MuiSvgIcon-root': {
        fill: '#ffff',
      },
    },
  },
});
function BottomNavigationBar() {
  const classes = useStyles();
  const { notification, userData } = useSelector((state) => state?.user);
  const { isBottomDrawer } = useSelector((state) => state.auth);
  const changedValue = isBottomDrawer?.value;
  const [rabcRoleOne, setRabcRoleOne] = useState(false);
  const [rabcRoleUsers, setRabcRoleUsers] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isProxtera, setIsProxtera] = useState(false);
  const { isDBG, isChannel, isBNR, isBDF, isUNO } = useSelector(
    (state) => state?.auth
  );
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  const [value, setValue] = useState('');
  const [logoutOpen, setLogoutOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const toggleLogoutModal = (val) => {
    setLogoutOpen(val);
  };
  useEffect(() => {
    if (userData?.roles) {
      if (
        userData?.roles?.includes('PARTNER_SUPER_ADMIN') ||
        userData?.roles?.includes('PARTNER_ADMIN')
      ) {
        setIsProxtera(true);
      }
      if (userData?.roles?.some((v) => v.includes('USER'))) {
        dispatch(handleRabcUsers(true));
        setRabcRoleUsers(true);
      } else {
        dispatch(handleRabcUsers(false));
        setRabcRoleUsers(false);
      }
      if (userData?.roles?.some((v) => v.includes('DBG'))) {
        dispatch(handleDBG(true));
      }
      if (userData?.roles?.some((v) => v.includes('CHANNEL'))) {
        dispatch(handleChannel(true));
      }
      if (userData?.roles?.some((v) => v.includes('BNR'))) {
        dispatch(handleBNR(true));
      }
      if (userData?.roles?.some((v) => v.includes('BDF'))) {
        dispatch(handleBDF(true));
      }
      if (userData?.roles?.some((v) => v.includes('UNO'))) {
        dispatch(handleUNO(true));
      }
      if (
        userData?.roles?.includes('PARTNER_SUPER_ADMIN') ||
        userData?.roles?.includes('PARTNER_ADMIN') ||
        userData?.roles?.some((v) => v.includes('CBG'))
      ) {
        setRabcRoleOne(true);
        dispatch(handleRabcOne(true));
      } else {
        setRabcRoleOne(false);
        dispatch(handleRabcOne(false));
      }
    }
  }, [userData]);

  useEffect(() => {
    if (isBottomDrawer?.value) {
      setValue(isBottomDrawer?.value);
    }
  }, [isBottomDrawer?.value]);
  return (
    <Paper
      className={classes.list}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        '& .MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected ': {
          color: 'black',
        },
        '& .MuiButtonBase-root.MuiBottomNavigationAction-root': {
          color: 'black',
          minWidth: '0px',
        },
      }}
    >
      <LogoutModal open={logoutOpen} handleClose={toggleLogoutModal} />
      <BottomDrawer />
      <BottomNavigation value={value} onChange={handleChange}>
        {menu?.slice(0, 5).map((i, key) => {
          if (i.key === 'finAnalytics' && (isChannel || isBDF || isUNO)) {
            return;
          }
          if (!isProxtera && i.key === 'imp') {
            return;
          }
          if (
            !rabcRoleOne &&
            (i.key === 'data_validation' || i.key === 'financing')
          ) {
            return;
          }
          if (
            userData?.roles?.includes('PARTNER_SUPER_ADMIN') &&
            i.key === 'profile'
          ) {
            return;
          }
          if (
            userData?.roles?.includes('PARTNER_ADMIN') &&
            (i.key === 'profile' || i.key === 'invite')
          ) {
            return;
          }
          if (
            userData?.roles?.some((v) => v.includes('USER')) &&
            (i.key === 'profile' || i.key === 'invite')
          ) {
            return;
          }
          if (
            !userData?.roles?.includes('PARTNER_SUPER_ADMIN') &&
            i.key === 'invite'
          ) {
            return;
          }
          const SVGIcon = i?.content;
          return (
            <BottomNavigationAction
              key={key?.toString()}
              className={currentUrl === i?.path ? 'secondary-main-blue-bg' : ''}
              sx={{
                '& .dv path': {
                  ...(currentUrl === ROUTE.DATA_VALIDATION && {
                    stroke: 'white',
                    strokeWidth: 0.6,
                  }),
                  ...(currentUrl !== ROUTE.DATA_VALIDATION && {
                    stroke: 'black',
                    strokeWidth: 0.6,
                  }),
                },
              }}
              onClick={() => {
                if (i?.path === ROUTE.LOGOUT) {
                  toggleLogoutModal(true);
                  return;
                }
                if (i?.path === ROUTE.DEV_DASHBOARD) {
                  const aTag = document.createElement('a');
                  aTag.href = 'https://developer.proxtera.com/';
                  aTag.target = '_blank';
                  aTag.click();
                  aTag.focus();
                  return;
                }
                navigate(i?.path);
              }}
              value={i?.title}
              onChange={handleChange}
              icon={
                <Badge
                  color='secondary'
                  className=''
                  variant={
                    (i?.key === 'financing' &&
                      notification &&
                      notification?.pending_actions_in_financing_requests >
                        0) ||
                    (i?.key === 'data_validation' &&
                      notification &&
                      notification?.pending_actions_in_data_validation_requests >
                        0)
                      ? 'dot'
                      : ''
                  }
                  // sx={{ ml: 4, mt: -8 }}
                >
                  {SVGIcon}
                </Badge>
              }
            />
          );
        })}

        <BottomNavigationAction
          // className={currentUrl === ROUTE.ROOT ? 'secondary-main-blue-bg' : ''}
          // sx={{
          //   '& .dv path': {
          //     ...(currentUrl === ROUTE.DATA_VALIDATION && {
          //       stroke: 'white',
          //       strokeWidth: 0.6,
          //     }),
          //     ...(currentUrl !== ROUTE.DATA_VALIDATION && {
          //       stroke: 'black',
          //       strokeWidth: 0.6,
          //     }),
          //   },
          // }}
          onClick={() => dispatch(handleBottomDrawer({ isOpen: true }))}
          value={''}
          // onChange={() => dispatch(handleBottomDrawer(true))}
          icon={<ICONS.MENU />}
        />
      </BottomNavigation>
    </Paper>
  );
}

export default BottomNavigationBar;
