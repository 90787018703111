import { createSlice } from '@reduxjs/toolkit';
import { PrivateRouteConfig } from '../../../routes/PrivateRoute/PrivateRoute';
import { PublicRouteConfig } from '../../../routes/PublicRoute/PublicRoutes';
import { SplashRouteConfig } from '../../../routes/SplashRoute/SplashRoute';

const initialState = {
  isUserLoggedIn: false,
  route: SplashRouteConfig,
  isSmsSent: false,
  isVerified: true,
  reCapchaToken: '',
  activeStepper: 0,
  maxStepper: 2,
  tokenState: null,
  isRabcOne: false,
  isRabcUsers: false,
  isDBG: false,
  isChannel: false,
  isBDF: false,
  isBNR: false,
  isUNO: false,
  isBottomDrawer: {
    isOpen: false,
    value: 0,
  },
};

let authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    handleSmsSent: function (state, action) {
      state.isSmsSent = action.payload;
    },
    handleStepper: function (state, action) {
      state.activeStepper = action.payload;
    },
    handleRabcOne: function (state, action) {
      state.isRabcOne = action.payload;
    },
    handleRabcUsers: function (state, action) {
      state.isRabcUsers = action.payload;
    },
    handleDBG: function (state, action) {
      state.isDBG = action.payload;
    },
    handleChannel: function (state, action) {
      state.isChannel = action.payload;
    },
    handleBNR: function (state, action) {
      state.isBNR = action.payload;
    },
    handleBDF: function (state, action) {
      state.isBDF = action.payload;
    },
    handleUNO: function (state, action) {
      state.isUNO = action.payload;
    },
    handleBottomDrawer: function (state, action) {
      state.isBottomDrawer = action.payload;
    },
    handleUserLoggedIn: function (state, action) {
      state.isUserLoggedIn = action.payload;
      if (action.payload) {
        state.route = PrivateRouteConfig;
      } else {
        state.route = PublicRouteConfig;
      }
    },
    handleTurnstileCapcha: function (state, action) {
      state.reCapchaToken = action.payload;
    },
    handleTurnstileCapchaFailed: function (state, action) {
      state.isVerified = action.payload;
    },

    handleToken: function (state, action) {
      state.tokenState = action.payload;
    },
  },
});
export const {
  handleUserLoggedIn,
  handleSmsSent,
  handleTurnstileCapcha,
  handleTurnstileCapchaFailed,
  handleStepper,
  handleToken,
  handleRabcOne,
  handleRabcUsers,
  handleBottomDrawer,
  handleDBG,
  handleChannel,
  handleBNR,
  handleBDF,
  handleUNO,
} = authSlice.actions;
export default authSlice.reducer;
