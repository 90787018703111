import * as yup from 'yup';
const linkRegMatch =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
const emailRules = yup
  .string('Enter_your_email')
  .email('Enter_a_valid_email')
  .trim()
  .required('Email_is_required');

const contactEmailRules = yup
  .string('Contract_Enter_your_email')
  .email('Contract_a_valid_email')
  .trim()
  .required('Contract_Email_is_required');
const passwordRules = yup
  .string('Enter_your_password')
  .trim()
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.[{}()"%/,><':;|_~`+=]).{8,}$/,
    'Password_policy'
  )
  .required('Password_is_required');

const loginPasswordRules = yup
  .string('Enter_your_password')
  .trim()
  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.[{}()"%/,><':;|_~`+=]).{8,}$/,
  //   'Password_policy'
  // )
  .required('Password_is_required');

const confirmPasswordRules = yup
  .string('Enter_your_password')
  .trim()
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.[{}()"%/,><':;|_~`+=]).{8,}$/,
    'Password_policy'
  )
  .required('Confirm_password_is_required');

const designationRules = yup
  .string('Designation_is_required')
  .trim()
  .required('Designation_is_required');

const firstNameRules = yup
  .string('First_name_is_required')
  .trim()
  .required('First_name_is_required');

const lastNameRules = yup
  .string('Last_name_is_required')
  .trim()
  .required('Last_name_is_required');

const codeRules = yup
  .string('Phone_Code_is_required')
  .trim()
  .required('Phone_Code_is_required');
const phoneRules = yup
  .string('Phone_number_is_required')
  .trim()
  .required('Phone_number_is_required')
  .matches(/^\d+$/, 'Phone_number_is_not_valid');
// .matches(new RegExp('[0-9]{7}'), 'Phone_number_is_not_valid');
yup.addMethod(
  yup.string,
  'phone',
  function (messageError = 'Phone number is not valid') {
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    return this.test('phone', messageError, (value) => {
      if (value && value.length > 0) {
        return phoneRegExp.test(value);
      }
      return true;
    });
  }
);
const urlRules = yup
  .string()
  .trim()
  .required('URL_is_required')
  // eslint-disable-next-line
  .matches(
    // eslint-disable-next-line
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    'Link_should_be_a_valid_URL'
  );
// const primaryIndustryRules = yup
//   .object('Primary_Industry_is_required')
//   // .trim()
//   .required('Primary_Industry_is_required');

const entityTypeRules = yup
  .string('Type_Of_Entity_Is_Required')
  .trim()
  .required('Type_Of_Entity_Is_Required');
const taxIdRules = yup
  .string('TaxId_is_required')
  .trim()
  .required('TaxId_is_required');
const corporateNameRules = yup
  .string('Corporate_name_is_required')
  .trim()
  .required('Corporate_name_is_required');

const registrationRules = yup
  .string('Registration_is_required')
  // .trim()
  .required('Registration_is_required');

const acceptCreateAccountRules = yup
  .bool()
  .oneOf([true], 'Accept_create_account_is_required');

const acceptPrivacyPolicyRules = yup
  .bool()
  .oneOf([true], 'Accept_privacy_policy_is_required');

const acceptTermsAndConditionsRules = yup
  .bool()
  .oneOf([true], 'Accept_terms_and_condtions_is_required');

const acceptTermsOfUseRules = yup
  .bool()
  .oneOf([true], 'Accept_terms_of_use_is_required');

const countryRules = yup
  .string('Enter_your_country')
  .required('Country_is_required')
  .typeError('Country_type');
const stateRules = yup
  .string('Enter_your_state')
  .required('State_is_required')
  .typeError('State_type');
const cityRules = yup
  .string('Enter_your_city')
  .required('City_is_required')
  .typeError('City_type');

// .matches(/^[0-9 ]+$/, 'postCode_type');
const primaryIndustryRules = yup
  .object()
  .nullable()
  .required('Primary Industry is required');
// const locationRules = yup
//   .string('Enter_your_location')
//   .required('Location_is_required');
const addressLineOneRules = yup
  .string('Enter_your_addressLineOne')
  .required('AddressLineOne_is_required');
const roleRules = yup
  .string('System Role is Required')
  .required('System Role is Required');
// const toLatitudeRules = yup.number().required(`${'Latitude_is_required'}`);
// const toLongitudeRules = yup.number().required(`${'Longitude_is_required'}`);
const postalCodeRules2 = yup
  .string('Enter_your_postCodee')
  .min('1', 'Minimum_One_Charater_Is_Required')
  .max('20', 'Maximum_Twenty_Charaters')
  .required('postCode_is_required');
const aboutRules = yup
  .string()
  // .min('0', 'Minimum_One_Charater_Is_Required')
  .max('500', 'Maximum_five_hundred_Charaters');

const postalCodeRules = yup.string().when('country', {
  is: 'Ghana',
  then: yup
    .string()
    .min(12, 'The_Ghanapost_GPS_Should_Be_A_Fixed_Format')
    .max(12, 'The_Ghanapost_GPS_Should_Be_A_Fixed_Format')
    .required('Ghanapost_Gps_Is_Required'),
  otherwise: yup
    .string('Enter_your_postCodee')
    .min('1', 'Minimum_One_Charater_Is_Required')
    .max('20', 'Maximum_Twenty_Charaters')
    .required('postCode_is_required'),
});

const ghanapostGps = yup.string().when('registration', {
  is: 'Ghana',
  then: yup.string().required('Ghanapost_Gps_Is_Required'),
  otherwise: yup.string(),
});

const onlinePagesRules = yup
  .array()
  .of(yup.string().trim().matches(linkRegMatch, 'Link_should_be_a_valid_URL'))
  .test('online_pages', 'URL_need_te_be_unique', (urls) => {
    if (!urls) return false;
    const uniqueUrls = new Set(urls);
    return uniqueUrls.size === urls.length;
  });

// export const LoginValidationSchema = yup.object({
//   email: emailRules,
//   password: loginPasswordRules,
// });

// export const ForgetPasswordValidationSchema = yup.object({
//   email: emailRules,
// });

const ChannelPartnerRules = yup.string().when('hasChannelData', {
  is: true,
  then: yup.string().required('Channel Partner is Required'),
  otherwise: yup.string(),
});

const IsChannelDataRules = yup.bool();

export const businessDetailsValidation = yup.object().shape({
  companyName: corporateNameRules,
  registration: registrationRules,
  taxId: taxIdRules,
  url: urlRules,
  entityType: entityTypeRules,

  // ghanapost_gps: ghanapostGps,

  primaryIndustry: primaryIndustryRules,
});
export const ContactDetailsValidation = yup.object().shape({
  country: countryRules,
  state: stateRules,
  // ghanapost_gps: ghanapostGps,

  addressLineOne: addressLineOneRules,
  postalCode: postalCodeRules,
  city: cityRules,
});

export const profileValidation = yup.object().shape({
  email: emailRules,
  firstName: firstNameRules,
  lastName: lastNameRules,
  designation: designationRules,
  countryCode: codeRules,
  phone: phoneRules,
  online_pages: onlinePagesRules,
  aboutMe: aboutRules,
});
export const memberValidation = yup.object().shape({
  email: emailRules,
  firstName: firstNameRules,
  lastName: lastNameRules,
  designation: designationRules,
  code: codeRules,
  phone: phoneRules,
  role: roleRules,

  // other_phone_numbers: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       code: yup.string().required('Code is required'),
  //       phone: yup
  //         .string()
  //         .required('Phone Number is required')
  //         .matches(/^\d+$/, 'Phone Number must be a number'),
  //     })
  //   )
  //   .required('At least one Phone Number is required'),
});

export const inviteMemberValidation = yup.object().shape({
  email: emailRules,
  firstName: firstNameRules,
  lastName: lastNameRules,
  // designation: designationRules,
  // code: codeRules,
  // phone: phoneRules,
  hasChannelData: IsChannelDataRules,
  role: roleRules,
  channel_partner: ChannelPartnerRules,
  // other_phone_numbers: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       code: yup.string().required('Code is required'),
  //       phone: yup
  //         .string()
  //         .required('Phone Number is required')
  //         .matches(/^\d+$/, 'Phone Number must be a number'),
  //     })
  //   )
  //   .required('At least one Phone Number is required'),
});
// export const ResetPasswordValidationSchema = yup.object({
//   password: passwordRules,
//   confirmPassword: confirmPasswordRules,
// });
