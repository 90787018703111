import React from 'react';
import { IMAGES } from '../../../../../constants/ImageConstants';
import {
  Box,
  Card,
  Container,
  Grid,
  makeStyles,
  Toolbar,
  Typography
} from '../../../../Containers/index';
const useStyles = makeStyles({
  root: {
    '& img': {
      width: '100%',
      maxWidth: '300px',
      height: 'auto'
    }
  }
});
function DevDashBoard(props) {
  const classes = useStyles();

  return (
    <Box sx={{ display: 'flex' }} className={classes.root}>
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Toolbar />
        <Container maxWidth='lg' sx={{ mt: 3, mb: 4 }}>
          <Card>
            {' '}
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                marginTop='10px'
                marginLeft={'10px'}
              >
                <Typography
                  variant='h5'
                  component='h3'
                  sx={{ fontWeight: 700, padding: 8 }}
                >
                  {' '}
                  Dev Dashboard
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                marginTop='10px'
                marginBottom='50px'
                marginLeft={'10px'}
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <img src={IMAGES.COMINGSOON} alt='dashboard' />
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </Box>
  );
}

export default DevDashBoard;
