//api urls
export const AUTH_SERVER_URL = process.env.REACT_APP_AUTH_SERVER_URL;
export const SFE_SERVER_URL = process.env.REACT_APP_SFE_SERVER_URL;

// google map key
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

// microsoft clarity id
export const CLARITY_ID = process.env.REACT_APP_MICROSOFT_CLARITY_ID;

// congnito credentials
export const COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

// certificate Ids
export const GREEN_CERT_ID_MOODLE = process.env.REACT_APP_GREEN_CERT_ID_MOODLE;
export const GLOBAL_CERT_ID_MOODLE =
  process.env.REACT_APP_GLOBAL_CERT_ID_MOODLE;
export const FOUNDATIONAL_CERT_TAGALOG_ID_MOODLE =
  process.env.REACT_APP_FOUNDATIONAL_CERT_TAGALOG_ID_MOODLE;
export const FOUNDATIONAL_CERT_ODIA_ID_MOODLE =
  process.env.REACT_APP_FOUNDATIONAL_CERT_ODIA_ID_MOODLE;
export const FOUNDATIONAL_CERT_HINDI_ID_MOODLE =
  process.env.REACT_APP_FOUNDATIONAL_CERT_HINDI_ID_MOODLE;
export const FOUNDATIONAL_CERT_ID_MOODLE =
  process.env.REACT_APP_FOUNDATIONAL_CERT_ID_MOODLE;
