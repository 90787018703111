import React, { useState } from 'react';
import {
  Button,
  Card,
  Container,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  LoadingButton,
} from '../../../../Containers/index';

import { signOut } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '../../../../../constants/Routes';
import { handleUserLoggedIn } from '../../../../../redux/Reducer/Auth';

function LogoutModal({ open, handleClose }) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () => {
    handleClose(false);
  };
  return (
    <Drawer
      open={open}
      aria-labelledby='sign-in-form'
      aria-describedby='sign-in-form'
      disableAutoFocus
      anchor={'right'}
      className='modal-form'
    >
      <Card sx={{ my: 24, boxShadow: 'none' }}>
        <Container>
          <Grid container spacing={2} className='mt-3 mb-3'>
            <Grid item xs={12} className=' text-center'>
              <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
                Logout
              </DialogTitle>
              <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                <DialogContentText className='mt-10 '>
                  Are you sure you want to logout?
                </DialogContentText>
              </DialogContent>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                variant='contained'
                loading={isLoading}
                onClick={async () => {
                  // await Auth.signOut();
                  setIsLoading(true);
                  await signOut();
                  setIsLoading(false);
                  dispatch(handleUserLoggedIn(false));
                  navigate(ROUTE.ROOT);
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                Ok
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant='text' onClick={closeModal}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Card>
    </Drawer>
  );
}

export default LogoutModal;
