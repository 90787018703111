import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useViewport } from '../../../../../hooks';
import {
  Box,
  Card,
  Grid,
  SnackBar,
  makeStyles,
} from '../../../../Containers/index';
// import MultiTreeSelect from '../../../../Containers/MultiTree';

const useStyles = makeStyles({
  root: {},
});

function ImpactMetrics() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  // const [contriesList, setContriesList] = useState([]);

  return (
    <Box
      component={Card}
      sx={{
        my: 4,
        p: 4,
        height: '100vh',
      }}
    >
      <SnackBar />
      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} marginTop='10px' marginLeft={'10px'}>
          <Typography
            variant='h5'
            component='h3'
            sx={{ fontWeight: 700, px: 4, py: 4 }}
          >
            {' '}
            Invite Member
          </Typography>
        </Grid>
      </Grid> */}
      {/* <Container maxWidth='sm' sx={{ mb: 8 }}> */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            '& iframe': {
              height: '100vh',
            },
          }}
        >
          <iframe
            width='100%'
            // height='100'
            src='https://qa.gac-lap.com/superuser/project_dashboard?project_id=661624af55e9b44956d210ec&jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcxMjIxOTM0MiwianRpIjoiMmUxNzNmZGYtZjU4Zi00YTM0LWI3ZDUtZDRmMDU3MmM5ODZiIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6InN1cGVydXNlckBnYWMtbGFwLmNvbSIsIm5iZiI6MTcxMjIxOTM0MiwiY3NyZiI6ImRiNWIxMTIyLWI0YzItNGIxNC04MmRlLWI3OThhNDQ2MjNjZiIsImV4cCI6MTc0Mzc1NTM0Mn0.NzbvEACNqkW-33CQU0ErTJMHhEuB9UqumXKx-P1F6QE'
          />
        </Grid>
      </Grid>
      {/* </Container> */}
    </Box>
  );
}

export default ImpactMetrics;
