import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../../constants/Icons';
import { IMAGES } from '../../../../../../constants/ImageConstants';
import { useViewport } from '../../../../../../hooks';
import {
  Box,
  Drawer,
  Grid,
  LoadingButton,
  Typography
} from '../../../../../Containers/index';

const AccountVerification = ({ isOpen, handleClose, handleLogout }) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '450px'
        }
      }}
    >
      <Box sx={{ height: '100%', p: 8, marginTop: 24 }}>
        <Box
          sx={{ position: 'fixed', right: 10, top: 80, cursor: 'pointer' }}
          onClick={() => handleClose()}
        >
          <ICONS.CLOSE sx={{ color: 'black' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            color='black'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '20px',
              fontWeight: 600,
              mt: 2
            }}
          >
            {t('Complete_verification')}
          </Typography>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'center' }}
          className='caution'
        >
          <img
            src={IMAGES.ACCOUNTCHECK}
            alt='account_complete'
            width={300}
            height={250}
          />
        </Box>
        <Typography
          // color='black'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
            fontWeight: 400,
            mt: 5,
            color: '#747272',
            textAlign: 'center'
          }}
        >
          {t('Account_description')}
          {/* <p className='heading'></p> */}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              sx={{
                mt: 4,
                textTransform: 'none',
                '& .MuiCircularProgress-root ': {
                  color: '#17236E'
                }
              }}
              variant='outlined'
              onClick={() => {
                handleClose();
                handleLogout(true);
              }}
            >
              {t('Proceed_to_logout')}
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              sx={{
                mt: 4,
                textTransform: 'none',
                '& .MuiCircularProgress-root ': {
                  color: '#17236E'
                }
              }}
              variant='contained'
              onClick={() => handleClose()}
            >
              {t('Continue')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};
export default AccountVerification;
