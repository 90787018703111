import {
  ForgetPassword,
  Login,
  ResetPassword,
  TwoFa
} from '../../app/Pages/Auth/Components';
import ApproveMemberRequest from '../../app/Pages/Auth/Components/ApproveRequest';
import ReviewRequest from '../../app/Pages/Auth/Components/ReviewRequest';
import SetPassword from '../../app/Pages/Auth/Components/SetPassword';
import Index from '../../app/Pages/Auth/index';
import { Error404 } from '../../app/Pages/Authenticated/Components';
import { ROUTE } from '../../constants/Routes';

export const PublicRouteConfig = [
  {
    path: '',
    element: <Index />,
    children: [
      {
        path: ROUTE.ROOT,
        element: <Login />
      },
      {
        path: ROUTE.LOGIN,
        element: <Login />
      },
      {
        path: ROUTE.SET_PASSWORD,
        element: <SetPassword />
      },
      // {
      //   path: ROUTE.SIGN_UP,
      //   element: <SignUp />,
      // },
      {
        path: ROUTE.FORGET_PASSWORD,
        element: <ForgetPassword />
      },
      {
        path: ROUTE.VERIFY_EMAIL,
        element: <ApproveMemberRequest />
      },
      {
        path: ROUTE.RESET_PASSWORD,
        element: <ResetPassword />
      },
      {
        path: ROUTE.TWOFA,
        element: <TwoFa />
      }
    ]
  },
  {
    path: '/review_sign_up_request',
    element: <ReviewRequest />
  },
  {
    path: '*',
    element: <Error404 />
  }
];
