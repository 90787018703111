import { apiGet, apiGetNoAuth, apiPost, apiPut } from '../../utils/Axios/axios';
import { AUTH_SERVER_URL, SFE_SERVER_URL } from '../Env';

//auth url
// const INDUSTRY_TYPE_ENUM_MULTI_TREE = `${AUTH_SERVER_URL}/enum/get_industries`;
const INDUSTRY_TYPE_ENUM_MULTI_TREE = `${AUTH_SERVER_URL}/enum/get_by_type?type=Industry`;
const INDUSTRY_TYPE_SINGAPORE_MULTI_TREE = `${AUTH_SERVER_URL}/enum/get_by_type?type=SingaporeIndustrySector`;

const ENUM_COUNTRY_LIST = `${AUTH_SERVER_URL}/enum/get_countries`;
const ROLES = `${AUTH_SERVER_URL}/enum/get_roles?source=dashboard&type=ADMIN`;
const SEND_OTP = `${AUTH_SERVER_URL}/auth/send_2fa_otp`;
const VERIFY_OTP = `${AUTH_SERVER_URL}/auth/verify_2fa_otp`;
const GET_BY_TYPE = AUTH_SERVER_URL + '/enum/get_by_type?type=';

const BASE_AUTH_URL = AUTH_SERVER_URL + '/proxtera';
//Base auth url
const EMAIL_SIGN_UP = `${BASE_AUTH_URL}/registration`;
const EMAIL_VERIFY = `${BASE_AUTH_URL}/verify_token`;
const ACCEPT_INVITE = `${AUTH_SERVER_URL}/accept_invitation`;
const FORGET_PASSWORD_EMAIL = `${BASE_AUTH_URL}/forgot_password`;
const RESET_PASSWORD = `${BASE_AUTH_URL}/reset_password`;
const REVIEW_PARTNER = `${BASE_AUTH_URL}/review_partner`;
//Sfe url
const NOTIFICATIONS = `${SFE_SERVER_URL}/finance/my_pending_items_notify`;
const CHANGE_PASSWORD_URL = AUTH_SERVER_URL + '/profile/change_password';
const PROFILE_URL_UPDATE = AUTH_SERVER_URL + `/profile/update_company_info`;

export const emailSignUp = (apiData) => {
  return apiPost(EMAIL_SIGN_UP, apiData);
};

export const emailVerify = (apiData) => {
  return apiPut(EMAIL_VERIFY, apiData);
};

export const approveMember = (apiData) => {
  return apiPost(ACCEPT_INVITE, apiData);
};
export const forgetPassWordEmail = (apiData) => {
  return apiPost(FORGET_PASSWORD_EMAIL, apiData);
};
export const resetPasswordViaToken = (apiData) => {
  return apiPut(RESET_PASSWORD, apiData);
};
export const ChangePasswordSubmit = (apiData) => {
  return apiPut(CHANGE_PASSWORD_URL, apiData);
};
export const getDropDownDataByType = (path) => {
  return apiGet(GET_BY_TYPE + `${path}`);
};
export const getIndustryTypeEnum = () => {
  return apiGetNoAuth(INDUSTRY_TYPE_ENUM_MULTI_TREE);
};
export const getIndustryTypeEnumSingapore = () => {
  return apiGet(INDUSTRY_TYPE_SINGAPORE_MULTI_TREE);
};
export const getCountries = () => {
  return apiGetNoAuth(ENUM_COUNTRY_LIST);
};
export const reviewPartner = (apiData) => {
  return apiPut(REVIEW_PARTNER, apiData);
};
export const getRoles = () => {
  return apiGetNoAuth(ROLES);
};
export const getNotifications = () => {
  return apiGet(NOTIFICATIONS);
};
export const sendOtp = (apiData) => {
  return apiPost(SEND_OTP, apiData);
};
export const verifyOtp = (apiData) => {
  return apiPut(VERIFY_OTP, apiData);
};
export const updateUserProfile = (apiData) => {
  return apiPut(PROFILE_URL_UPDATE, apiData);
};
