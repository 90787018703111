export const ROUTE = {
  ROOT: '/',
  LOGIN: 'login',
  SIGN_UP: 'signup',
  FORGET_PASSWORD: 'forget_password',
  // VERIFY_EMAIL: '/verify_email',
  VERIFY_EMAIL: '/accept_invite',
  RESET_PASSWORD: 'reset_password',
  TWOFA: '/two_fa',
  DASHBOARD: '/dashboard',
  ONBOARD: '/on_boarding',
  INVITE: '/invite',
  MY_PROFILE: '/my_profile',
  SET_PASSWORD: '/set_password',
  SFE: '/sfe',
  DEV_DASHBOARD: '/dev_dashboard',
  IMPACT_METRICS: '/impact_metrics',
  SETTINGS: '/settings',
  TRUSTED: '/trusted_credentials',
  DATA_VALIDATION: '/data_validation',
  FINANCING_REQUEST: '/financing_request',
  FINANCING_ANALYTICS: '/financing_analytics',
  LOGOUT: '/logout',
};
