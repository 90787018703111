import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { handleUserLoggedIn } from '../redux/Reducer/Auth';
import { PrivateRouteConfig } from './PrivateRoute/PrivateRoute';
function Route() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  useEffect(() => {
    if (localStorage.getItem('idToken')) {
      dispatch(handleUserLoggedIn(true));
    } else {
      dispatch(handleUserLoggedIn(false));
    }
    // eslint-disable-next-line
  }, []);
  const { route } = useSelector((state) => state?.auth);
  const routes = useRoutes(route);
  useEffect(() => {
    if (!localStorage.getItem('idToken')) {
      PrivateRouteConfig &&
        PrivateRouteConfig[0].children?.map((e) => {
          if (e?.path === currentUrl) {
            navigate('/');
          }
        });
    }
    // eslint-disable-next-line
  }, []);
  // Hub.listen('auth', ({ payload: { event, data } }) => {
  //   switch (event) {
  //     case 'signOut':
  //       localStorage.clear();
  //       dispatch(handleUserLoggedIn(false));
  //       navigate('/');
  //       break;
  //     case 'oAuthSignOut':
  //       localStorage.clear();
  //       dispatch(handleUserLoggedIn(false));
  //       navigate('/');
  //       break;
  //     default:
  //       break;
  //   }
  // });
  return <React.Fragment>{routes}</React.Fragment>;
}

export default Route;
