import { apiGet, apiPost, apiPut } from '../../utils/Axios/axios';
import { AUTH_SERVER_URL, SFE_SERVER_URL } from '../Env';

const BASE_AUTH_URL = SFE_SERVER_URL + '/finance';
//Base auth url
const GET_FINANCING_REQUESTS = `${BASE_AUTH_URL}/financing_requests`;
const GET_REJECTED_REASONS = `${AUTH_SERVER_URL}/enum/get_by_type?type=CBGLoanRejectReason`;
const FINANCE_SUBMIT = `${BASE_AUTH_URL}/update_status`;

//devauthapi.proxtera.app/enum/get_by_type?type=CBGLoanRejectReason
export const getFinancingRequests = (apiData) => {
  return apiPost(GET_FINANCING_REQUESTS, apiData);
};

export const getRejectedReasons = () => {
  return apiGet(GET_REJECTED_REASONS);
};

export const sumbitFinanceRequest = (apiData) => {
  return apiPut(FINANCE_SUBMIT, apiData);
};
