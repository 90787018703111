import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../../constants/Icons';
import { toggleSnackBar } from '../../../../../redux/Reducer/Utils';
import { ChangePasswordSubmit } from '../../../../../services/Auth';
import { changePasswordValidationSchema } from '../../../../../utils/ValidationSchema/Auth';
import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  SnackBar,
  TextField,
  Typography,
} from '../../../../Containers/index';

const SettingsComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().search;
  // const token = new URLSearchParams(location).get('token');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);

      if (
        values.password.trim().toLowerCase() !==
        values.confirmPassword.trim().toLowerCase()
      ) {
        setIsLoading(false);

        setErrorMsg('Both_passwords_should_be_same');
        // dispatch(
        //   toggleSnackBar({
        //     isOpen: true,
        //     type: 'error',
        //     message: t('Both_passwords_should_be_same'),
        //   })
        // );
        return;
      }
      if (
        values.password.toLowerCase() === values.confirmPassword.toLowerCase()
      ) {
        const params = {
          access_token: localStorage.getItem('accessToken'),
          new_password: values.password,
          source: 'dashboard',
        };
        try {
          let res = await ChangePasswordSubmit(params);
          // navigate(ROUTE.ROOT);
          resetForm();
          dispatch(
            toggleSnackBar({
              isOpen: true,
              type: 'success',
              message: res?.data?.message,
            })
          );
        } catch (error) {
          dispatch(
            toggleSnackBar({
              isOpen: true,
              type: 'error',
              message: error,
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    },
  });
  useEffect(() => {
    return () =>
      dispatch(
        toggleSnackBar({
          isOpen: false,
          type: '',
          message: '',
        })
      );
  }, []);
  return (
    <Box
      component={Card}
      sx={{
        my: 4,
        p: 4,
      }}
    >
      <SnackBar />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} marginTop='10px' marginLeft={'10px'}>
          <Typography
            mt={4}
            sx={{
              fontWeight: 900,
              fontSize: '24px',
              fontStyle: 'normal',
            }}
          >
            {t('Settings')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} marginTop='10px' marginLeft={'10px'}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12} lg={12} marginTop='10px' marginLeft={'10px'}>
          <Typography
            my={4}
            sx={{
              fontWeight: 500,
              fontSize: '20px',
              fontStyle: 'normal',
            }}
          >
            {t('Change_Password')}
          </Typography>

          <TextField
            inputProps={{ 'data-testid': 'reset-password' }}
            fullWidth
            required
            sx={{ mt: 2, mb: 2 }}
            label='New Password'
            name='password'
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'
                  >
                    {showPassword ? (
                      <ICONS.VISIBILITY />
                    ) : (
                      <ICONS.VISIBILITY_OFF />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password &&
              formik.errors.password &&
              t(formik.errors.password)
            }
            value={formik.values.password
              .trimStart()
              .replace(/\s\s+/g, '')
              .replaceAll(/ /g, '')
              .replace(/\p{Emoji_Presentation}/gu, '')}
            onChange={(e) => {
              setErrorMsg('');
              formik.handleChange(e);
            }}
          />
          {!formik.touched.password && !Boolean(formik.errors.password) !== '' && (
            <Box>
              <Box
                sx={{
                  color: 'grey',
                  fontSize: '0.75rem',
                  ml: 4,
                  mb: 2,
                }}
              >
                {t(
                  'A minimum of 8 characters with a combination of uppercase and lowercase letters, numbers and special characters is required.'
                )}
              </Box>
            </Box>
          )}
          <TextField
            inputProps={{ 'data-testid': 'reset-confirmPassword' }}
            fullWidth
            sx={{ my: 2 }}
            name='confirmPassword'
            label='Confirm Password'
            required
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge='end'
                  >
                    {showConfirmPassword ? (
                      <ICONS.VISIBILITY />
                    ) : (
                      <ICONS.VISIBILITY_OFF />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            value={formik.values.confirmPassword
              .trimStart()
              .replace(/\s\s+/g, '')
              .replaceAll(/ /g, '')

              .replace(/\p{Emoji_Presentation}/gu, '')}
            onChange={(e) => {
              setErrorMsg('');
              formik.handleChange(e);
            }}
            helperText={
              formik.touched.confirmPassword &&
              formik.errors.confirmPassword &&
              t(formik.errors.confirmPassword)
            }
          />

          {/* {errorMsg !== '' && (
        <Box>
          <Box
            sx={{
              color: 'error.main',
              fontSize: '0.75rem',
              ml: 4,
            }}
          >
            {t(errorMsg)}
          </Box>
        </Box>
      )} */}
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            fullWidth
            variant='contained'
            onClick={formik.handleSubmit}
            sx={{
              my: 2,
              textTransform: 'none',
              '& .MuiCircularProgress-root ': {
                color: '#ffff',
              },
            }}
          >
            {t('Change Password')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsComponent;
