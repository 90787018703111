import {
  DashBoard,
  DataValidation,
  DevDashboard,
  Error404,
  Finance,
  FinancialAnalytics,
  ImpactMetrics,
  Invite,
  OnBoarding,
  Profile,
  Settings,
  Sfe,
} from '../../app/Pages/Authenticated/Components';
import AppLayout from '../../app/Pages/Authenticated/Layout/index';
import { ROUTE } from '../../constants/Routes';

export const PrivateRouteConfig = [
  {
    path: '',
    element: <AppLayout />,
    children: [
      {
        path: ROUTE.ROOT,
        element: <DashBoard />,
      },
      {
        path: ROUTE.ONBOARD,
        element: <OnBoarding />,
      },

      {
        path: ROUTE.INVITE,
        element: <Invite />,
      },
      {
        path: ROUTE.MY_PROFILE,
        element: <Profile />,
      },
      {
        path: ROUTE.SFE,
        element: <Sfe />,
      },
      {
        path: ROUTE.DATA_VALIDATION,
        element: <DataValidation />,
      },
      {
        path: ROUTE.DEV_DASHBOARD,
        element: <DevDashboard />,
      },
      {
        path: ROUTE.SETTINGS,
        element: <Settings />,
      },
      {
        path: ROUTE.IMPACT_METRICS,
        element: <ImpactMetrics />,
      },
      {
        path: ROUTE.FINANCING_REQUEST,
        element: <Finance />,
      },
      {
        path: ROUTE.FINANCING_ANALYTICS,
        element: <FinancialAnalytics />,
      },
    ],
  },
  {
    path: '*',
    element: <Error404 />,
  },
];
