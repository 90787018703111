import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../../../constants/Icons';
import { ROUTE } from '../../../../../../constants/Routes';
import { toggleSnackBar } from '../../../../../../redux/Reducer/Utils';
import { approveMember } from '../../../../../../services/Auth';
import { SetPasswordValidationSchema } from '../../../../../../utils/ValidationSchema/Auth';
import {
  Box,
  IconButton,
  InputAdornment,
  LoadingButton,
  SnackBar,
  TextField,
  Typography,
  makeStyles,
} from '../../../../../Containers/index';

const useStyles = makeStyles({
  root: {
    '& .MuiLink-root': {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
});
function AfterVerify({ acceptMessage }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation().search;
  const token = new URLSearchParams(location).get('token');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const [cushowPassword, setCuShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      // currentPassword: '',
      confirmPassword: '',
    },
    validationSchema: SetPasswordValidationSchema,
    onSubmit: async (values) => {
      // setShowRecapcha(true);
      setIsLoading(true);
      const params = {
        new_password: values.password,
        source: 'dashboard',
        token: token,
      };
      // console.log(params);
      try {
        const response = await approveMember(params);
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'success',
            message: response?.data?.message,
          })
        );
        navigate(ROUTE.ROOT);
        // if (response.data.status === 'success') {
        localStorage.clear();

        window.location.reload();

        // }
      } catch (error) {
        // setErrorMessage(error);
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: error,
          })
        );
      } finally {
        setIsLoading(false);
        // setShowRecapcha(false);
      }
    },
  });
  return (
    <div>
      <Box sx={{}}>
        {/* <Typography
          my={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 900,
            fontSize: '24px',
            fontStyle: 'normal',
          }}
        >
          {acceptMessage}
        </Typography> */}
        <Box className={classes.root}>
          <SnackBar />

          <Typography
            my={4}
            sx={{
              fontWeight: 900,
              fontSize: '24px',
              fontStyle: 'normal',
            }}
          >
            {t('Set Password')}
          </Typography>

          <Box sx={{ my: 2 }}>
            {/* <TextField
          inputProps={{ 'data-testid': 'signup-password' }}
          fullWidth
          required
          sx={{ mt: 2, mb: 1 }}
          label='Current Password'
          name='currentPassword'
          type={cushowPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setCuShowPassword(!cushowPassword)}
                  edge='end'
                >
                  {cushowPassword ? (
                    <ICONS.VISIBILITY />
                  ) : (
                    <ICONS.VISIBILITY_OFF />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={
            formik.touched.currentPassword &&
            Boolean(formik.errors.currentPassword)
          }
          value={formik.values.currentPassword
            .trimStart()
            .replace(/\s\s+/g, '')
            .replace(/\p{Emoji_Presentation}/gu, '')}
          onChange={(e) => formik.handleChange(e)}
          helperText={
            formik.touched.currentPassword &&
            formik.errors.currentPassword &&
            t(formik.errors.currentPassword)
          }
        /> */}

            <TextField
              inputProps={{ 'data-testid': 'signup-password' }}
              fullWidth
              required
              sx={{ mt: 2, mb: 1 }}
              label='New Password'
              name='password'
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? (
                        <ICONS.VISIBILITY />
                      ) : (
                        <ICONS.VISIBILITY_OFF />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={formik.touched.password && Boolean(formik.errors.password)}
              value={formik.values.password
                .trimStart()
                .replace(/\s\s+/g, '')
                .replace(/\p{Emoji_Presentation}/gu, '')}
              onChange={(e) => formik.handleChange(e)}
              helperText={
                formik.touched.password &&
                formik.errors.password &&
                t(formik.errors.password)
              }
            />

            <TextField
              inputProps={{ 'data-testid': 'signup-confirmPassword' }}
              fullWidth
              sx={{ my: 2 }}
              label='Confirm Password'
              required
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge='end'
                    >
                      {showConfirmPassword ? (
                        <ICONS.VISIBILITY />
                      ) : (
                        <ICONS.VISIBILITY_OFF />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              name='confirmPassword'
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              value={formik.values.confirmPassword
                .trimStart()
                .replace(/\s\s+/g, '')
                .replace(/\p{Emoji_Presentation}/gu, '')}
              onChange={(e) => formik.handleChange(e)}
              helperText={
                formik.touched.confirmPassword &&
                formik.errors.confirmPassword &&
                t(formik.errors.confirmPassword)
              }
            />

            {/* {errorMessage !== '' && (
            <Typography mt={1} ml={1}>
              {Boolean(errorMessage) && (
                <Box sx={{ color: 'error.main' }}>{errorMessage}</Box>
              )}
            </Typography>
          )} */}
            <LoadingButton
              loading={isLoading}
              disabled={isLoading}
              fullWidth
              variant='contained'
              onClick={(e) => {
                // handleSecondForm();
                //  const handleCapcha = async (e) => {
                // handleCapcha(e);
                formik.handleSubmit();
              }}
              sx={{
                my: 2,
                textTransform: 'none',
                '& .MuiCircularProgress-root ': {
                  color: '#ffff',
                },
              }}
            >
              {t('Set_Password')}
            </LoadingButton>
            {/* {showRecapcha && (
          <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
            <TurnstileWidget />
          </Box>
        )} */}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default AfterVerify;
