import * as React from 'react';

const FinancingRequestIcon = (color) => (
  <svg
    width='28'
    height='30'
    viewBox='0 -03 24 22'
    style={{ marginLeft: '4px', marginTop: '2px' }}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='svglocal MuiSvgIcon-root MuiSvgIcon-fontSizeMedium'
  >
    <path
      d='M17.7555 11.103C17.5155 10.6836 17.1268 10.3848 16.6619 10.2606C16.197 10.1364 15.7123 10.203 15.3247 10.4292L12.1663 12.0438C11.8352 11.6532 11.3481 11.4 10.798 11.4H5.99888C5.14884 11.4 4.2712 11.8626 3.68271 12.6H2.99944C2.99944 12.2688 2.73069 12 2.39955 12H0.599888C0.26875 12 0 12.2688 0 12.6V17.4C0 17.7312 0.26875 18 0.599888 18H2.39955C2.73069 18 2.99944 17.7312 2.99944 17.4H9.59821C10.0427 17.4 10.6582 17.2152 10.9923 16.992L17.0908 13.5624L17.1508 13.524C17.9936 12.9084 18.2366 11.9352 17.7555 11.103ZM1.79966 16.8H1.19978V13.2H1.79966V16.8ZM16.4693 12.5358L10.3679 15.9684C10.1909 16.0854 9.80997 16.2 9.59821 16.2H2.99944V13.8H3.98925C4.19322 13.8 4.38338 13.6962 4.49376 13.5246C4.84949 12.9714 5.45418 12.6 5.99888 12.6H10.798C11.1291 12.6 11.3979 12.8694 11.3979 13.2C11.3979 13.5306 11.1291 13.8 10.798 13.8H7.19866C6.86752 13.8 6.59877 14.0688 6.59877 14.4C6.59877 14.7312 6.86752 15 7.19866 15H10.798C11.7902 15 12.5976 14.1924 12.5976 13.2C12.5976 13.1904 12.5946 13.1814 12.5946 13.1724L15.8994 11.4822C16.038 11.4012 16.2 11.3796 16.3517 11.4198C16.5059 11.4612 16.6349 11.5602 16.7159 11.7024C16.9282 12.069 16.7057 12.3546 16.4693 12.5358ZM1.79966 9.6H16.197C16.5287 9.6 16.7969 9.3312 16.7969 9V0.6C16.7969 0.2688 16.5287 0 16.197 0H1.79966C1.46853 0 1.19978 0.2688 1.19978 0.6V9C1.19978 9.3312 1.46853 9.6 1.79966 9.6ZM4.73851 1.2H13.2587C13.4975 2.3736 14.4237 3.3 15.5971 3.5394V6.0606C14.4237 6.3 13.4975 7.2264 13.2587 8.4H4.73851C4.49916 7.2264 3.57293 6.3 2.39955 6.0606V3.5394C3.57293 3.3 4.49916 2.3736 4.73851 1.2ZM2.39955 7.3104C2.90766 7.4916 3.30778 7.8918 3.48895 8.4H2.39955V7.3104ZM14.5083 8.4C14.6895 7.8918 15.089 7.4916 15.5971 7.3104V8.4H14.5083ZM15.5971 2.289C15.0884 2.1084 14.6889 1.7082 14.5083 1.2H15.5971V2.289ZM3.48895 1.2C3.30778 1.7082 2.90766 2.1084 2.39955 2.2896V1.2H3.48895Z'
      fill={color.color}
      stroke={color.color}
      strokeWidth='0.2'
    />
    <path
      d='M8.99822 7.7998C10.6521 7.7998 11.9977 6.454 11.9977 4.7998C11.9977 3.1456 10.6521 1.7998 8.99822 1.7998C7.34433 1.7998 5.99878 3.1456 5.99878 4.7998C5.99878 6.454 7.34433 7.7998 8.99822 7.7998ZM8.99822 2.9998C9.99043 2.9998 10.7979 3.8074 10.7979 4.7998C10.7979 5.7922 9.99043 6.5998 8.99822 6.5998C8.006 6.5998 7.19856 5.7922 7.19856 4.7998C7.19856 3.8074 8.006 2.9998 8.99822 2.9998Z'
      fill={color.color}
      stroke={color.color}
      strokeWidth='0.2'
    />
  </svg>
);

export default FinancingRequestIcon;
