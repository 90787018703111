import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../../constants/Icons';
import { ROUTE } from '../../../../../constants/Routes';
import {
  handleBottomDrawer,
  handleRabcOne,
  handleRabcUsers,
} from '../../../../../redux/Reducer/Auth';
import {
  Badge,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  makeStyles,
} from '../../../../Containers/index';
import LogoutModal from '../../../Auth/Components/Logout';
// import { NavDrawer } from '../Settings/index';
import { getPendingNotifications } from '../../../../../redux/Reducer/Profile';
import { menu } from '../SideDrawer/Menu';

const useStyles = makeStyles({
  list: {
    '& .MuiTypography-root ': {
      // fontWeight: 500,
      // fontSize: '14px',
    },
    '& .MuiListItemIcon-root , .MuiListItemText-root': {
      color: 'black',
    },
    '& .MuiSvgIcon-root': {
      fill: 'black',
    },
    '& .MuiListItemButton-gutters.Mui-selected svg.svglocal': {
      fill: 'white',
      stroke: 'white',
    },
    '& .MuiListItemButton-gutters svg.svglocal': {
      fill: 'black',
      stroke: '#38383be0',
    },
    '& .MuiBadge-badge': {
      color: 'red',
      backgroundColor: 'red',
      top: '2px',
      right: '6px',
    },
    '& .MuiButtonBase-root.MuiListItemButton-root.Mui-selected': {
      backgroundColor: '#17236E',

      '& .MuiListItemIcon-root , .MuiListItemText-root': {
        color: '#ffff',
      },
      '& .MuiSvgIcon-root': {
        fill: '#ffff',
      },
    },
  },
});
function BottomDrawer() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isBottomDrawer } = useSelector((state) => state.auth);

  const { isAdmin } = useSelector((state) => state.user);
  const [isProxtera, setIsProxtera] = useState(false);

  const [logoutOpen, setLogoutOpen] = useState(false);
  const { notification, userData } = useSelector((state) => state?.user);
  const { isDBG, isChannel, isBNR, isBDF, isUNO } = useSelector(
    (state) => state?.auth
  );
  const [rabcRoleOne, setRabcRoleOne] = useState(false);
  const [rabcRoleUsers, setRabcRoleUsers] = useState(false);
  const location = useLocation();

  const currentUrl = location.pathname;

  const toggleLogoutModal = (value) => {
    setLogoutOpen(value);
  };
  useEffect(() => {
    if (userData?.roles) {
      if (
        userData?.roles?.includes('PARTNER_SUPER_ADMIN') ||
        userData?.roles?.includes('PARTNER_ADMIN')
      ) {
        setIsProxtera(true);
      }
      if (userData?.roles?.some((v) => v.includes('USER'))) {
        dispatch(handleRabcUsers(true));
        setRabcRoleUsers(true);
      } else {
        dispatch(handleRabcUsers(false));
        setRabcRoleUsers(false);
      }
      if (
        userData?.roles?.includes('PARTNER_SUPER_ADMIN') ||
        userData?.roles?.includes('PARTNER_ADMIN') ||
        userData?.roles?.some((v) => v.includes('CBG')) ||
        userData?.roles?.some((v) => v.includes('DBG'))
        // userData?.roles?.some((v) => v.includes('DBG'))
      ) {
        setRabcRoleOne(true);
        dispatch(handleRabcOne(true));
      } else {
        setRabcRoleOne(false);
        dispatch(handleRabcOne(false));
      }
    }
  }, [userData]);
  return (
    <Drawer
      // variant='permanent'
      open={isBottomDrawer?.isOpen}
      //open={true}
      anchor={'bottom'}
      sx={{
        display: {
          xs: 'block',
          sm: 'block',
          md: 'none',
          lg: 'none',
        },
      }}
    >
      <LogoutModal open={logoutOpen} handleClose={toggleLogoutModal} />
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        {isBottomDrawer?.isOpen && (
          <IconButton
            data-testid='collapse-menu-button'
            onClick={() => dispatch(handleBottomDrawer(false))}
          >
            <ICONS.CLOSE />
          </IconButton>
        )}
      </Toolbar>

      <Divider />
      <List className={classes.list}>
        {menu?.map((i, key) => {
          // if (!isAdmin && i.title === 'SFE') {
          //   return null;
          // }
          if (i.key === 'finAnalytics' && (isChannel || isBDF || isUNO)) {
            return;
          }
          if (!isProxtera && i.key === 'imp') {
            return;
          }
          if (
            !rabcRoleOne &&
            (i.key === 'data_validation' || i.key === 'financing')
          ) {
            return;
          }
          if (
            userData?.roles?.includes('PARTNER_SUPER_ADMIN') &&
            i.key === 'profile'
          ) {
            return;
          }
          if (
            userData?.roles?.includes('PARTNER_ADMIN') &&
            (i.key === 'profile' || i.key === 'invite')
          ) {
            return;
          }
          if (rabcRoleUsers && (i.key === 'profilee' || i.key === 'invite')) {
            return;
          }
          if (
            !userData?.roles?.includes('PARTNER_SUPER_ADMIN') &&
            i.key === 'invite'
          ) {
            return;
          }
          const SVGIcon = i?.content;

          return (
            <React.Fragment key={key?.toString()}>
              <Tooltip
                title={isBottomDrawer?.isOpen ? '' : i?.title}
                arrow
                placement='right'
              >
                <ListItem
                  disablePadding
                  className='display-block'
                  onClick={() => {
                    if (i?.path === ROUTE.LOGOUT) {
                      toggleLogoutModal(true);
                      return;
                    }

                    if (i?.path === ROUTE.DEV_DASHBOARD) {
                      const aTag = document.createElement('a');
                      aTag.href = 'https://developer.proxtera.com/';
                      aTag.target = '_blank';
                      aTag.click();
                      aTag.focus();
                      return;
                    }
                    navigate(i?.path);
                    dispatch(getPendingNotifications());
                    dispatch(
                      handleBottomDrawer({ isOpen: false, value: i?.title })
                    );
                  }}
                >
                  <ListItemButton
                    selected={currentUrl === i?.path}
                    className={
                      currentUrl === i?.path ? 'secondary-main-blue-bg' : ''
                    }
                  >
                    <ListItemIcon className='min-icon-width'>
                      {
                        <Badge
                          color='secondary'
                          //   sx={{ top: '2px', bottom: '6px' }}
                          variant={
                            (i?.key === 'financing' &&
                              notification &&
                              notification?.pending_actions_in_financing_requests >
                                0) ||
                            (i?.key === 'data_validation' &&
                              notification &&
                              notification?.pending_actions_in_data_validation_requests >
                                0)
                              ? 'dot'
                              : ''
                          }
                          // sx={{ ml: 4, mt: -8 }}
                        >
                          {SVGIcon}
                        </Badge>
                      }
                    </ListItemIcon>
                    <ListItemText
                      sx={{ fontWeight: 500 }}
                      primary={i.title}
                      className={
                        isBottomDrawer?.isOpen
                          ? `opacity-1 ${
                              currentUrl === i?.path &&
                              !logoutOpen &&
                              !isBottomDrawer?.isOpen
                                ? 'color-white'
                                : ''
                            } `
                          : 'opacity-0'
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </React.Fragment>
          );
        })}
      </List>
    </Drawer>
  );
}

export default BottomDrawer;
