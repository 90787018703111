import { ArrowBack } from '@mui/icons-material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import countryList from '../../../../../constants/CountryList';
import { ICONS } from '../../../../../constants/Icons';
import { ROUTE } from '../../../../../constants/Routes';
import { toggleSnackBar } from '../../../../../redux/Reducer/Utils';
import {
  emailSignUp,
  getCountries,
  getIndustryTypeEnum,
} from '../../../../../services/Auth';
import { GOOGLE_MAP_KEY } from '../../../../../services/Env';
import GoogleApiWrapper from '../../../../../utils/GoogleMap/index';
import TurnstileWidget from '../../../../../utils/Turnstile/TurnstileWidget';
import { SignUpValidationSchema } from '../../../../../utils/ValidationSchema';
import MultiTreeSelect from '../../../../Containers/MultiTree';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  LoadingButton,
  SnackBar,
  TextField,
  Typography,
  makeStyles,
} from '../../../../Containers/index';

Geocode.setApiKey(GOOGLE_MAP_KEY);
Geocode.setLanguage('en');
const useStyles = makeStyles({
  root: {
    '& .MuiLink-root': {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
});
const SignUp = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [industryMultiTree, setIndustryMultiTree] = useState([]);
  const [contriesList, setContriesList] = useState([]);
  const [toggleForm, setToggleForm] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [lat, setLat] = useState('');
  const { isVerified, reCapchaToken } = useSelector((state) => state?.auth);
  const [showRecapcha, setShowRecapcha] = useState(false);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState('');
  const [lng, setLng] = useState('');
  function getLatLngByAddress(address) {
    Geocode.fromAddress(address).then(
      (response) => {
        let { address_components } = response.results[0];
        let { formatted_address } = response.results[0];

        if (formatted_address) {
          let formatted_address_split = formatted_address.match(/[^,]+,[^,]+/g);
          formik.setFieldValue(
            'addressLineOne',
            `${formatted_address_split[0]}`
          );
          formatted_address_split.shift();
          formik.setFieldValue(
            'addressLineTwo',
            formatted_address_split?.join(' ')
          );
        }
        if (formik.values.registration !== 'Ghana') {
          // setAddressFieldValue(address_components, 'country', 'country');
        }
        setAddressFieldValue(
          address_components,
          'administrative_area_level_1',
          'state'
        );
        setAddressFieldValue(
          address_components,
          'administrative_area_level_3',
          'city'
        );
        if (formik.values.registration !== 'Ghana') {
          setAddressFieldValue(address_components, 'postal_code', 'postalCode');
        }

        const { lat, lng } = response.results[0].geometry.location;
        setLat(lat);
        setLng(lng);
        formik.setFieldValue('longitude', String(lng));
        formik.setFieldValue('latitude', String(lat));
      },
      (error) => {
        // console.error(error);
      }
    );
  }

  function setAddressFieldValue(address_components = [], key, field) {
    let findValue = address_components.find((item) => item.types.includes(key));
    if (findValue) {
      formik.setFieldValue(field, findValue?.long_name);
    } else {
      formik.setFieldValue(field, '');
    }
  }
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: GOOGLE_MAP_KEY,
  });
  const getIndustryType = async () => {
    try {
      const res = await getIndustryTypeEnum();
      if (res) {
        setIndustryMultiTree(res?.data?.data);
      }
    } catch (e) {}
  };
  const getCountriesList = async () => {
    var res = await getCountries();
    if (res) {
      setContriesList(res?.data?.data?.countries);
    }
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      designation: '',
      email: '',
      code: '',
      phone: '',
      corporateName: '',
      registration: '',
      taxId: '',
      password: '',
      url: '',
      confirmPassword: '',
      acceptTermsOfUse: false,
      acceptTermsAndConditions: false,
      acceptPrivacyPolicy: false,
      acceptCreateAccount: false,
      address: '',
      addressLineOne: '',
      addressLineTwo: '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
      latitude: '',
      longitude: '',
      ghanapost_gps: '',
      primaryIndustry: null,
    },
    validationSchema: SignUpValidationSchema,
    onSubmit: async (values) => {
      if (values.password !== values.confirmPassword) {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: t('Both_passwords_should_be_same'),
          })
        );
        return;
      }
      if (values.primaryIndustry === null) {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: t('Please_select_primary_industry'),
          })
        );
        return;
      }
      // setShowRecapcha(true);
      setIsLoading(true);
      const params = {
        first_name: values.firstName,
        last_name: values.lastName,
        designation: values.designation,
        email: values.email,
        country_code: values.code,
        phone: values.phone,
        company_name: values.corporateName,
        country_of_incorporation: values.registration,
        tax_id: values.taxId,
        website: values.url,
        address: values.address,
        address_line_one: values.addressLineOne,
        address_line_two: values.addressLineTwo,
        country: values.country,
        state: values.state,
        city: values.city,
        postal_code: values.postalCode,
        latitude: values.latitude,
        longitude: values.longitude,
        password: values.password,
        ghanapost_gps: values.ghanapost_gps,
        primary_industry: values.primaryIndustry,
        user_type: 'individual',
        source: 'dashboard',
        role: 'PARTNER_ADMIN',
        token: reCapchaToken,
      };

      try {
        const response = await emailSignUp(params);
        if (response.data.status === 'success') {
          navigate(ROUTE.ROOT);
          navigate(ROUTE.VERIFY_EMAIL);
        }
      } catch (error) {
        setErrorMessage(error);
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: error,
          })
        );
      } finally {
        setIsLoading(false);
        setShowRecapcha(false);
      }
    },
  });
  const handleSecondForm = () => {
    if (formik.values.primaryIndustry === null) {
      formik.setFieldError('primaryIndustry', formik.values.primaryIndustry);
      formik.setFieldTouched('primaryIndustry');
      return;
    }
    if (formik.values.registration === '') {
      formik.setFieldError('registration', formik.values.registration);
      formik.setFieldTouched('registration', true);
      return;
    }
  };
  const handleFirstForm = () => {
    if (
      formik.values.firstName === '' ||
      formik.values.lastName === '' ||
      formik.values.designation === '' ||
      formik.values.email === '' ||
      formik.values.code === '' ||
      formik.values.phone === '' ||
      formik.values.acceptCreateAccount === false
    ) {
      if (formik.values.firstName === '') {
        formik.setFieldError('firstName', formik.values.firstName);
        formik.setFieldTouched('firstName');
      }
      if (formik.values.lastName === '') {
        formik.setFieldError('lastName', formik.values.lastName);
        formik.setFieldTouched('lastName');
      }
      if (formik.values.designation === '') {
        formik.setFieldError('designation', formik.values.designation);
        formik.setFieldTouched('designation');
      }

      if (formik.values.email === '') {
        formik.setFieldError('email', formik.values.email);
        formik.setFieldTouched('email');
      }
      if (
        !formik.values.email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        formik.setFieldError('email', formik.values.email);
        formik.setFieldTouched('email');
      }

      //         .matches(/^\d+$/, 'Phone_number_is_not_valid');
      // // .matches(new RegExp('[0-9]{7}'), 'Phone_number_is_not_valid');

      if (formik.values.code === '') {
        formik.setFieldError('code', formik.values.code);
        formik.setFieldTouched('code');
      }
      if (formik.values.phone === '') {
        formik.setFieldError('phone', formik.values.phone);
        formik.setFieldTouched('phone');
      }
      if (
        formik.values.phone !== '' &&
        !formik.values.phone.match(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
        )
      ) {
        formik.setFieldError('phone', formik.values.phone);
        formik.setFieldTouched('phone');
      }
      if (formik.values.acceptCreateAccount === false) {
        formik.setFieldError(
          'acceptCreateAccount',
          formik.values.acceptCreateAccount
        );
        formik.setFieldTouched('acceptCreateAccount');
      }
    } else {
      setToggleForm(false);
    }
  };
  const sortByProperty = (property) => {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;

      return 0;
    };
  };
  const handleCapcha = async (e) => {
    e?.preventDefault();

    const err = await formik?.validateForm();
    // console.log(err);
    if (Object.keys(err).length === 0) {
      setShowRecapcha(true);
    } else {
      Object.keys(err).forEach((k) => {
        formik.setFieldTouched(k, true);
      });
    }
  };
  useEffect(() => {
    getCountriesList();
    formik.setFieldError('registration', '');
    formik.setFieldTouched('registration', false);
    getIndustryType();

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (reCapchaToken) {
      formik.handleSubmit();
    } else {
      setIsLoading(false);
    }
  }, [reCapchaToken]);
  useEffect(() => {
    setIsLoading(false);
    setTimeout(() => {
      setShowRecapcha(false);
    }, 5000);
  }, [isVerified]);
  return (
    <Box className={classes.root}>
      <SnackBar />
      {!toggleForm && (
        <Box sx={{ mt: 4 }} onClick={() => setToggleForm(true)}>
          <ArrowBack />
        </Box>
      )}
      {toggleForm ? (
        <>
          <Typography
            my={4}
            sx={{
              fontWeight: 900,
              fontSize: '24px',
              fontStyle: 'normal',
            }}
          >
            {t('Sign_Up_title')}
          </Typography>
          <Typography
            my={4}
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              fontStyle: 'normal',
            }}
          >
            {t('Auth_sub_title')}
          </Typography>
        </>
      ) : (
        <Typography
          my={4}
          sx={{
            fontWeight: 900,
            fontSize: '24px',
            fontStyle: 'normal',
          }}
        >
          {t('Business_info')}
        </Typography>
      )}

      {toggleForm ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12}>
              <TextField
                inputProps={{
                  'data-testid': 'signup-firstname',
                }}
                sx={{ my: 2 }}
                label={t('First_name')}
                required
                fullWidth
                name='firstName'
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                value={formik.values.firstName
                  .trimStart()
                  .replace(/\s\s+/g, '')
                  .replace(/\p{Emoji_Presentation}/gu, '')}
                onChange={(e) => formik.handleChange(e)}
                helperText={
                  formik.touched.firstName &&
                  formik.errors.firstName &&
                  t(formik.errors.firstName)
                }
              />
            </Grid>

            <Grid item lg={6} xl={6} xs={12} md={12} sm={12}>
              <TextField
                inputProps={{
                  'data-testid': 'signup-lastname',
                }}
                sx={{ my: 2 }}
                label={t('Last_name')}
                required
                fullWidth
                name='lastName'
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                value={formik.values.lastName
                  .trimStart()
                  .replace(/\s\s+/g, '')
                  .replace(/\p{Emoji_Presentation}/gu, '')}
                onChange={(e) => formik.handleChange(e)}
                helperText={
                  formik.touched.lastName &&
                  formik.errors.lastName &&
                  t(formik.errors.lastName)
                }
              />
            </Grid>
          </Grid>
          <TextField
            inputProps={{ 'data-testid': 'signup-designation' }}
            fullWidth
            required
            sx={{ my: 2 }}
            label={t('Designation')}
            name='designation'
            error={
              formik.touched.designation && Boolean(formik.errors.designation)
            }
            value={formik.values.designation
              .trimStart()
              .replace(/\s\s+/g, '')
              .replace(/\p{Emoji_Presentation}/gu, '')}
            onChange={(e) => formik.handleChange(e)}
            helperText={
              formik.touched.designation &&
              formik.errors.designation &&
              t(formik.errors.designation)
            }
          />

          <Grid container spacing={2}>
            <Grid item lg={12} xl={12} xs={12} md={12} sm={12}>
              <TextField
                inputProps={{ 'data-testid': 'signup-email' }}
                fullWidth
                required
                sx={{ my: 2 }}
                label={t('Contact_email')}
                name='email'
                error={formik.touched.email && Boolean(formik.errors.email)}
                value={formik.values.email
                  .trimStart()
                  .replace(/\s\s+/g, '')
                  .replace(/\p{Emoji_Presentation}/gu, '')}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                helperText={
                  formik.touched.email &&
                  formik.errors.email &&
                  t(formik.errors.email)
                }
              />
            </Grid>
          </Grid>
          <Grid item lg={12} xl={12} xs={12} md={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item lg={4} xl={4} md={4} xs={4} sm={4}>
                <Autocomplete
                  required
                  data-testid='code'
                  sx={{ my: 2 }}
                  name={'code'}
                  label={t('Code')}
                  fullWidth
                  value={formik.values.code}
                  onChange={(event, item) => {
                    formik.setFieldValue('code', item.label);
                  }}
                  options={countryList
                    ?.sort((a, b) => a?.dialCode - b?.dialCode)
                    .map((item) => ({
                      label: item.dialCode,
                      value: item.dialCode,
                    }))}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value
                  }
                  freeSolo={false}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={t('Code')}
                      error={formik.touched.code && Boolean(formik.errors.code)}
                      helperText={
                        formik.touched.code &&
                        formik.errors.code &&
                        t(formik.errors.code)
                      }
                      variant={'outlined'}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      value={formik?.values?.code}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={8} xl={8} xs={8} md={8} sm={8}>
                <TextField
                  // inputProps={{ 'data-testid': 'signup-phone' }}
                  required
                  sx={{ my: 2 }}
                  label={t('Mobile_number')}
                  fullWidth
                  name='phone'
                  // type='number'
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  value={formik.values.phone.trim().replace(/\s\s+/g, '')}
                  // .replace(/\s\s+/g, '')
                  // .replace(/\p{Emoji_Presentation}/gu, '')}
                  // onChange={(e) => formik.handleChange(e)}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  inputProps={{
                    maxlength: 15,
                    minLength: 6,
                    'data-testid': 'signup-phone',
                  }}
                  helperText={
                    formik.touched.phone &&
                    formik.errors.phone &&
                    t(formik.errors.phone)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <FormControlLabel
            required
            name='acceptCreateAccount'
            control={<Checkbox />}
            checked={formik.values.acceptCreateAccount}
            onChange={(e) => formik.handleChange(e)}
            label={t('Accept_create_account')}
          />
          {formik.errors.acceptCreateAccount && (
            <Box className='Mui-error'>
              {formik.touched.acceptCreateAccount &&
                Boolean(formik.errors.acceptCreateAccount) && (
                  <Box
                    sx={{
                      color: 'error.main',
                      fontSize: '0.75rem',
                    }}
                  >
                    {t(formik.errors.acceptCreateAccount)}
                  </Box>
                )}
            </Box>
          )}
          <Button
            data-testid='continue'
            fullWidth
            variant='contained'
            onClick={() => {
              // setToggleForm(false)
              handleFirstForm();
            }}
            sx={{
              my: 2,
              textTransform: 'none',
              '& .MuiCircularProgress-root ': {
                color: '#ffff',
              },
            }}
          >
            {t('Continue')}
          </Button>
        </Box>
      ) : (
        <Box sx={{ my: 2 }}>
          <TextField
            inputProps={{ 'data-testid': 'signup-corporatename' }}
            fullWidth
            required
            sx={{ my: 2 }}
            label='Company/Corporate Name'
            name='corporateName'
            error={
              formik.touched.corporateName &&
              Boolean(formik.errors.corporateName)
            }
            value={formik.values.corporateName
              .trimStart()
              .replace(/\s\s+/g, '')
              .replace(/\p{Emoji_Presentation}/gu, '')}
            onChange={(e) => formik.handleChange(e)}
            helperText={
              formik.touched.corporateName &&
              formik.errors.corporateName &&
              t(formik.errors.corporateName)
            }
          />

          <Grid item lg={12} xl={12} xs={12} md={12} sm={12}>
            <TextField
              inputProps={{ 'data-testid': 'signup-taxid' }}
              required
              sx={{ my: 2 }}
              label='Company Tax ID'
              fullWidth
              name='taxId'
              error={formik.touched.taxId && Boolean(formik.errors.taxId)}
              value={formik.values.taxId
                .trimStart()
                .replace(/\s\s+/g, '')
                .replace(/\p{Emoji_Presentation}/gu, '')}
              onChange={(e) => formik.handleChange(e)}
              helperText={
                formik.touched.taxId &&
                formik.errors.taxId &&
                t(formik.errors.taxId)
              }
            />
          </Grid>
          <TextField
            inputProps={{ 'data-testid': 'signup-url' }}
            required
            fullWidth
            sx={{ my: 2, mb: 3 }}
            label='Company Website'
            name='url'
            error={formik.touched.url && Boolean(formik.errors.url)}
            value={formik.values.url
              .trimStart()
              .replace(/\s\s+/g, '')
              .replace(/\p{Emoji_Presentation}/gu, '')}
            onChange={(e) => formik.handleChange(e)}
            helperText={
              formik.touched.url && formik.errors.url && t(formik.errors.url)
            }
          />

          <Grid item lg={12} xl={12} xs={12} md={12} sm={12}>
            <Autocomplete
              name='registration'
              label='Country/Place of Incorporation/Registration/Registration *'
              sx={{ mb: 3 }}
              fullWidth
              value={formik.values.registration}
              freeSolo={false}
              onChange={(event, item) => {
                formik.setFieldValue('registration', item?.label);

                formik.setFieldValue(
                  'country',
                  item?.label?.trimStart().replace(/\s\s+/g, '')
                );
              }}
              options={contriesList
                ?.sort(sortByProperty('name'))
                ?.map((item) => ({
                  label: item.name,
                  value: item.name,
                }))}
              isOptionEqualToValue={(option, value) => option?.value === value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    'Country/Place of Incorporation/Registration/Registration *'
                  }
                  error={
                    formik.touched.registration &&
                    Boolean(formik.errors.registration)
                  }
                  InputProps={{
                    ...params.InputProps,
                  }}
                  helperText={
                    formik.touched.registration &&
                    formik.errors.registration &&
                    t(formik.errors.registration)
                  }
                />
              )}
            />
          </Grid>
          <MultiTreeSelect
            label={`${t('PrimaryIndustry')} *`}
            name='primaryIndustry'
            sx={{ my: 3 }}
            defaultIndustries={formik.values.primaryIndustry}
            onChangeSelect={(val) => {
              if (val) {
                formik.setFieldValue('primaryIndustry', val.value);
              } else {
                formik.setFieldValue('primaryIndustry', null);
              }
            }}
            error={
              formik.touched.primaryIndustry &&
              Boolean(formik.errors.primaryIndustry)
            }
            helperText={
              formik.touched.primaryIndustry &&
              formik.errors.primaryIndustry &&
              t(formik.errors.primaryIndustry)
            }
            industryMultiTree={industryMultiTree}
          />
          {formik.values.registration === 'Ghana' && (
            <TextField
              // sx={{ mb: 2 }}
              name='ghanapost_gps'
              value={formik.values.ghanapost_gps}
              onChange={(e) => {
                formik.setFieldValue('ghanapost_gps', e.target.value);
                formik.setFieldTouched('ghanapost_gps', true);
              }}
              onBlur={(e) => {
                const enteredValue = e.target.value.trim();
                const postalCode = enteredValue.substring(0, 6);
                formik.setFieldValue('ghanapost_gps', enteredValue);
                formik.setFieldValue('postalCode', postalCode);
              }}
              error={
                formik.touched.ghanapost_gps &&
                Boolean(formik.errors.ghanapost_gps)
              }
              helperText={
                formik.touched.ghanapost_gps && t(formik.errors.ghanapost_gps)
              }
              label={`${t('Ghanapost_GPS')} *`}
              fullWidth
            />
          )}
          <Autocomplete
            id='address'
            name='address'
            label='Location '
            variant='outlined'
            fullWidth
            sx={{ my: 2, mt: 4 }}
            value={formik.values.address}
            onChange={(event, item) => {
              if (item) {
                getLatLngByAddress(item?.description);

                formik.setFieldValue('address', item?.label);
              }
            }}
            onInputChange={(event) => {
              if (event?.target?.value) {
                getPlacePredictions({
                  input: event?.target?.value,
                });
              }
            }}
            options={placePredictions.map((item) => ({
              ...item,
              label: item.description,
              value: item.place_id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Location '
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={
                  formik.touched.address &&
                  formik.errors.address &&
                  t(formik.errors.address)
                }
              />
            )}
          />

          <TextField
            id='addressLineOne'
            label='Address Line 1 (e.g. Street Name) *'
            variant={'outlined'}
            fullWidth
            sx={{ my: 2 }}
            name='addressLineOne'
            value={formik.values.addressLineOne}
            onChange={formik.handleChange}
            error={
              formik.touched.addressLineOne &&
              Boolean(formik.errors.addressLineOne)
            }
            helperText={
              formik.touched.addressLineOne &&
              formik.errors.addressLineOne &&
              t(formik.errors.addressLineOne)
            }
          />

          <TextField
            id='addressLineTwo'
            label='Address Line 2 (e.g. Neighborhood/ Locality)'
            variant={'outlined'}
            fullWidth
            sx={{ my: 2 }}
            name='addressLineTwo'
            value={formik.values.addressLineTwo}
            onChange={formik.handleChange}
          />

          <Grid container spacing={2}>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12} sx={{ my: 2 }}>
              <TextField
                id='country'
                label='Country/Region *'
                variant='outlined'
                fullWidth
                name='country'
                value={formik.values.country}
                // disabled
                onChange={(e) => {
                  //  formik.handleChange(e);
                  // if (formik.values.registration !== 'Ghana') {
                  //   formik.setFieldValue(
                  //     'country',
                  //     e.target?.value?.trimStart().replace(/\s\s+/g, '')
                  //   );
                  // }
                }}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={
                  formik.touched.country &&
                  formik.errors.country &&
                  t(formik.errors.country)
                }
              />
            </Grid>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12} sx={{ my: 2 }}>
              <TextField
                id='state'
                label='State/Province *'
                variant='outlined'
                fullWidth
                name='state'
                value={formik.values.state}
                onChange={(e) => {
                  //  formik.handleChange(e);
                  formik.setFieldValue(
                    'state',
                    e.target?.value?.trimStart().replace(/\s\s+/g, '')
                  );
                }}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={
                  formik.touched.state &&
                  formik.errors.state &&
                  t(formik.errors.state)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12} sx={{ my: 2 }}>
              <TextField
                id='city'
                label='City *'
                variant='outlined'
                fullWidth
                name='city'
                value={formik.values.city}
                onChange={(e) => {
                  //  formik.handleChange(e);
                  formik.setFieldValue(
                    'city',
                    e.target?.value?.trimStart().replace(/\s\s+/g, '')
                  );
                }}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={
                  formik.touched.city &&
                  formik.errors.city &&
                  t(formik.errors.city)
                }
              />
            </Grid>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12} sx={{ my: 2 }}>
              <TextField
                id='postalCode'
                name='postalCode'
                label='Post Code *'
                variant='outlined'
                fullWidth
                value={formik.values.postalCode}
                onChange={(e) => {
                  //  formik.handleChange(e);
                  if (formik.values.registration !== 'Ghana') {
                    formik.setFieldValue(
                      'postalCode',
                      e.target?.value?.trimStart().replace(/\s\s+/g, '')
                    );
                  }
                }}
                error={
                  formik.touched.postalCode && Boolean(formik.errors.postalCode)
                }
                helperText={
                  formik.touched.postalCode &&
                  formik.errors.postalCode &&
                  t(formik.errors.postalCode)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} xl={12} xs={12} md={12} sm={6} sx={{ my: 2 }}>
              <Box
                className='form-map form-item'
                sx={{ height: '221px', position: 'relative' }}
              >
                <GoogleApiWrapper lat={lat} lng={lng} />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12} sx={{ my: 2 }}>
              <TextField
                id='longitude'
                name='longitude'
                label='Longitude '
                variant={'outlined'}
                type='number'
                fullWidth
                value={formik.values.longitude}
                onChange={(e) => {
                  setLng(e.target.value);
                  formik.setFieldValue(
                    'longitude',
                    e.target?.value?.trimStart().replace(/\s\s+/g, '')
                  );
                }}
                error={
                  formik.touched.longitude && Boolean(formik.errors.longitude)
                }
                helperText={
                  formik.touched.longitude &&
                  formik.errors.longitude &&
                  t(formik.errors.longitude)
                }
              />
            </Grid>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12} sx={{ my: 2 }}>
              <TextField
                id='latitude'
                name='latitude'
                label='Latitude '
                variant={'outlined'}
                type='number'
                fullWidth
                value={formik.values.latitude}
                onChange={(e) => {
                  setLat(e.target.value);
                  formik.setFieldValue(
                    'latitude',
                    e.target?.value?.trimStart().replace(/\s\s+/g, '')
                  );
                }}
                error={
                  formik.touched.latitude && Boolean(formik.errors.latitude)
                }
                helperText={
                  formik.touched.latitude &&
                  formik.errors.latitude &&
                  t(formik.errors.latitude)
                }
              />
            </Grid>
          </Grid>
          <TextField
            inputProps={{ 'data-testid': 'signup-password' }}
            fullWidth
            required
            sx={{ mt: 2, mb: 1 }}
            label='Create Password'
            name='password'
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'
                  >
                    {showPassword ? (
                      <ICONS.VISIBILITY />
                    ) : (
                      <ICONS.VISIBILITY_OFF />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={formik.touched.password && Boolean(formik.errors.password)}
            value={formik.values.password
              .trimStart()
              .replace(/\s\s+/g, '')
              .replace(/\p{Emoji_Presentation}/gu, '')}
            onChange={(e) => formik.handleChange(e)}
            helperText={
              formik.touched.password &&
              formik.errors.password &&
              t(formik.errors.password)
            }
          />

          <TextField
            inputProps={{ 'data-testid': 'signup-confirmPassword' }}
            fullWidth
            sx={{ my: 2 }}
            label='Confirm Password'
            required
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge='end'
                  >
                    {showConfirmPassword ? (
                      <ICONS.VISIBILITY />
                    ) : (
                      <ICONS.VISIBILITY_OFF />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name='confirmPassword'
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            value={formik.values.confirmPassword
              .trimStart()
              .replace(/\s\s+/g, '')
              .replace(/\p{Emoji_Presentation}/gu, '')}
            onChange={(e) => formik.handleChange(e)}
            helperText={
              formik.touched.confirmPassword &&
              formik.errors.confirmPassword &&
              t(formik.errors.confirmPassword)
            }
          />

          <Box display='flex' flexDirection='column'>
            <FormControlLabel
              // required
              name='acceptTermsAndConditions'
              checked={formik.values.acceptTermsAndConditions}
              onChange={(e) => formik.handleChange(e)}
              control={<Checkbox />}
              label={
                <span className='light-gray-text f-12'>
                  {t('I_agree_new')}
                  <span
                    className='terms'
                    // style={{
                    //   textDecoration: 'none',
                    //   color: '#17236E',
                    //   fontWeight: 500,
                    // }}
                  >
                    {t('Terms_and_conditions')}
                  </span>{' '}
                  {t('In_Proxtera')}{' '}
                  <Link
                    sx={{
                      textDecoration: 'none',
                      color: 'primary.main',
                      fontWeight: 500,
                    }}
                    title='terms-of-service'
                    href='https://proxtera.com/commercial-agreement/'
                    target='_blank'
                    rel='noreferrer noopener'
                    aria-hidden='true'
                  >
                    {t('Commercial_agreement')}.
                  </Link>{' '}
                </span>
              }
            />
            {formik.errors.acceptTermsAndConditions && (
              <Box className='Mui-error'>
                {formik.touched.acceptTermsAndConditions &&
                  Boolean(formik.errors.acceptTermsAndConditions) && (
                    <Box
                      sx={{
                        fontSize: '0.75rem',
                        color: 'error.main',
                      }}
                    >
                      {t(formik.errors.acceptTermsAndConditions)}
                    </Box>
                  )}
              </Box>
            )}
            <FormControlLabel
              // required
              name='acceptTermsOfUse'
              checked={formik.values.acceptTermsOfUse}
              onChange={(e) => formik.handleChange(e)}
              control={<Checkbox />}
              label={
                <span className='light-gray-text f-12'>
                  {t('I_agree')}{' '}
                  <Link
                    sx={{
                      textDecoration: 'none',
                      color: 'primary.main',
                      fontWeight: 500,
                    }}
                    title='privacy-policy'
                    href='https://proxtera.com/termsofuse/'
                    target='_blank'
                    rel='noreferrer noopener'
                    aria-hidden='true'
                  >
                    {t('Terms_of_use')}
                  </Link>{' '}
                </span>
              }
            />
            {formik.errors.acceptTermsOfUse && (
              <Box className='Mui-error'>
                {formik.touched.acceptTermsOfUse &&
                  Boolean(formik.errors.acceptTermsOfUse) && (
                    <Box
                      sx={{
                        fontSize: '0.75rem',
                        color: 'error.main',
                      }}
                    >
                      {t(formik.errors.acceptTermsOfUse)}
                    </Box>
                  )}
              </Box>
            )}
            <FormControlLabel
              // required
              name='acceptPrivacyPolicy'
              checked={formik.values.acceptPrivacyPolicy}
              onChange={(e) => formik.handleChange(e)}
              control={<Checkbox />}
              label={
                <span className='light-gray-text f-12'>
                  {t('I_agree')}{' '}
                  <Link
                    sx={{
                      textDecoration: 'none',
                      color: 'primary.main',
                      fontWeight: 500,
                    }}
                    title='privacy-policy'
                    href='https://proxtera.com/privacy-policy/'
                    target='_blank'
                    rel='noreferrer noopener'
                    aria-hidden='true'
                  >
                    {t('Privacy_policy')}
                  </Link>{' '}
                </span>
              }
            />
            {formik.errors.acceptPrivacyPolicy && (
              <Box className='Mui-error'>
                {formik.touched.acceptPrivacyPolicy &&
                  Boolean(formik.errors.acceptPrivacyPolicy) && (
                    <Box
                      sx={{
                        fontSize: '0.75rem',
                        color: 'error.main',
                      }}
                    >
                      {t(formik.errors.acceptPrivacyPolicy)}
                    </Box>
                  )}
              </Box>
            )}
          </Box>
          {/* {errorMessage !== '' && (
            <Typography mt={1} ml={1}>
              {Boolean(errorMessage) && (
                <Box sx={{ color: 'error.main' }}>{errorMessage}</Box>
              )}
            </Typography>
          )} */}
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            fullWidth
            variant='contained'
            onClick={(e) => {
              handleSecondForm();
              //  const handleCapcha = async (e) => {
              handleCapcha(e);
              // formik.handleSubmit();
            }}
            sx={{
              my: 2,
              textTransform: 'none',
              '& .MuiCircularProgress-root ': {
                color: '#ffff',
              },
            }}
          >
            {t('Sign_up')}
          </LoadingButton>
          {showRecapcha && (
            <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
              <TurnstileWidget />
            </Box>
          )}
        </Box>
      )}
      <Grid
        container
        display='flex'
        justifyContent='space-between'
        mt={2}
        mb={4}
      >
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Box
            color='primary.main'
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(ROUTE.ROOT)}
          >
            {t('Back_to_login')}
          </Box>
        </Grid>
        {!toggleForm && (
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box
              display='flex'
              justifyContent='flex-end'
              color='primary.main'
              sx={{ cursor: 'pointer' }}
              onClick={() => setToggleForm(true)}
            >
              {t('Back')}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SignUp;
