import authReducer from './Auth/index';
import onBoardReducer from './OnBoard/index';
import userReducer from './Profile/index';
import sfeReducer from './Sfe/index';
import utilsReducer from './Utils/index';
const rootReducer = {
  auth: authReducer,
  utils: utilsReducer,
  user: userReducer,
  sfe: sfeReducer,
  onboard: onBoardReducer
};
export default rootReducer;
