import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../../constants/Icons';
import { ROUTE } from '../../../../../constants/Routes';
import { toggleSnackBar } from '../../../../../redux/Reducer/Utils';
import { resetPasswordViaToken } from '../../../../../services/Auth';
import { ResetPasswordValidationSchema } from '../../../../../utils/ValidationSchema/Auth';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  SnackBar,
  TextField,
  Typography
} from '../../../../Containers/index';

const ForgetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().search;
  const token = new URLSearchParams(location).get('token');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: ResetPasswordValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      if (
        values.password.trim().toLowerCase() !==
        values.confirmPassword.trim().toLowerCase()
      ) {
        setIsLoading(false);

        setErrorMsg('Both_passwords_should_be_same');
        // dispatch(
        //   toggleSnackBar({
        //     isOpen: true,
        //     type: 'error',
        //     message: t('Both_passwords_should_be_same'),
        //   })
        // );
        return;
      }
      if (
        values.password.toLowerCase() === values.confirmPassword.toLowerCase()
      ) {
        const params = {
          token: token,
          new_password: values.password
        };
        try {
          let res = await resetPasswordViaToken(params);
          navigate(ROUTE.ROOT);
          dispatch(
            toggleSnackBar({
              isOpen: true,
              type: 'success',
              message: res?.data?.message
            })
          );
        } catch (error) {
          dispatch(
            toggleSnackBar({
              isOpen: true,
              type: 'error',
              message: error
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    }
  });

  return (
    <Box>
      <SnackBar />
      <Typography
        my={4}
        sx={{
          fontWeight: 900,
          fontSize: '24px',
          fontStyle: 'normal'
        }}
      >
        {t('Create_new_password_title')}
      </Typography>

      <TextField
        inputProps={{ 'data-testid': 'reset-password' }}
        fullWidth
        required
        sx={{ mt: 2, mb: 1 }}
        label='Password'
        name='password'
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPassword(!showPassword)}
                edge='end'
              >
                {showPassword ? <ICONS.VISIBILITY /> : <ICONS.VISIBILITY_OFF />}
              </IconButton>
            </InputAdornment>
          )
        }}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={
          formik.touched.password &&
          formik.errors.password &&
          t(formik.errors.password)
        }
        value={formik.values.password
          .trimStart()
          .replace(/\s\s+/g, '')
          .replaceAll(/ /g, '')
          .replace(/\p{Emoji_Presentation}/gu, '')}
        onChange={(e) => {
          setErrorMsg('');
          formik.handleChange(e);
        }}
      />

      <TextField
        inputProps={{ 'data-testid': 'reset-confirmPassword' }}
        fullWidth
        sx={{ my: 2 }}
        name='confirmPassword'
        label='Confirm Password'
        required
        type={showConfirmPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                edge='end'
              >
                {showConfirmPassword ? (
                  <ICONS.VISIBILITY />
                ) : (
                  <ICONS.VISIBILITY_OFF />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        value={formik.values.confirmPassword
          .trimStart()
          .replace(/\s\s+/g, '')
          .replaceAll(/ /g, '')

          .replace(/\p{Emoji_Presentation}/gu, '')}
        onChange={(e) => {
          setErrorMsg('');
          formik.handleChange(e);
        }}
        helperText={
          formik.touched.confirmPassword &&
          formik.errors.confirmPassword &&
          t(formik.errors.confirmPassword)
        }
      />

      {errorMsg !== '' && (
        <Box>
          <Box
            sx={{
              color: 'error.main',
              fontSize: '0.75rem',
              ml: 4
            }}
          >
            {t(errorMsg)}
          </Box>
        </Box>
      )}
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        fullWidth
        variant='contained'
        onClick={formik.handleSubmit}
        sx={{
          my: 2,
          textTransform: 'none',
          '& .MuiCircularProgress-root ': {
            color: '#ffff'
          }
        }}
      >
        {t('Submit')}
      </LoadingButton>
      <Grid container display='flex' justifyContent='space-between' mt={2}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Box
            color='primary.main'
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(ROUTE.ROOT);
            }}
          >
            {t('Back_to_login')}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgetPassword;
