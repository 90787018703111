import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// import { useViewport } from '../../../../hooks';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  SnackBar,
  Typography,
  makeStyles,
} from '../../../../Containers/index';
// import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useViewport } from '../../../../../hooks';
import { SFE_SERVER_URL } from '../../../../../services/Env';
import { apiPost } from '../../../../../utils/Axios/axios';

// import MultiTreeSelect from '../../../../Containers/MultiTree';

const useStyles = makeStyles({
  root: {},
});

function FinAnalytics() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const [powerBIData, setPowerBIData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [embedURL, setEmbedURL] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const handlePowerBIToken = async () => {
    try {
      setIsLoading(true);
      setErrorMsg('');
      const url = SFE_SERVER_URL + '/dashboard/generate_access_token';
      const result = await apiPost(url, {
        provider: 'powerbi',
      });
      console.log(result.data.data);
      if (result?.data?.data) {
        const res = result?.data?.data;
        setPowerBIData(res);
        const financeData = res?.embedUrl?.find((e) => e?.name === 'financing');
        console.log('"finance"', financeData);
        if (!financeData) {
          setErrorMsg('No data is available');
          return;
        }
        setEmbedURL(financeData);
      }
      console.log('result', result);
    } catch (e) {
      console.log('err', e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handlePowerBIToken();
    // const formData = new FormData();
    // axios
    //   .post(
    //     // `https://login.microsoftonline.com/${'24eda686-3da3-422d-9460-443a50ed67a3'}/oauth2/token`,
    //     // 'https://login.microsoftonline.com/common/',
    //     'https://login.microsoftonline.com/24eda686-3da3-422d-9460-443a50ed67a3/oauth2/v2.0/token',
    //     new URLSearchParams({
    //       client_id: 'de4be58c-4c04-43c6-a358-6756fbbb6d14',
    //       client_secret: 'Sa68Q~7DGQWDd4XF91x1Rm9NDxs_lXij52EiAc6R',
    //       grant_type: 'client_credentials',
    //       scope: 'https://analysis.windows.net/powerbi/api/.default',
    //     })

    //     // {
    //     //   client_id: 'de4be58c-4c04-43c6-a358-6756fbbb6d14',
    //     //   client_secret: 'Sa68Q~7DGQWDd4XF91x1Rm9NDxs_lXij52EiAc6R',
    //     //   resource: 'https://analysis.windows.net/powerbi/api',
    //     //   grant_type: 'client_credentials',
    //     //   scope: 'https://analysis.windows.net/powerbi/api/.default',
    //     // }
    //   )
    //   .then((response) => {
    //     console.log(response);
    //     // setReportConfig({
    //     //   …reportConfig,
    //     //   embedUrl: response.value.embedUrl,
    //     //   accessToken: response.value.token,
    //     //   id: response.value.id
    //     // });
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }, []);
  if (isLoading) {
    return (
      <Box
        sx={{
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (!isLoading && errorMsg !== '') {
    return (
      <Box
        sx={{
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='h6'>{errorMsg}</Typography>
      </Box>
    );
  } else
    return (
      <Box
        component={Card}
        sx={{
          my: 4,
          p: 4,
          height: '100vh',
        }}
      >
        <SnackBar />
        {/* <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} marginTop='10px' marginLeft={'10px'}>
          <Typography
            variant='h5'
            component='h3'
            sx={{ fontWeight: 700, px: 4, py: 4 }}
          >
            {' '}
            Invite Member
          </Typography>
        </Grid>
      </Grid> */}
        {/* <Container maxWidth='sm' sx={{ mb: 8 }}> */}
        {/* {isLoading && Object.keys(powerBIData).length > 0 ? (
        <Box
          sx={{
            height: '80vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : ( */}
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              '& iframe': {
                height: '100vh !important',
              },
            }}
          >
            <PowerBIEmbed
              embedConfig={{
                type: 'report',
                id: embedURL?.reportId,
                // embedUrl: undefined,
                embedUrl: embedURL?.embedUrl,
                // 'https://app.powerbi.com/reportEmbed?reportId=818a4fb3-a75b-4c5e-b72f-ba04476beae4&groupId=f8bd68a6-95f1-48e6-9aff-ca517c58095e&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUVBU1QtQVNJQS1DLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',

                accessToken: powerBIData?.accessToken,
                // 'H4sIAAAAAAAEAB2Uxw6saBaD3-VuGQmKUMBIvSAUOWfYkYucfmJr3n2qe21L1vlkn7__WOndT2nx579_as8f4yRwHIEmeHQOuVRECDTXRb6XQGt2nw-MI-5Ly_QqhY0McP2MK-x6sW-xuUNK3ZZMcV-LFTTNEO-TOTXQOB-wIzH164JWaX8Ha8-Vxi1_fdc3zEaXe5aZgqV_Rlc2z_wdVzntG-Izld9-g7Fhy69wdXrbg8_FahPbJnlW10IxydX8A0m4pt0NlTmSBWKRysI0nxrSOcHWF3crD9VOwfwl-4jKdNeMG_YSEyn7BW3PsrUBBFEQty-_Sqf2TMEUJmFYdjn7nDPpCHaQuNLA8ISOd4VX_qSJEGxn8e8d7xXNBrM6xNfsmW03Hyl1YA3Z8LTnmlqym2gpHBf41Fhq3nvCZ0ekFbVAxrqWdjezsQAqqZ5r1SQOvXdJWaSTzFsbKmkm1UjevpEMMWBTbm5oxnYiGhV73JuxDDt71pBlSRkUroH4NrJxWz_lI8y_K1mYCDKp0ZunUU-ddA7CKXtFLkhnQZGPiyiw9Q3i3Yse_r7jasSszm_Didpa189VzOFOj6Sbr700e8cs6OZgDE5kYuBX72NrK5jbNo_IyLK4WvNNdzOyZFQovGoZSs4XlsnYDpEH1Cn9L1cVJRPo6nNIyNYPUNCcpIduwD1x6MJ5vwhfuoJFEeHDuyQIdkSUJMYdR9y_35qO26kQypIggdMK-0pY1m-DmgU1inusWZlNX21qrtXsDXTT4rXmVH4wN8GaJK-KKgBNAJWTHO90Urtb56tBC5pd940A6eDTBQa6TNR7gerS5xYAnGyrvte6iItybS8VdXhUgvOGn35nfLJZlAmyxnylbjK_wWs4TaQRzGDVBi-O13aY5etyxfxsOPLMwJ___OHWewaTWt6_ORWhIpm0qAuTQMKoHe99xOk9WcuGmSiIfC3TxWytYbLKRK5bvgyu3WruTZ4x7qMnwp03bzAf3-_Gbac72YwHsuek-OPNmgXMSOLjDrSh1EoOUFfj8drqEHz0idqsK8-Om7DuJSF492ySRnGVJgSYFepv_PMyInvOP9kUcQwIVYTtD7sJIjoFjv4lKo_NbUDvp4BeYYCtEvQWdLNbHN18vNx6smNpzRHJdewRvf2uGrY0Zub4VfeC_PQ8bLviiDXWiYnv6fTXI-eWtDCCoAUzuXFupOBQZCXeOMMbyDfHsQktEGrvFWB8mvAttVDMEhEB8dv-VSDiFfY5uQeTEz8Yz2ao_ddf_2C-52-5ysGP8piKSeK--IRe1EGlXOKio-P81-U29ZiCfS1_tmrEpzhD7xJGBYylhVoY03AAzVyewsfHiuKmVDa6Y6hGcKhQqRAGBRKx46ukZt1JI3SpBtWPIOwicYh1oiTJsTwTN_PlK5DxxS4ieFqxu2kG8LvIXFS-nvqhhGU5YsW9Jir9W-FGrs9AhT7CVpXazbWUNxaMWvDavj-BQoXs5-r4pfTujqfI3EP5zOpIJ4cuk4qpOqowWJMFRpafLOj0PR45MJtr0yMQJITxh0xm2IKmIhrtW1Q7NRzrQcZPued5Xg5ZmoVOr4YuuT51VEGN6Nt-Tc5giVS17xgwrwD-vYEWsft6xGabVM_C7SwnLonc1HRVuy82kI0McbbgKyNYaJEr88P8v_8DBOhctUIGAAA=.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUVBU1QtQVNJQS1DLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJleHAiOjE3MTYyNjk2NzQsImFsbG93QWNjZXNzT3ZlclB1YmxpY0ludGVybmV0Ijp0cnVlfQ==',
                tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                // settings: {
                //   panes: {
                //     filters: {
                //       expanded: false,
                //       visible: false,
                //     },
                //   },
                // background: models.BackgroundType.Transparent,
                // },
              }}
              eventHandlers={
                new Map([
                  [
                    'loaded',
                    function () {
                      console.log('Report loaded');
                    },
                  ],
                  [
                    'rendered',
                    function () {
                      console.log('Report rendered');
                    },
                  ],
                  [
                    'error',
                    function (event) {
                      console.log(event.detail);
                    },
                  ],
                  ['visualClicked', () => console.log('visual clicked')],
                  ['pageChanged', (event) => console.log(event)],
                ])
              }
              cssClassName={'reportClass'}
              getEmbeddedComponent={(embeddedReport) => {
                // this.report = embeddedReport;
                window.report = embeddedReport;
              }}
            />
            {/* <iframe
            width='100%'
            // height='100'
            src='https://app.powerbi.com/reportEmbed?reportId=9e503662-062c-45f2-978e-cad42ba2d1df&autoAuth=true&ctid=24eda686-3da3-422d-9460-443a50ed67a3&clientSideAuth=0&fallbackError=%7B%22flow%22%3A%22Reportembed%22%2C%22source%22%3A%22System%22%2C%22info%22%3A%22popup_window_error%3A+Error+opening+popup+window.+This+can+happen+if+you+are+using+IE+or+if+popups+are+blocked+in+the+browser.+Details%3A+BrowserAuthError%3A+empty_window_error%3A+window.open+returned+null+or+undefined+window+object.%22%2C%22code%22%3A%22popup_window_error%22%7D'
          /> */}
          </Grid>
        </Grid>
        {/* // )} */}
        {/* </Container> */}
      </Box>
    );
}

export default FinAnalytics;
