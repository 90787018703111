import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getNotifications } from '../../../services/Auth';
import { getProfileDetail } from '../../../services/Profile';
import { getTeamMembers } from '../../../services/Sfe';

const initialState = {
  userData: [],
  isLoadingProfile: false,
  teamMembers: [],
  isTeamLoading: false,
  errorMessage: '',
  isAdmin: false,
  notification: null,
  navToAddUserTab: false,
};

export const getUsersData = createAsyncThunk('users/userData', async () => {
  console.log('inove');
  try {
     let response = await getProfileDetail();
  // localStorage.setItem('isSocial', response.data?.data?.is_social_login);
  return response.data.data;
  } catch (e) {
    console.log('error', e);
  }
 
});

export const getTeam = createAsyncThunk('users/teamMembers', async () => {
  let response = await getTeamMembers();
  // localStorage.setItem('isSocial', response.data?.data?.is_social_login);
  return response.data.data;
});
export const getPendingNotifications = createAsyncThunk(
  'users/notifications',
  async () => {
    let response = await getNotifications();
    // localStorage.setItem('isSocial', response.data?.data?.is_social_login);
    return response.data.data;
  }
);
let userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    handleAddUserTab: function (state, action) {
      state.navToAddUserTab = action.payload;
    },
  },
  extraReducers: {
    [getUsersData.pending]: (state, action) => {
      state.isLoadingProfile = true;
    },
    [getUsersData.fulfilled]: (state, action) => {
      state.isLoadingProfile = false;
      console.log('action.payload', action.payload);
      state.userData = action.payload;
      state.isAdmin =
        action.payload.roles?.includes('PARTNER_SUPER_ADMIN') ||
        action.payload.roles?.includes('PARTNER_DBG') ||
        action.payload.roles?.includes('PARTNER_CBG');
    },
    [getUsersData.rejected]: (state, action) => {
      state.isLoadingProfile = false;
      state.errorMessage = `Oops! Something goes wrong!`;
    },

    [getTeam.pending]: (state, action) => {
      state.isTeamLoading = true;
    },
    [getTeam.fulfilled]: (state, action) => {
      state.isTeamLoading = false;
      state.teamMembers = action.payload;
    },
    [getTeam.rejected]: (state, action) => {
      state.isTeamLoading = false;
      state.errorMessage = `Oops! Something goes wrong!`;
    },
    [getPendingNotifications.pending]: (state, action) => {
      state.isLoadingProfile = true;
    },
    [getPendingNotifications.fulfilled]: (state, action) => {
      state.isLoadingProfile = false;
      state.notification = action.payload;
    },
    [getPendingNotifications.rejected]: (state, action) => {
      state.isLoadingProfile = false;
      state.errorMessage = `Oops! Something goes wrong!`;
    },
  },
});
export const { updateProfile, handleStepper, handleAddUserTab } =
  userSlice.actions;

export default userSlice.reducer;
