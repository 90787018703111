import { apiGet, apiPost, apiPut } from '../../utils/Axios/axios';
import { SFE_SERVER_URL } from '../Env';

const BASE_AUTH_URL = SFE_SERVER_URL + '/finance';
//Base auth url
const GET_VALIDATION_REQUESTS = `${BASE_AUTH_URL}/data_validation_requests`;
const GET_VALIDATION_REQUEST = `${BASE_AUTH_URL}/data_validation_request/`;
const STACAI_URL = `${BASE_AUTH_URL}/retrieve_gbb_stacai_info`;

export const getValidationData = (apiData) => {
  return apiPost(GET_VALIDATION_REQUESTS, apiData);
};
export const getValidationDetails = (id, type) => {
  return apiGet(GET_VALIDATION_REQUEST + id + '?type=' + type);
};
export const updateValidationDetails = (apiData) => {
  return apiPut(GET_VALIDATION_REQUEST, apiData);
};

export const getGBBAI = (params) => {
  return apiPost(STACAI_URL, params);
};
