import hands from '../assets/Images/Handshake.png';
import dataValidation from '../assets/Images/dataValidation.png';
import education from '../assets/Images/education.png';
import finance from '../assets/Images/finance.png';
import financeAnalysis from '../assets/Images/financeAnalysis.png';

import accountCheck from '../assets/Images/accountCheck.png';
import caution from '../assets/Images/caution.png';
import comingSoon from '../assets/Images/comingSoon.png';
import coworking from '../assets/Images/coworking.png';
import dashboard from '../assets/Images/dashboard.png';
import errorImage from '../assets/Images/error.png';
import imapact from '../assets/Images/impact.png';
import loginBG from '../assets/Images/loginBG.png';
import logo from '../assets/Images/logo.png';

export const IMAGES = {
  LOGIN_BG: loginBG,
  ERROR_IMAGE: errorImage,
  LOGO: logo,
  DASHBOARD: dashboard,
  COMINGSOON: comingSoon,
  CAUTION: caution,
  ACCOUNTCHECK: accountCheck,
  COWORK: coworking,
  HANDS: hands,
  DATA_VALIDATION: dataValidation,
  EDUCATION: education,
  FINANCE: finance,
  IMAPACT: imapact,
  FIN_ANALYTICS: financeAnalysis,
};
