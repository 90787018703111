import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  getAnalyticsData,
  getFFL,
  getGFL,
  getGIFE,
  getGOFL,
  getVocherCodes,
  getVocherUsers,
} from '../../../services/Sfe';

let initialState = {
  isVocherLoading: false,
  voucherUsers: [],
  isGOFLLoading: false,
  GOFL: [],
  isFFLLoading: false,
  FFL: [],
  isGFLLoading: false,
  GFL: [],
  isAnalyticsLoading: false,
  analyticsData: [],
  isVocherCodeLoading: false,

  voucherData: [],
};

const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};
export const getVocherCodesData = createAsyncThunk(
  'sfe/getVocherCodes',
  async () => {
    let response = await getVocherCodes();
    return response.data?.data?.voucher_codes;
  }
);
export const getAnalytics = createAsyncThunk(
  'sfe/getAnalytics',
  async (voucherCode) => {
    let response = await getAnalyticsData(voucherCode);
    return response.data;
  }
);
export const getVocherUsersData = createAsyncThunk(
  'sfe/getVocherUsersData',
  async (data) => {
    var params = {
      category: 'total_dbg_vouchers_redeemed',
      voucher_code: data?.data,
    };
    if (data?.dateRange?.startDate && data?.dateRange?.endDate) {
      params['start_date'] = formatDate(data?.dateRange?.startDate);
      params['end_date'] = formatDate(data?.dateRange?.endDate);
    }
    let response = await getVocherUsers(params);
    return response?.data?.data;
  }
);
export const getGOFLData = createAsyncThunk('sfe/getGOFLData', async (data) => {
  var params = {
    category: 'total_dbg_global_users',
    voucher_code: data?.data,
  };
  if (data?.dateRange?.startDate && data?.dateRange?.endDate) {
    params['start_date'] = formatDate(data?.dateRange?.startDate);
    params['end_date'] = formatDate(data?.dateRange?.endDate);
  }
  let response = await getGOFL(params);
  return response?.data?.data;
});
export const getFFLData = createAsyncThunk('sfe/getFFLData', async (data) => {
  var params = {
    category: 'total_dbg_foundational_users',
    voucher_code: data?.data,
  };
  if (data?.dateRange?.startDate && data?.dateRange?.endDate) {
    params['start_date'] = formatDate(data?.dateRange?.startDate);
    params['end_date'] = formatDate(data?.dateRange?.endDate);
  }
  let response = await getFFL(params);
  return response?.data?.data;
});
export const getGFLData = createAsyncThunk('sfe/getGFLData', async (data) => {
  var params = {
    category: 'total_dbg_green_users',
    voucher_code: data?.data,
  };
  if (data?.dateRange?.startDate && data?.dateRange?.endDate) {
    params['start_date'] = formatDate(data?.dateRange?.startDate);
    params['end_date'] = formatDate(data?.dateRange?.endDate);
  }
  let response = await getGFL(params);
  return response?.data?.data;
});
export const getGIFEData = createAsyncThunk('sfe/getGIFEData', async (data) => {
  var params = {
    category: 'gife_finance_requests',
    voucher_code: data?.data,
  };
  if (data?.dateRange?.startDate && data?.dateRange?.endDate) {
    params['start_date'] = formatDate(data?.dateRange?.startDate);
    params['end_date'] = formatDate(data?.dateRange?.endDate);
  }
  let response = await getGIFE(params);
  return response?.data?.data;
});
let sfeSlice = createSlice({
  name: 'sfe',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getVocherUsersData.pending]: (state, action) => {
      state.isVocherLoading = true;
    },
    [getVocherUsersData.fulfilled]: (state, action) => {
      state.isVocherLoading = false;
      state.voucherUsers = action.payload;
    },
    [getVocherUsersData.rejected]: (state, action) => {
      state.isVocherLoading = false;
      // state.errorMessage = `Oops! Something goes wrong!`;
    },
    [getVocherCodesData.pending]: (state, action) => {
      state.isVocherCodeLoading = true;
    },
    [getVocherCodesData.fulfilled]: (state, action) => {
      state.isVocherCodeLoading = false;
      state.voucherData = action.payload;
    },
    [getVocherCodesData.rejected]: (state, action) => {
      state.isVocherCodeLoading = false;
      // state.errorMessage = `Oops! Something goes wrong!`;
    },
    [getGOFLData.pending]: (state, action) => {
      state.isGOFLLoading = true;
    },
    [getGOFLData.fulfilled]: (state, action) => {
      state.isGOFLLoading = false;
      state.GOFL = action.payload;
    },
    [getGOFLData.rejected]: (state, action) => {
      state.isGOFLLoading = false;
      state.GOFL = [];

      // state.errorMessage = `Oops! Something goes wrong!`;
    },
    [getGFLData.pending]: (state, action) => {
      state.isGFLLoading = true;
    },
    [getGFLData.fulfilled]: (state, action) => {
      state.isGFLLoading = false;
      state.GFL = action.payload;
    },
    [getGFLData.rejected]: (state, action) => {
      state.isGFLLoading = false;
      state.GFL = [];
      // state.errorMessage = `Oops! Something goes wrong!`;
    },

    [getFFLData.pending]: (state, action) => {
      state.isFFLLoading = true;
    },
    [getFFLData.fulfilled]: (state, action) => {
      state.isFFLLoading = false;
      state.FFL = action.payload;
    },
    [getFFLData.rejected]: (state, action) => {
      state.isFFLLoading = false;
      state.FFL = [];

      // state.errorMessage = `Oops! Something goes wrong!`;
    },

    [getGIFEData.pending]: (state, action) => {
      state.isGIFELoading = true;
    },
    [getGIFEData.fulfilled]: (state, action) => {
      state.isGIFELoading = false;
      state.GIFE = action.payload;
    },
    [getGIFEData.rejected]: (state, action) => {
      state.isGIFELoading = false;
      state.GIFE = [];

      state.errorMessage = `Oops! Something goes wrong!`;
    },

    [getAnalytics.pending]: (state, action) => {
      state.isAnalyticsLoading = true;
    },
    [getAnalytics.fulfilled]: (state, action) => {
      state.isAnalyticsLoading = false;
      state.analyticsData = action.payload;
    },
    [getAnalytics.rejected]: (state, action) => {
      state.isAnalyticsLoading = false;
      state.errorMessage = `Oops! Something goes wrong!`;
    },
  },
});
// export const { updateProfile } = userSlice.actions;

export default sfeSlice.reducer;
