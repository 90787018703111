import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../../constants/Icons';
import { useViewport } from '../../../../../../hooks';
import {
  Box,
  Drawer,
  Grid,
  LoadingButton,
  Typography
} from '../../../../../Containers/index';

const DataRetrive = ({
  isOpen,
  handleClose,
  handleVerify,
  isLoad,
  handleRefresh
}) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '450px'
        }
      }}
    >
      <Box sx={{ height: '100%', p: 8, marginTop: 24 }}>
        <Box
          sx={{ position: 'fixed', right: 10, top: 80, cursor: 'pointer' }}
          onClick={() => {
            handleRefresh();
            handleClose();
          }}
        >
          <ICONS.CLOSE sx={{ color: 'black' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
          <Typography
            color='black'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '20px',
              fontWeight: 600,
              mt: 2
            }}
          >
            {t('Data_Retrieval_in_Progress')}
          </Typography>
        </Box>

        <Typography
          color='black'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
            fontWeight: 400,
            mt: 5
          }}
        >
          {t('Data_Retrive_Message')}
          {/* <p className='heading'></p> */}
        </Typography>
        <Typography
          color='black'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
            fontWeight: 400,
            mt: 1
          }}
        >
          {t('Data_Retrive_Message_refresh')}
          {/* <p className='heading'></p> */}
        </Typography>
        <Grid container spacing={4} mt={4}>
          {/* <Grid item xs={6} lg={6}>
            <Button
              variant='outlined'
              color='primary'
              fullWidth
              sx={{ mt: 4 }}
              onClick={() => handleClose()}
            >
              {t('Back')}
            </Button>
          </Grid> */}
          <Grid item xs={12} lg={12}>
            <LoadingButton
              fullWidth
              loading={isLoad}
              disabled={isLoad}
              sx={{
                mt: 4,
                textTransform: 'none',
                '& .MuiCircularProgress-root ': {
                  color: '#17236E'
                }
              }}
              variant='contained'
              onClick={() => {
                handleRefresh();
                handleClose();
              }}
            >
              {t('Continue')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};
export default DataRetrive;
