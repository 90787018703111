import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IMAGES } from '../../../../../constants/ImageConstants';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography
} from '../../../../Containers/index';
const useStyles = makeStyles({
  root: {
    '& img': {
      width: '100%',
      maxWidth: '400px',
      height: 'auto'
    }
  }
});
export default function Error404() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3} sx={{ my: 'auto' }} className={classes.root}>
          <img
            src={IMAGES.ERROR_IMAGE}
            alt='Error404'
            className='img-fluid'
            // width='400'
          />
          ;
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontFamily: 'Open Sans',
              fontSize: '2.4rem',
              fontWeight: '700',
              mb: '3.2rem'
            }}
          >
            Oops! Page Not Found
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2
            }}
          >
            <Button variant='contained' onClick={() => navigate('/')}>
              Return Home
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
