import { useDispatch } from 'react-redux';
import Turnstile, { useTurnstile } from 'react-turnstile';
import {
  handleTurnstileCapcha,
  handleTurnstileCapchaFailed,
} from '../../redux/Reducer/Auth';
import { toggleSnackBar } from '../../redux/Reducer/Utils';

const TurnstileWidget = () => {
  const turnstile = useTurnstile();
  const dispatch = useDispatch();
  // console.log(`${process.env.REACT_APP_TURNSTILE_KEY}`);
  return (
    <Turnstile
      theme='light'
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      sitekey={`${process.env.REACT_APP_TURNSTILE_KEY}`}
      onError={() => {
        dispatch(handleTurnstileCapcha(''));
        dispatch(handleTurnstileCapchaFailed(false));
      }}
      onVerify={async (token) => {
        try {
          dispatch(handleTurnstileCapcha(token));
        } catch (e) {
          dispatch(handleTurnstileCapcha(''));
          dispatch(
            toggleSnackBar({
              isOpen: true,
              type: 'error',
              message: e,
            })
          );
        }
      }}
    />
  );
};
export default TurnstileWidget;
