import React from 'react';
import { useTranslation } from 'react-i18next';
import { useViewport } from '../../../../../../hooks';
import {
  Box,
  Button,
  Drawer,
  Grid,
  LoadingButton,
  Typography
} from '../../../../../Containers/index';

const Confirm = ({ isOpen, handleClose, handleVerify, isLoad }) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '450px'
        }
      }}
    >
      <Box sx={{ height: '100%', p: 8, marginTop: 24 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            color='black'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '20px',
              fontWeight: 600,
              mt: 2
            }}
          >
            {t('Confirm')}
          </Typography>
        </Box>

        <Typography
          color='black'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
            fontWeight: 400,
            mt: 5
          }}
        >
          {t('Are_you_sure_want_to_submit')}
          {/* <p className='heading'></p> */}
        </Typography>
        <Grid container spacing={4} mt={4}>
          <Grid item xs={6} lg={6}>
            <Button
              variant='outlined'
              color='primary'
              fullWidth
              sx={{ mt: 4 }}
              onClick={() => handleClose()}
            >
              {t('Back')}
            </Button>
          </Grid>
          <Grid item xs={6} lg={6}>
            <LoadingButton
              fullWidth
              loading={isLoad}
              disabled={isLoad}
              sx={{
                mt: 4,
                textTransform: 'none',
                '& .MuiCircularProgress-root ': {
                  color: '#17236E'
                }
              }}
              variant='contained'
              onClick={() => handleVerify()}
            >
              {t('Continue')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};
export default Confirm;
