import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '../../../../../Containers/index';

function AfterVerify() {
  const { t } = useTranslation();
  return (
    <div>
      <Box>
        <Typography
          my={4}
          sx={{ fontWeight: 900, fontSize: '24px', fontStyle: 'normal' }}
        >
          {t('verification_response_title')}
        </Typography>
        <Typography
          my={4}
          sx={{ fontWeight: 400, fontSize: '16px', fontStyle: 'normal' }}
        >
          {t('verification_response_description')}
        </Typography>

        <Button
          fullWidth
          variant='contained'
          sx={{ textTransform: 'none' }}
          onClick={() => {
            const aTag = document.createElement('a');
            aTag.href = 'https://proxtera.com';
            aTag.target = '_blank';
            aTag.click();
            aTag.focus();
          }}
        >
          {t('Back_to_proxtera')}
        </Button>
      </Box>
    </div>
  );
}

export default AfterVerify;
