import { apiGet, apiPost, apiPut } from '../../utils/Axios/axios';
import { AUTH_SERVER_URL, SFE_SERVER_URL } from '../Env';

const BASE_AUTH_URL = SFE_SERVER_URL + '/lms';
//Base auth url
const SFE_DATA = `${BASE_AUTH_URL}/sfe_data`;

const ANALYTICS_URL = `${SFE_SERVER_URL}/dashboard/analytics`;
const VOUCHER_CODE_URL = `${SFE_SERVER_URL}/dashboard/voucher_codes`;
const REPORT_URL = `${SFE_SERVER_URL}/dashboard/reports`;
const PROFILE_URL = `${AUTH_SERVER_URL}/profile`;
const ADD_TEAM_MEMBER_MRL = `${AUTH_SERVER_URL}/profile/add_user`;
const INVITE_TEAM_MEMBER_MRL = `${AUTH_SERVER_URL}/profile/invite_partner`;
const RESEND_INVITE_URL = `${AUTH_SERVER_URL}/profile/resend_invitation`;
const LIST_TEAM_MEMBER_MRL = `${AUTH_SERVER_URL}/profile/list_user`;
const DELETE_USER = `${AUTH_SERVER_URL}/profile/remove_user`;
const BUSINESSONBOARD_URL = `${AUTH_SERVER_URL}/profile/update_company_info`;
const UPDATE_TEAM_MEMBER_URL = `${AUTH_SERVER_URL}/profile/update_profile`;

export const getCertificateDetailsList = (apiData) => {
  return apiPost(SFE_DATA, apiData);
};

export const getAnalyticsData = (queryData) => {
  return apiGet(ANALYTICS_URL + '?voucher_code=' + queryData);
};
export const getVocherCodes = () => {
  return apiGet(VOUCHER_CODE_URL);
};

export const getVocherUsers = (bodyParams) => {
  return apiPost(REPORT_URL, bodyParams);
};

export const getGFL = (bodyParams) => {
  return apiPost(REPORT_URL, bodyParams);
};
export const getGOFL = (bodyParams) => {
  return apiPost(REPORT_URL, bodyParams);
};

export const getFFL = (bodyParams) => {
  return apiPost(REPORT_URL, bodyParams);
};

export const getGIFE = (bodyParams) => {
  return apiPost(REPORT_URL, bodyParams);
};

export const updateBusinessOnBoard = (bodyParams) => {
  return apiPut(BUSINESSONBOARD_URL, bodyParams);
};

export const addTeamMember = (bodyParams) => {
  return apiPut(ADD_TEAM_MEMBER_MRL, bodyParams);
};
export const updateTeamMember = (id, bodyParams) => {
  return apiPut(UPDATE_TEAM_MEMBER_URL + '/' + id, bodyParams);
};
export const inviteTeamMember = (bodyParams) => {
  return apiPost(INVITE_TEAM_MEMBER_MRL, bodyParams);
};
export const resendInvite = (bodyParams) => {
  return apiPut(RESEND_INVITE_URL, bodyParams);
};
export const deleteInvite = (bodyParams) => {
  return apiPut(DELETE_USER, bodyParams);
};
export const getTeamMembers = () => {
  return apiGet(LIST_TEAM_MEMBER_MRL);
};
