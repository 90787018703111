// import { ThemeProvider } from "@mui/material/styles";
import { Amplify } from 'aws-amplify';
import { Suspense, useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackBar } from './app/Containers';
import DefaultThemeProvider from './context/ThemeProvider';
import ViewportProvider from './context/ViewPortProvider';
import store from './redux/Store';
import Route from './routes';
import {
  AUTH_SERVER_URL,
  CLARITY_ID,
  COGNITO_CLIENT_ID,
  COGNITO_REGION,
  COGNITO_USER_POOL_ID,
} from './services/Env';
import Loader from './utils/SuspenseLoader';
import { GlobalDebug } from './utils/remove-console';

// Amplify.configure({
//   Auth: {
//     identityPoolId: `${COGNITO_IDENTITY_POOL_ID}`,
//     region: `${COGNITO_REGION}`,
//     identityPoolRegion: `${COGNITO_REGION}`,
//     userPoolId: `${COGNITO_USER_POOL_ID}`,
//     userPoolWebClientId: `${COGNITO_CLIENT_ID}`
//   }
// });
const awsmobile = {
  aws_project_region: COGNITO_REGION,
  aws_cognito_region: COGNITO_REGION,
  aws_user_pools_id: COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO_CLIENT_ID,
  oauth: {
    domain: AUTH_SERVER_URL,
  },
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL', 'PHONE_NUMBER'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS', 'TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};
Amplify.configure(awsmobile);
function App() {
  useEffect(() => {
    clarity.init(`${CLARITY_ID}`);
    GlobalDebug(false);
  }, []);
  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <DefaultThemeProvider>
          <ViewportProvider>
            <BrowserRouter>
              <SnackBar />
              <Route />
            </BrowserRouter>
          </ViewportProvider>
        </DefaultThemeProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
