import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROUTE } from '../../../../constants/Routes';
import { useViewport } from '../../../../hooks';
import BottomNavigationBar from './BottomNavBar/BottomNavigation';
import NavBar from './NavBar';
import { Main } from './Settings';
import SideDrawer from './SideDrawer';

function AppLayout() {
  const { isMobile } = useViewport();
  const navigate = useNavigate();
  const { isSideNavOpen } = useSelector((state) => state.utils);
  const { userData } = useSelector((state) => state.user);
  const [showSidebar, setShowSideBar] = useState(true);
  useEffect(() => {
    if (
      (userData?.platformDetail?.company_info?.corporate_name === '' ||
        userData?.platformDetail?.company_info?.address?.address_line_one ===
          '') &&
      !userData?.roles?.some((v) => v.includes('USER')) &&
      !userData?.roles?.some((v) => v.includes('SUPER')) &&
      !userData?.roles?.includes('PARTNER_ADMIN')
    ) {
      navigate(ROUTE.ONBOARD);
      setShowSideBar(false);
    } else {
      setShowSideBar(true);
    }
  }, [userData]);
  return (
    <React.Fragment>
      <NavBar />
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          mb: 10,
        }}
      >
        {showSidebar && !isMobile && <SideDrawer />}
        <Main open={isSideNavOpen}>
          <Outlet />
        </Main>
      </Box>
      {isMobile && (
        <Box sx={{ mt: 4 }}>
          <BottomNavigationBar />
        </Box>
      )}
    </React.Fragment>
  );
}

export default AppLayout;
