import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toggleSnackBar } from '../../../../../redux/Reducer/Utils/index';
import { reviewPartner } from '../../../../../services/Auth';
import { Box, CircularProgress } from '../../../../Containers/index';
import ReviewInfo from './ReviewInfo';

const ReviewRequest = () => {
  const location = useLocation().search;
  const token = new URLSearchParams(location).get('token');
  const dispatch = useDispatch();

  const [isVerified, setIsVerified] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [reviewToken, setReviewToken] = useState('');
  const [userInfo, setUserInfo] = useState([]);
  // const [tokenError, setTokenError] = useState('');

  const handleReviewRequestUserInfo = async (token) => {
    setReviewToken(token);
    // setTokenError('');
    const params = {
      token: token,
      type: 'review_details'
    };
    try {
      const response = await reviewPartner(params);
      if (response?.data) {
        setUserInfo(response?.data?.data);
      }
      setIsVerified(true);
    } catch (error) {
      // setTokenError(error);
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error
        })
      );
    }
  };
  useEffect(() => {
    if (token !== '' && token !== null && token !== undefined) {
      setTokenFound(true);
      handleReviewRequestUserInfo(token);
    }
    // eslint-disable-next-line
  }, [token]);

  if (isTokenFound && !isVerified) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '98vh'
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (isTokenFound && isVerified) {
    return <ReviewInfo userInfo={userInfo} token={reviewToken} />;
  }
  // else
  //   return (
  //     <Box
  //       sx={{
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         height: '98vh',
  //       }}
  //     >
  //       <Typography variant='h5' component='h3' sx={{ fontWeight: 700 }}>
  //         {' '}
  //         {tokenError}
  //       </Typography>
  //     </Box>
  //   );
};

export default ReviewRequest;
