import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../../../constants/Icons';
import { IMAGES } from '../../../../../../constants/ImageConstants';
import { ROUTE } from '../../../../../../constants/Routes';
import { toggleSnackBar } from '../../../../../../redux/Reducer/Utils';
import { getIndustryTypeEnum, getRoles } from '../../../../../../services/Auth';
import MultiTreeSelect from '../../../../../Containers/MultiTree';
import {
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LoadingButton,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
  makeStyles,
} from '../../../../../Containers/index';
import { HeaderAppBar } from '../../../../Authenticated/Layout/Settings';
import ConfirmModel from './model';

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root.MuiCard-root': {
      padding: '24px',
    },
  },
});

function ReviewInfo({ userInfo, token }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [industryMultiTree, setIndustryMultiTree] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');
  const getIndustryType = () => {
    getIndustryTypeEnum().then((res) => {
      setIndustryMultiTree(res.data.data);
    });
  };
  const getRolesForAccess = async () => {
    try {
      const result = await getRoles();
      if (result) {
        setRoles(result?.data?.data?.roles);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      designation: '',
      email: '',
      code: '',
      phone: '',
      corporateName: '',
      registration: '',
      taxId: '',
      password: '',
      url: '',
      confirmPassword: '',
      acceptTermsOfUse: false,
      acceptTermsAndConditions: false,
      acceptPrivacyPolicy: false,
      acceptCreateAccount: false,
      address: '',
      addressLineOne: '',
      addressLineTwo: '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
      latitude: '',
      longitude: '',
      primaryIndustry: null,
    },
  });
  useEffect(() => {
    getRolesForAccess();
    getIndustryType();
  }, []);
  useEffect(() => {
    formik.setFieldValue('firstName', userInfo?.first_name);
    formik.setFieldValue('lastName', userInfo?.last_name);
    formik.setFieldValue('email', userInfo?.email);
    formik.setFieldValue('phone', userInfo?.phone_number);
    formik.setFieldValue('designation', userInfo?.designation);
    formik.setFieldValue('corporateName', userInfo?.company_name);
    formik.setFieldValue('registration', userInfo?.country_of_incorporation);
    formik.setFieldValue('taxId', userInfo?.tax_id);
    formik.setFieldValue('url', userInfo?.website);
    formik.setFieldValue('primaryIndustry', userInfo?.primary_industry);
    formik.setFieldValue('address', userInfo?.address);
    formik.setFieldValue('addressLineOne', userInfo?.address_line_one);
    formik.setFieldValue('addressLineTwo', userInfo?.address_line_two);
    formik.setFieldValue('country', userInfo?.country);
    formik.setFieldValue('state', userInfo?.state);
    formik.setFieldValue('city', userInfo?.city);
    formik.setFieldValue('postalCode', userInfo?.postal_code);
    // eslint-disable-next-line
  }, [userInfo]);

  const toggleConfirmModal = (value) => {
    setOpen(value);
    setIsLoading(false);
  };
  const handleStatus = () => {
    const statusMessage = type === 'approve' ? 'Approved' : 'Rejected';
    setStatus(statusMessage);
  };
  const handleLoadingButton = (value) => {
    setIsLoading(value);
  };
  const handleChange = (e) => {
    setSelectedRole(e.target.value);
  };
  return (
    <div>
      <Box className={classes.root}>
        <ConfirmModel
          open={open}
          handleClose={toggleConfirmModal}
          handleStatus={handleStatus}
          handleLoadingButton={handleLoadingButton}
          type={type}
          token={token}
          role={selectedRole}
        />

        <HeaderAppBar
          className='HeaderAppBar'
          position='sticky'
          sx={{
            bgcolor: '#ffff',
            justifyContent: 'center',
            boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
            mb: 4,
          }}
        >
          <Toolbar>
            <Box
              sx={{
                flexGrow: 1,
                pl: 1,
              }}
            >
              <img
                src={IMAGES.LOGO}
                height='48'
                width='48'
                style={{ aspectRatio: '3.6428/1' }}
                alt='logo'
              />
            </Box>{' '}
            <Box className='ml-auto'>
              <IconButton
                color='primary'
                sx={{ mr: 2 }}
                onClick={() => {
                  navigate(ROUTE.ROOT);
                }}
              >
                <ICONS.HOME />
              </IconButton>
              {/* <Box sx={{ flexGrow: 0 }}>
               
              </Box> */}
            </Box>
          </Toolbar>
        </HeaderAppBar>
        <Container maxWidth='lg' sx={{ mt: 3, mb: 4 }}>
          <Card elevation={3}>
            <Grid
              container
              spacing={2}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item xs={12} md={6} lg={6} marginBottom={'10px'}>
                <Typography
                  variant='h5'
                  component='h3'
                  sx={{ fontWeight: 700 }}
                >
                  {' '}
                  Review Signup Request
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={2}>
                  <Grid item lg={6} xl={6} xs={12} md={12} sm={12}>
                    <TextField
                      sx={{ my: 2 }}
                      label={t('First_name')}
                      fullWidth
                      name='firstName'
                      value={formik.values?.firstName
                        .trimStart()
                        .replace(/\s\s+/g, '')
                        .replace(/\p{Emoji_Presentation}/gu, '')}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item lg={6} xl={6} xs={12} md={12} sm={12}>
                    <TextField
                      sx={{ my: 2 }}
                      label={t('Last_name')}
                      fullWidth
                      name='lastName'
                      value={formik.values.lastName
                        .trimStart()
                        .replace(/\s\s+/g, '')
                        .replace(/\p{Emoji_Presentation}/gu, '')}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  sx={{ my: 2 }}
                  label={t('Designation')}
                  name='designation'
                  value={formik.values.designation
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />{' '}
                <TextField
                  fullWidth
                  sx={{ my: 2 }}
                  label={t('Contact_email')}
                  name='email'
                  value={formik.values.email
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  sx={{ my: 2 }}
                  label={t('Mobile_number')}
                  fullWidth
                  name='phone'
                  value={formik.values.phone
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  sx={{ my: 2 }}
                  label='Company/Corporate Name'
                  name='corporateName'
                  value={formik.values.corporateName
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item lg={6} xl={6} xs={12} md={12} sm={12}>
                    <TextField
                      sx={{ my: 2 }}
                      label='Country of Registration'
                      fullWidth
                      name='registration'
                      value={formik.values.registration
                        .trimStart()
                        .replace(/\s\s+/g, '')
                        .replace(/\p{Emoji_Presentation}/gu, '')}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item lg={6} xl={6} xs={12} md={12} sm={12}>
                    <TextField
                      sx={{ my: 2 }}
                      label='Company Tax ID'
                      fullWidth
                      name='taxId'
                      value={formik.values.taxId
                        .trimStart()
                        .replace(/\s\s+/g, '')
                        .replace(/\p{Emoji_Presentation}/gu, '')}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  sx={{ my: 2 }}
                  label='Company URL'
                  name='url'
                  value={formik.values.url
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl fullWidth disabled={status !== ''} sx={{ mt: 2 }}>
                  <InputLabel id='roles'>Roles *</InputLabel>

                  <Select
                    labelId='roles'
                    value={selectedRole}
                    label='Roles *'
                    onChange={(e) => handleChange(e)}
                  >
                    {roles?.map((e) => (
                      <MenuItem value={e.role} key={e.role}>
                        {e.role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MultiTreeSelect
                  readOnly={true}
                  label={`${t('PrimaryIndustry')}`}
                  name='primaryIndustry'
                  sx={{ mb: 2 }}
                  defaultIndustries={
                    formik?.values?.primaryIndustry
                      ? formik?.values?.primaryIndustry
                      : null
                  }
                  industryMultiTree={industryMultiTree}
                />
                <TextField
                  fullWidth
                  sx={{ my: 2, mt: 2 }}
                  multiline
                  label='Location'
                  name='address'
                  value={formik.values.address
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label='Address Line 1'
                  variant={'outlined'}
                  fullWidth
                  sx={{ my: 2 }}
                  name='addressLineOne'
                  value={formik.values.addressLineOne}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label='Address Line 2'
                  variant={'outlined'}
                  fullWidth
                  sx={{ my: 2 }}
                  name='addressLineTwo'
                  value={formik.values.addressLineTwo}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />{' '}
                <TextField
                  id='country'
                  label='Country / Region'
                  sx={{ my: 2 }}
                  variant='outlined'
                  fullWidth
                  name='country'
                  value={formik.values.country}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id='state'
                  label='State/Province'
                  variant='outlined'
                  sx={{ my: 2 }}
                  fullWidth
                  name='state'
                  value={formik.values.state}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={6}
                    xl={6}
                    xs={12}
                    md={12}
                    sm={12}
                    sx={{ my: 2 }}
                  >
                    <TextField
                      id='city'
                      label='City'
                      variant='outlined'
                      fullWidth
                      name='city'
                      value={formik.values.city}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    xl={6}
                    xs={12}
                    md={12}
                    sm={12}
                    sx={{ my: 2 }}
                  >
                    <TextField
                      id='postalCode'
                      name='postalCode'
                      label='Post Code'
                      variant='outlined'
                      fullWidth
                      value={formik.values.postalCode}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                mt={4}
                marginBottom={'8px'}
                sx={{ display: 'flex', justifyContent: 'end' }}
              >
                {status === '' ? (
                  <>
                    <LoadingButton
                      loading={isLoading}
                      disabled={isLoading}
                      variant='contained'
                      sx={{ mr: 2 }}
                      onClick={() => {
                        if (selectedRole !== '') {
                          setIsLoading(true);

                          setType('approve');
                          toggleConfirmModal(true);
                        } else {
                          dispatch(
                            toggleSnackBar({
                              isOpen: true,
                              type: 'error',
                              message: 'Please Select a Role',
                            })
                          );
                        }
                      }}
                    >
                      Approve
                    </LoadingButton>
                    <LoadingButton
                      loading={isLoading}
                      disabled={isLoading}
                      variant='outlined'
                      color='error'
                      onClick={() => {
                        setIsLoading(true);

                        setType('reject');
                        toggleConfirmModal(true);
                      }}
                    >
                      Reject
                    </LoadingButton>
                  </>
                ) : (
                  <Typography
                    variant='h5'
                    component='h5'
                    sx={{
                      fontWeight: 700,
                      color: type === 'approve' ? 'success.main' : 'error.main',
                    }}
                  >
                    {' '}
                    {status}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </div>
  );
}

export default ReviewInfo;
