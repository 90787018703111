import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useViewport } from '../../../../../hooks';
import { toggleSnackBar } from '../../../../../redux/Reducer/Utils';
import { getCountries, getRoles } from '../../../../../services/Auth';
import { inviteTeamMember } from '../../../../../services/Sfe';
import { inviteMemberValidation } from '../../../../../utils/ValidationSchema/onBoard';
import {
  Box,
  Card,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoadingButton,
  MenuItem,
  Select,
  SnackBar,
  TextField,
  Typography,
  makeStyles,
} from '../../../../Containers/index';
// import MultiTreeSelect from '../../../../Containers/MultiTree';

const useStyles = makeStyles({
  root: {},
});

function InviteMemberCard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  // const [contriesList, setContriesList] = useState([]);
  const [showAddCard, setShowAddCard] = useState(false);
  const [contriesList, setContriesList] = useState([]);
  const [channelRole, setChannelRole] = useState([]);

  const [roles, setRoles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isMemberDetails, setIsMemberDetails] = useState(false);

  const toggleAddCard = () => setShowAddCard(!showAddCard);
  const getCountriesList = async () => {
    var res = await getCountries();
    if (res) {
      setContriesList(res?.data?.data?.countries);
    }
  };
  const getRolesForAccess = async () => {
    try {
      const result = await getRoles();
      console.log(result?.data?.data);
      if (result) {
        setRoles(result?.data?.data?.roles);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      // designation: '',
      email: '',
      // code: '',
      // phone: '',
      role: '',
      hasChannelData: false,
      channel_partner: '',
      // ghanapost_gps: '',
    },
    validationSchema: inviteMemberValidation,

    onSubmit: async (values, { resetForm }) => {
      // console.log(values);

      const params = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        role: values.role,
        channel_partner: values?.channel_partner,
      };
      try {
        setIsLoading(true);
        const res = await inviteTeamMember(params);
        resetForm();
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'success',
            message: res?.data?.message,
          })
        );
      } catch (e) {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: e,
          })
        );
      } finally {
        setIsLoading(false);
      }
    },
  });
  useEffect(() => {
    getRolesForAccess();
    getCountriesList();
  }, []);

  return (
    <Box
      component={Card}
      sx={{
        my: 4,
        p: 4,
      }}
    >
      <SnackBar />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} marginTop='10px' marginLeft={'10px'}>
          <Typography
            variant='h5'
            component='h3'
            sx={{ fontWeight: 700, px: 4, py: 4 }}
          >
            {' '}
            Invite Member
          </Typography>
        </Grid>
      </Grid>
      <Container maxWidth='sm' sx={{ mb: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <FormControl fullWidth>
              <TextField
                inputProps={{
                  'data-testid': 'signup-firstname',
                }}
                sx={{ my: 2 }}
                label={t('First_name')}
                required
                fullWidth
                name='firstName'
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                value={formik.values.firstName
                  .trimStart()
                  .replace(/\s\s+/g, '')
                  .replace(/\p{Emoji_Presentation}/gu, '')}
                onChange={(e) => {
                  const regx = /^[a-zA-Z ]*$/;
                  if (e.target.value === '' || regx.test(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                helperText={
                  formik.touched.firstName &&
                  formik.errors.firstName &&
                  t(formik.errors.firstName)
                }
              />
            </FormControl>
            <TextField
              inputProps={{
                'data-testid': 'signup-lastname',
              }}
              sx={{ my: 2 }}
              label={t('Last_name')}
              required
              fullWidth
              name='lastName'
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              value={formik.values.lastName
                .trimStart()
                .replace(/\s\s+/g, '')
                .replace(/\p{Emoji_Presentation}/gu, '')}
              onChange={(e) => {
                const regx = /^[a-zA-Z ]*$/;
                if (e.target.value === '' || regx.test(e.target.value)) {
                  formik.handleChange(e);
                }
              }}
              helperText={
                formik.touched.lastName &&
                formik.errors.lastName &&
                t(formik.errors.lastName)
              }
            />

            {/* <TextField
              inputProps={{ 'data-testid': 'signup-designation' }}
              fullWidth
              required
              sx={{ my: 2 }}
              label={t('Designation')}
              name='designation'
              error={
                formik.touched.designation && Boolean(formik.errors.designation)
              }
              value={formik.values.designation
                .trimStart()
                .replace(/\s\s+/g, '')
                .replace(/\p{Emoji_Presentation}/gu, '')}
              onChange={(e) => formik.handleChange(e)}
              helperText={
                formik.touched.designation &&
                formik.errors.designation &&
                t(formik.errors.designation)
              }
            /> */}

            <Grid container spacing={2}>
              <Grid item lg={12} xl={12} xs={12} md={12} sm={12}>
                <TextField
                  inputProps={{ 'data-testid': 'signup-email' }}
                  fullWidth
                  required
                  sx={{ my: 2 }}
                  label={t('Email_Address')}
                  name='email'
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  value={formik.values.email
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  helperText={
                    formik.touched.email &&
                    formik.errors.email &&
                    t(formik.errors.email)
                  }
                />
              </Grid>
            </Grid>

            {/* <Grid container spacing={2}>
              <Grid item lg={4} xl={4} md={4} xs={12} sm={4}>
                <Autocomplete
                  required
                  data-testid='code'
                  sx={{ my: 2 }}
                  name={'code'}
                  label={t('Code')}
                  fullWidth
                  value={formik.values.code}
                  onChange={(event, item) => {
                    formik.setFieldValue('code', item?.label);
                  }}
                  options={contriesList
                    ?.sort((a, b) => a?.dialCode - b?.dialCode)
                    .map((item) => ({
                      label: item.dialCode,
                      value: item.dialCode,
                    }))}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value
                  }
                  freeSolo={false}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={t('Code')}
                      error={formik.touched.code && Boolean(formik.errors.code)}
                      helperText={
                        formik.touched.code &&
                        formik.errors.code &&
                        t(formik.errors.code)
                      }
                      variant={'outlined'}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      value={formik?.values?.code}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={8} xl={8} xs={12} md={8} sm={8}>
                <TextField
                  // inputProps={{ 'data-testid': 'signup-phone' }}
                  required
                  sx={{ my: 2 }}
                  label={t('Mobile_number')}
                  fullWidth
                  name='phone'
                  // type='number'
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  value={formik.values.phone.trim().replace(/\s\s+/g, '')}
                  // .replace(/\s\s+/g, '')
                  // .replace(/\p{Emoji_Presentation}/gu, '')}
                  // onChange={(e) => formik.handleChange(e)}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  inputProps={{
                    maxlength: 15,
                    minLength: 6,
                    'data-testid': 'signup-phone',
                  }}
                  helperText={
                    formik.touched.phone &&
                    formik.errors.phone &&
                    t(formik.errors.phone)
                  }
                />
              </Grid>
            </Grid> */}

            <FormControl
              fullWidth
              error={formik.touched.role && Boolean(formik.errors.role)}
              helperText={
                formik.touched.role &&
                formik.errors.role &&
                t(formik.errors.role)
              }
              sx={{ my: 2 }}
            >
              <InputLabel id='roles' required>
                System Role
              </InputLabel>

              <Select
                labelId='roles'
                value={formik.values.role}
                label='System Role'
                name='role'
                required
                onChange={(e) => {
                  formik.handleChange(e);

                  const res = roles?.find((i) => i?.role === e.target.value);
                  console.log(res);
                  if (
                    Object.keys(res)?.length &&
                    res?.channelPartners?.length
                  ) {
                    setChannelRole(res?.channelPartners);
                    formik.setFieldValue('hasChannelData', true);
                  } else {
                    formik.setFieldValue('hasChannelData', false);
                    formik.setFieldValue('channel_partner', '');

                    setChannelRole([]);
                  }
                }}
                freeSolo={isMemberDetails}
                InputProps={{
                  readOnly: isMemberDetails,
                  disableUnderline: isMemberDetails,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={
                  formik.touched.role &&
                  formik.errors.role &&
                  t(formik.errors.role)
                }
              >
                {roles?.map((e) => (
                  <MenuItem
                    value={e.role}
                    key={e.role}
                    sx={{ whiteSpace: 'normal' }}
                  >
                    {e.role}
                  </MenuItem>
                ))}
              </Select>

              {formik.touched.role && formik.errors.role && (
                <FormHelperText>{t(formik.errors.role)}</FormHelperText>
              )}
            </FormControl>

            {channelRole && channelRole[0]?._id && (
              <FormControl
                fullWidth
                error={
                  formik.touched.channel_partner &&
                  Boolean(formik.errors.channel_partner)
                }
                helperText={
                  formik.touched.channel_partner &&
                  formik.errors.channel_partner &&
                  t(formik.errors.channel_partner)
                }
                sx={{ my: 2 }}
              >
                <InputLabel id='roles' required>
                  Channel Partner
                </InputLabel>

                <Select
                  labelId='roles'
                  value={formik.values.channel_partner}
                  label='Channel Partner'
                  name='channel_partner'
                  required
                  onChange={formik.handleChange}
                  freeSolo={isMemberDetails}
                  InputProps={{
                    readOnly: isMemberDetails,
                    disableUnderline: isMemberDetails,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    formik.touched.channel_partner &&
                    Boolean(formik.errors.channel_partner)
                  }
                  helperText={
                    formik.touched.channel_partner &&
                    formik.errors.channel_partner &&
                    t(formik.errors.channel_partner)
                  }
                >
                  {channelRole?.map((e) => (
                    <MenuItem
                      value={e._id}
                      key={e._id}
                      sx={{ whiteSpace: 'normal' }}
                    >
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>

                {formik.touched.channel_partner &&
                  formik.errors.channel_partner && (
                    <FormHelperText>
                      {t(formik.errors.channel_partner)}
                    </FormHelperText>
                  )}
              </FormControl>
            )}
            <Grid container spacing={2}>
              <Grid item lg={12} xl={12} md={12} xs={12} sm={12}>
                {/* <Button
                  variant='outlined'
                  fullWidth
                  // onClick={formik.handleSubmit}
                  // onClick={() => handleExpand(index, false)}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={6} xl={6} md={12} xs={12} sm={12}> */}
                <LoadingButton
                  loading={isLoading}
                  variant='contained'
                  fullWidth
                  onClick={formik.handleSubmit}
                  sx={{ mr: 1, my: 2 }}
                >
                  Invite
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default InviteMemberCard;
