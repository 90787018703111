import React from 'react';

function DataValidation(color) {
  return (
    <svg
      width='32'
      height='28'
      viewBox='0 -02 24 22'
      style={{ marginLeft: '-2px', marginBottom: '6px' }}
      // strokeWidth='2px'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='svglocal data_validation dv MuiSvgIcon-root MuiSvgIcon-fontSizeMedium'
    >
      <path
        d='M4.85 16.625C4.85 16.6966 4.87452 16.7679 4.92831 16.8217C4.9821 16.8755 5.05341 16.9 5.125 16.9C5.19659 16.9 5.2679 16.8755 5.32169 16.8217C5.37548 16.7679 5.4 16.6966 5.4 16.625V6.4H16.1V15.375C16.1 15.4466 16.1245 15.5179 16.1783 15.5717C16.2321 15.6255 16.3034 15.65 16.375 15.65C16.4466 15.65 16.5179 15.6255 16.5717 15.5717C16.6255 15.5179 16.65 15.4466 16.65 15.375V6.125C16.65 6.05341 16.6255 5.9821 16.5717 5.92831C16.5179 5.87452 16.4466 5.85 16.375 5.85H5.125C5.05341 5.85 4.9821 5.87452 4.92831 5.92831C4.87452 5.9821 4.85 6.05341 4.85 6.125V16.625Z'
        fill={color.color}
        stroke={color.color}
        // strokeWidth='0.9'
      />
      <path
        d='M6.31007 18.1493L6.31752 18.15H6.325H17.625H17.7H17.71L17.7198 18.1487C18.5416 18.0391 19.175 17.3529 19.175 16.5C19.175 15.9786 18.904 15.4612 18.469 15.1577C18.4029 15.0997 18.3309 15.0999 18.3029 15.1C18.3019 15.1 18.3009 15.1 18.3 15.1H7.3C7.2015 15.1 7.09476 15.1501 7.04084 15.2579C7.00652 15.3266 7.01078 15.4005 7.02957 15.4568C7.04724 15.5098 7.08448 15.5675 7.14594 15.6028C7.4246 15.7991 7.6 16.1491 7.6 16.5C7.6 16.6002 7.59952 16.6585 7.57948 16.7386L7.57941 16.7386L7.57842 16.7431C7.46908 17.2461 7.00765 17.6 6.5 17.6C5.88284 17.6 5.4 17.1172 5.4 16.5C5.4 16.4284 5.37548 16.3571 5.32169 16.3033C5.2679 16.2495 5.19659 16.225 5.125 16.225C5.05341 16.225 4.9821 16.2495 4.92831 16.3033C4.87452 16.3571 4.85 16.4284 4.85 16.5C4.85 17.3489 5.48024 18.0663 6.31007 18.1493ZM17.5 17.6H7.72466C7.90436 17.3989 8.0414 17.1466 8.09659 16.8571C8.12185 16.7554 8.15 16.6177 8.15 16.5C8.15 16.1962 8.07455 15.9047 7.9223 15.65H18.1981C18.4404 15.8502 18.6 16.166 18.6 16.5C18.6 17.1172 18.1172 17.6 17.5 17.6ZM9.44393 13.0561L9.44981 13.062L9.4563 13.0671C9.59762 13.1802 9.77977 13.275 10 13.275C10.209 13.275 10.4033 13.188 10.5129 13.0493L13.5034 10.0587C13.8262 9.76478 13.7769 9.26959 13.5115 8.97467C13.224 8.65518 12.7242 8.66365 12.4189 8.96895L9.975 11.4129L9.03107 10.4689C8.88546 10.3233 8.68214 10.2563 8.4875 10.2563C8.29411 10.2563 8.09216 10.3225 7.94675 10.4661C7.63022 10.7541 7.63956 11.2517 7.94393 11.5561L9.44393 13.0561ZM10.1439 12.6439L10.1435 12.6435L10.1348 12.654C10.0747 12.7261 9.9284 12.7413 9.83107 12.6439L8.33107 11.1439C8.24297 11.0558 8.23977 10.9319 8.32146 10.8412C8.38474 10.801 8.44868 10.775 8.5 10.775C8.55122 10.775 8.61499 10.8021 8.66893 10.8561L9.89393 12.0811L10.0005 12.1876L10.1065 12.0806L12.8311 9.33108C12.8311 9.33101 12.8312 9.33095 12.8313 9.33088C12.9227 9.23966 13.0526 9.23972 13.1439 9.33108C13.2354 9.4225 13.2354 9.55253 13.1439 9.64395L10.1439 12.6439Z'
        fill={color.color}
        stroke={color.color}
        // strokeWidth='0.9'
      />
    </svg>
  );
}

export default DataValidation;
