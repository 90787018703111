import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useViewport } from '../../../../../../hooks';

import { getTeam } from '../../../../../../redux/Reducer/Profile';
import { toggleSnackBar } from '../../../../../../redux/Reducer/Utils';
import { updateTeamMember } from '../../../../../../services/Sfe';
import { memberValidation } from '../../../../../../utils/ValidationSchema/onBoard';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '../../../../../Containers/index';
// import MultiTreeSelect from '../../../../Containers/MultiTree';

const useStyles = makeStyles({
  root: {},
});

function MemberCard({ contriesList, memberData, handleExpand, index }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  // const [contriesList, setContriesList] = useState([]);
  const { userData } = useSelector((state) => state.user);
  const [showAddCard, setShowAddCard] = useState(false);
  const [roles, setRoles] = useState([
    { name: 'Admin', value: 'admin' },
    { name: 'User', value: 'user' },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMemberDetails, setIsMemberDetails] = useState(false);

  const toggleAddCard = () => setShowAddCard(!showAddCard);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      designation: '',
      email: '',
      code: '',
      phone: '',
      role: '',
      // ghanapost_gps: '',
    },
    validationSchema: memberValidation,
    onSubmit: async (values) => {
      // console.log(values);

      const params = {
        first_name: values.firstName,
        last_name: values.lastName,
        designation: values.designation,
        //  email: values.email,
        country_code: values.code,
        phone_number: values.phone,
        role: values.role,
        source: 'dashboard',
      };
      // // console.log(params);

      try {
        setIsLoading(true);
        const res = await updateTeamMember(memberData?._id, params);
        //  setShowAddCard(false);
        dispatch(getTeam());
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'success',
            message: res?.data.message,
          })
        );
        // next(2);
      } catch (e) {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: e,
          })
        );
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    console.log(
      'check ',
      memberData?.roles && memberData?.roles[0].toLowerCase().includes('user')
    );
    formik.setFieldValue('firstName', memberData?.first_name || '');
    formik.setFieldValue('lastName', memberData?.last_name || '');
    formik.setFieldValue('designation', memberData?.designation || '');
    formik.setFieldValue('email', memberData?.email || '');
    if (memberData?.country_code) {
      formik.setFieldValue(
        'code',
        '+' + memberData?.country_code?.replace('+', '') || ''
      );
    }
    formik.setFieldValue('phone', memberData?.phone || '');
    formik.setFieldValue(
      'role',
      memberData?.roles && memberData?.roles[0].toLowerCase().includes('user')
        ? 'user'
        : 'admin'
    );
  }, [memberData]);
  return (
    <Box
      sx={{
        my: 4,
        px: 4,
      }}
    >
      <Grid container spacing={2}>
        <TextField
          inputProps={{
            'data-testid': 'signup-firstname',
          }}
          sx={{ my: 2 }}
          label={t('First_name')}
          required
          fullWidth
          name='firstName'
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          value={formik.values.firstName
            .trimStart()
            .replace(/\s\s+/g, '')
            .replace(/\p{Emoji_Presentation}/gu, '')}
          onChange={(e) => {
            const regx = /^[a-zA-Z ]*$/;
            if (e.target.value === '' || regx.test(e.target.value)) {
              formik.handleChange(e);
            }
          }}
          helperText={
            formik.touched.firstName &&
            formik.errors.firstName &&
            t(formik.errors.firstName)
          }
        />

        <TextField
          inputProps={{
            'data-testid': 'signup-lastname',
          }}
          sx={{ my: 2 }}
          label={t('Last_name')}
          required
          fullWidth
          name='lastName'
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          value={formik.values.lastName
            .trimStart()
            .replace(/\s\s+/g, '')
            .replace(/\p{Emoji_Presentation}/gu, '')}
          onChange={(e) => {
            const regx = /^[a-zA-Z ]*$/;
            if (e.target.value === '' || regx.test(e.target.value)) {
              formik.handleChange(e);
            }
          }}
          helperText={
            formik.touched.lastName &&
            formik.errors.lastName &&
            t(formik.errors.lastName)
          }
        />

        <TextField
          inputProps={{ 'data-testid': 'signup-designation' }}
          fullWidth
          required
          sx={{ my: 2 }}
          label={t('Designation')}
          name='designation'
          error={
            formik.touched.designation && Boolean(formik.errors.designation)
          }
          value={formik.values.designation
            .trimStart()
            .replace(/\s\s+/g, '')
            .replace(/\p{Emoji_Presentation}/gu, '')}
          onChange={(e) => formik.handleChange(e)}
          helperText={
            formik.touched.designation &&
            formik.errors.designation &&
            t(formik.errors.designation)
          }
        />

        <Grid container spacing={2}>
          <Grid item lg={12} xl={12} xs={12} md={12} sm={12}>
            <TextField
              inputProps={{ 'data-testid': 'signup-email' }}
              fullWidth
              required
              disabled={formik.values.email}
              sx={{ my: 2 }}
              label={t('Contact_email')}
              name='email'
              error={formik.touched.email && Boolean(formik.errors.email)}
              value={formik.values.email
                .trimStart()
                .replace(/\s\s+/g, '')
                .replace(/\p{Emoji_Presentation}/gu, '')}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              helperText={
                formik.touched.email &&
                formik.errors.email &&
                t(formik.errors.email)
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={4} xl={4} md={4} xs={12} sm={4}>
            <Autocomplete
              required
              data-testid='code'
              sx={{ my: 2 }}
              name={'code'}
              label={t('Code')}
              fullWidth
              value={formik.values.code}
              onChange={(event, item) => {
                formik.setFieldValue('code', item?.label);
              }}
              options={contriesList
                ?.sort((a, b) => a?.dialCode - b?.dialCode)
                .map((item) => ({
                  label: item?.dialCode,
                  value: item?.dialCode,
                }))}
              isOptionEqualToValue={(option, value) => option?.value === value}
              freeSolo={false}
              onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                  let matchedOption = contriesList?.find(
                    (option) => option?.dialCode === '+' + value
                  );
                  if (matchedOption) {
                    formik.setFieldValue(
                      'code',
                      matchedOption?.dialCode
                      // matchedOption
                    );
                  } else {
                    formik.setFieldValue('code', '');
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label={t('Code')}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={
                    formik.touched.code &&
                    formik.errors.code &&
                    t(formik.errors.code)
                  }
                  variant={'outlined'}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  value={formik?.values?.code}
                />
              )}
            />
          </Grid>
          <Grid item lg={8} xl={8} xs={12} md={8} sm={8}>
            <TextField
              // inputProps={{ 'data-testid': 'signup-phone' }}
              required
              sx={{ my: 2 }}
              label={t('Mobile_number')}
              fullWidth
              name='phone'
              // type='number'
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              value={formik.values.phone.trim().replace(/\s\s+/g, '')}
              // .replace(/\s\s+/g, '')
              // .replace(/\p{Emoji_Presentation}/gu, '')}
              // onChange={(e) => formik.handleChange(e)}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  formik.handleChange(e);
                }
              }}
              inputProps={{
                maxlength: 15,
                minLength: 6,
                'data-testid': 'signup-phone',
              }}
              helperText={
                formik.touched.phone &&
                formik.errors.phone &&
                t(formik.errors.phone)
              }
            />
          </Grid>
        </Grid>
        <FormControl
          fullWidth
          error={formik.touched.role && Boolean(formik.errors.role)}
          helperText={
            formik.touched.role && formik.errors.role && t(formik.errors.role)
          }
          disabled={formik.values.email === userData?.email}
          sx={{ my: 2 }}
        >
          <InputLabel id='roles'>System Role *</InputLabel>

          <Select
            labelId='roles'
            value={formik.values.role}
            label='System Role *'
            name='role'
            onChange={formik.handleChange}
            disabled={formik.values.email === userData?.email}
            // freeSolo
            // freeSolo={isMemberDetails}
            // InputProps={{
            //   readOnly: isMemberDetails,
            //   disableUnderline: isMemberDetails,
            // }}
            InputLabelProps={{
              shrink: true,
            }}
            error={formik.touched.role && Boolean(formik.errors.role)}
            helperText={
              formik.touched.role && formik.errors.role && t(formik.errors.role)
            }
          >
            {roles?.map((e) => (
              <MenuItem
                value={e.value}
                key={e.name}
                sx={{ whiteSpace: 'normal' }}
              >
                {e.name}
              </MenuItem>
            ))}
          </Select>

          {formik.touched.role && formik.errors.role && (
            <FormHelperText>{t(formik.errors.role)}</FormHelperText>
          )}
        </FormControl>
        {/* <TextField
          id='role'
          label='System Role *'
          sx={{ my: 2 }}
          variant={isMemberDetails ? 'standard' : 'outlined'}
          InputProps={{
            readOnly: isMemberDetails,
            disableUnderline: isMemberDetails,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          freeSolo={isMemberDetails}
          fullWidth
          name='role'
          value={formik.values.role}
          onChange={(e) => {
            //  formik.handleChange(e);
            formik.setFieldValue(
              'role',
              e.target?.value?.trimStart().replace(/\s\s+/g, '')
            );
          }}
          error={formik.touched.role && Boolean(formik.errors.role)}
          helperText={
            formik.touched.role && formik.errors.role && t(formik.errors.role)
          }
        /> */}

        <Grid container spacing={2} mt={2}>
          <Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
            <Button
              variant='outlined'
              fullWidth
              // onClick={formik.handleSubmit}
              onClick={() => handleExpand(index, false)}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
            <Button
              variant='contained'
              fullWidth
              onClick={formik.handleSubmit}
              sx={{ mr: 1 }}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MemberCard;
