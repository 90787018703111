import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ICONS } from '../../../../../../constants/Icons';
import { useViewport } from '../../../../../../hooks';
import { handleContactDetails } from '../../../../../../redux/Reducer/OnBoard';
import { getUsersData } from '../../../../../../redux/Reducer/Profile';
import { toggleSnackBar } from '../../../../../../redux/Reducer/Utils';
import { getCountries } from '../../../../../../services/Auth';
import { updateBusinessOnBoard } from '../../../../../../services/Sfe';
import { ContactDetailsValidation } from '../../../../../../utils/ValidationSchema/onBoard';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  LoadingButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '../../../../../Containers/index';
import { sortByProperty } from './BusinessDetails';
// import MultiTreeSelect from '../../../../Containers/MultiTree';

const useStyles = makeStyles({
  root: {},
});

const steps = ['Business Details', 'Contact Details', 'Team Members'];

function ContactDetails({ next, back }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { userData, teamMembers, isTeamLoading, isLoadingProfile } =
    useSelector((state) => state.user);
  const { isRabcUsers } = useSelector((state) => state?.auth);

  const [isLoading, setIsLoading] = useState(false);
  const [isContactDetails, setIsContactDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [industryMultiTree, setIndustryMultiTree] = useState([]);
  const [contriesList, setContriesList] = useState([]);
  const [entityType, setEntityType] = useState([]);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const { businessDetails, contactDetails } = useSelector(
    (state) => state?.onboard
  );
  // console.log('contactDetails', contactDetails);
  const formik = useFormik({
    initialValues: {
      // address: '',
      addressLineOne: '',
      addressLineTwo: '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
      // latitude: '',
      // longitude: '',
      // ghanapost_gps: '',
    },
    validationSchema: ContactDetailsValidation,
    onSubmit: async (values) => {
      // // console.log(values);
      dispatch(handleContactDetails(values));
      // if (contactDetails) {
      //   next(2);
      //   return;
      // }
      const params = {
        address_line_one: values.addressLineOne,
        address_line_two: values.addressLineTwo,
        country: values.country,
        state: values.state,
        city: values.city,
        postal_code: values.country !== 'Ghana' ? values.postalCode : '',
        ghanapost_gps: values.country === 'Ghana' ? values.postalCode : '',
      };
      // // console.log(params);ffbn

      try {
        setIsLoading(true);
        const res = await updateBusinessOnBoard(params);
        if (!contactDetails?.addressLineOne) {
          next(2);
        } else {
          dispatch(
            toggleSnackBar({
              isOpen: true,
              type: 'success',
              message: res?.data?.message,
            })
          );
        }

        dispatch(getUsersData());
      } catch (e) {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: e,
          })
        );
      } finally {
        setIsLoading(false);
      }
    },
  });
  const getCountriesList = async () => {
    var res = await getCountries();
    if (res) {
      setContriesList(res?.data?.data?.countries);
    }
  };
  useEffect(() => {
    getCountriesList();
    // if (false) {
    // if (userData?.platformDetail?.country_of_incorporation === 'Singapore') {
    // getEntityType('SingaporeEntityType');
    // } else {
    // }
  }, []);
  const handleCapcha = async (e) => {
    e?.preventDefault();
    if (isContactDetails) {
      next(2);
      return;
    }
    // // console.log('contactDetails', isContactDetails);

    const err = await formik?.validateForm();
    // console.log(err);
    if (Object.keys(err).length === 0) {
      // setShowRecapcha(true);
      formik.handleSubmit();
    } else {
      Object.keys(err).forEach((k) => {
        formik.setFieldTouched(k, true);
      });
    }
  };

  useEffect(() => {
    if (Object.keys(contactDetails).length !== 0) {
      if (Object.keys(businessDetails).length !== 0) {
        setIsContactDetails(true);
      }

      formik.setFieldValue('addressLineOne', contactDetails?.addressLineOne);
      formik.setFieldValue('addressLineTwo', contactDetails?.addressLineTwo);
      formik.setFieldValue('country', contactDetails?.country);
      formik.setFieldValue('city', contactDetails?.city);
      formik.setFieldValue('state', contactDetails?.state);
      formik.setFieldValue('postalCode', contactDetails?.postalCode);
    }
  }, [contactDetails]);
  return (
    <div>
      <React.Fragment>
        <Card
          sx={{
            p: 8,
            mt: 4,
          }}
        >
          <Grid item xs={12} md={12} lg={12} id='top'>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant='h5'
                component='h3'
                sx={{ fontWeight: 700, py: 2 }}
              >
                {steps[1]}
              </Typography>
              {businessDetails.companyName &&
                contactDetails?.addressLineOne &&
                !isRabcUsers && (
                  <Box>
                    {!isLoadingProfile && (
                      <Tooltip
                        title={isContactDetails ? 'Edit' : 'Read-only'}
                        arrow
                      >
                        <IconButton
                          sx={{ px: 2 }}
                          onClick={() => {
                            setIsContactDetails(!isContactDetails);
                          }}
                        >
                          {!isContactDetails ? (
                            <ICONS.VISIBILITY />
                          ) : (
                            <ICONS.EDIT />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                )}
            </Box>
          </Grid>
          <Divider sx={{ mb: 4 }} />
          {/* <Autocomplete
            id='address'
            name='address'
            label='Location '
            variant='outlined'
            fullWidth
            sx={{ my: 2, mt: 4 }}
            value={formik.values.address}
            onChange={(event, item) => {
              if (item) {
                getLatLngByAddress(item?.description);

                formik.setFieldValue('address', item?.label);
              }
            }}
            onInputChange={(event) => {
              if (event?.target?.value) {
                getPlacePredictions({
                  input: event?.target?.value,
                });
              }
            }}
            options={placePredictions.map((item) => ({
              ...item,
              label: item.description,
              value: item.place_id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Location '
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={
                  formik.touched.address &&
                  formik.errors.address &&
                  t(formik.errors.address)
                }
              />
            )}
          /> */}

          <TextField
            id='addressLineOne'
            label='Address Line 1 (e.g. Street Name) *'
            fullWidth
            sx={{ my: 2 }}
            name='addressLineOne'
            value={formik.values.addressLineOne}
            onChange={(e) => {
              formik.setFieldValue(
                'addressLineOne',
                e.target?.value?.trimStart().replace(/\s\s+/g, '')
              );
            }}
            variant={isContactDetails ? 'standard' : 'outlined'}
            InputProps={{
              readOnly: isContactDetails,
              disableUnderline: isContactDetails,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            freeSolo={isContactDetails}
            error={
              formik.touched.addressLineOne &&
              Boolean(formik.errors.addressLineOne)
            }
            helperText={
              formik.touched.addressLineOne &&
              formik.errors.addressLineOne &&
              t(formik.errors.addressLineOne)
            }
          />

          <TextField
            id='addressLineTwo'
            label='Address Line 2 (e.g. Neighborhood/ Locality)'
            fullWidth
            sx={{ my: 2 }}
            variant={isContactDetails ? 'standard' : 'outlined'}
            InputProps={{
              readOnly: isContactDetails,
              disableUnderline: isContactDetails,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            freeSolo={isContactDetails}
            name='addressLineTwo'
            value={formik.values.addressLineTwo}
            onChange={(e) => {
              formik.setFieldValue(
                'addressLineTwo',
                e.target?.value?.trimStart().replace(/\s\s+/g, '')
              );
            }}
          />

          <Grid container spacing={2}>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12} sx={{ my: 2 }}>
              <Autocomplete
                id='country'
                label='Country/Region *'
                // sx={{ my: 2 }}
                fullWidth
                value={formik.values.country}
                freeSolo={isContactDetails}
                readOnly={isContactDetails}
                onChange={(event, item) => {
                  formik.setFieldValue(
                    'country',
                    item?.label?.trimStart().replace(/\s\s+/g, '')
                  );

                  // formik.setFieldValue(
                  //   'country',
                  //   item?.label?.trimStart().replace(/\s\s+/g, '')
                  // );
                }}
                options={contriesList
                  ?.sort(sortByProperty('name'))
                  ?.map((item) => ({
                    label: item.name,
                    value: item.name,
                  }))}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Country/Region *'
                    variant={isContactDetails ? 'standard' : 'outlined'}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: isContactDetails,
                      disableUnderline: isContactDetails,
                    }}
                    helperText={
                      formik.touched.country &&
                      formik.errors.country &&
                      t(formik.errors.country)
                    }
                  />
                )}
              />
              {/* <TextField
                id='country'
                label='Country/Region *'
                variant={isContactDetails ? 'standard' : 'outlined'}
                InputProps={{
                  readOnly: isContactDetails,
                  disableUnderline: isContactDetails,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                freeSolo={isContactDetails}
                fullWidth
                name='country'
                value={formik.values.country}
                // disabled
                onChange={(e) => {
                  //  formik.handleChange(e);
                  // if (formik.values.registration !== 'Ghana') {
                  formik.setFieldValue(
                    'country',
                    e.target?.value?.trimStart().replace(/\s\s+/g, '')
                  );
                  // }
                }}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={
                  formik.touched.country &&
                  formik.errors.country &&
                  t(formik.errors.country)
                }
              /> */}
            </Grid>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12} sx={{ my: 2 }}>
              <TextField
                id='state'
                label='State/Province *'
                variant={isContactDetails ? 'standard' : 'outlined'}
                InputProps={{
                  readOnly: isContactDetails,
                  disableUnderline: isContactDetails,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                freeSolo={isContactDetails}
                fullWidth
                name='state'
                value={formik.values.state}
                onChange={(e) => {
                  //  formik.handleChange(e);
                  formik.setFieldValue(
                    'state',
                    e.target?.value?.trimStart().replace(/\s\s+/g, '')
                  );
                }}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={
                  formik.touched.state &&
                  formik.errors.state &&
                  t(formik.errors.state)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12} sx={{ my: 2 }}>
              <TextField
                id='city'
                label='City *'
                variant={isContactDetails ? 'standard' : 'outlined'}
                InputProps={{
                  readOnly: isContactDetails,
                  disableUnderline: isContactDetails,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                freeSolo={isContactDetails}
                fullWidth
                name='city'
                value={formik.values.city}
                onChange={(e) => {
                  //  formik.handleChange(e);
                  formik.setFieldValue(
                    'city',
                    e.target?.value?.trimStart().replace(/\s\s+/g, '')
                  );
                }}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={
                  formik.touched.city &&
                  formik.errors.city &&
                  t(formik.errors.city)
                }
              />
            </Grid>
            <Grid item lg={6} xl={6} xs={12} md={12} sm={12} sx={{ my: 2 }}>
              <TextField
                id='postalCode'
                name='postalCode'
                label={
                  formik.values.country !== 'Ghana'
                    ? 'Postal Code *'
                    : 'Ghanapost Code *'
                }
                variant={isContactDetails ? 'standard' : 'outlined'}
                InputProps={{
                  readOnly: isContactDetails,
                  disableUnderline: isContactDetails,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                freeSolo={isContactDetails}
                fullWidth
                value={formik.values.postalCode}
                onChange={(e) => {
                  //  formik.handleChange(e);
                  // if (formik.values.registration !== 'Ghana') {
                  formik.setFieldValue(
                    'postalCode',
                    e.target?.value?.trimStart().replace(/\s\s+/g, '')
                  );
                  // }
                }}
                error={
                  formik.touched.postalCode && Boolean(formik.errors.postalCode)
                }
                helperText={
                  formik.touched.postalCode &&
                  formik.errors.postalCode &&
                  t(formik.errors.postalCode)
                }
              />
            </Grid>
          </Grid>

          {true && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 2,
                  justifyContent:
                    !businessDetails.companyName ||
                    !contactDetails?.addressLineOne
                      ? 'space-between'
                      : 'flex-end',
                }}
              >
                {(!businessDetails.companyName ||
                  !contactDetails?.addressLineOne) && (
                  <Button
                    variant='outlined'
                    onClick={() => next(0)}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                )}
                {!isContactDetails && (
                  <LoadingButton
                    loading={isLoading}
                    disabled={isLoading}
                    variant='contained'
                    // onClick={formik.handleSubmit}
                    onClick={(e) => handleCapcha(e)}
                    sx={{ mr: 1 }}
                  >
                    {!businessDetails.companyName ||
                    !contactDetails?.addressLineOne
                      ? 'Next'
                      : 'Update'}
                  </LoadingButton>
                )}
              </Box>
            </Grid>
          )}
        </Card>
        {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color='inherit'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                    Next
                  </Button> *
                  {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Typography
                        variant='caption'
                        sx={{ display: 'inline-block' }}
                      >
                        Step {activeStep + 1} already completed
                      </Typography>
                    ) : (
                      <Button onClick={handleComplete}>
                        {completedSteps() === totalSteps() - 1
                          ? 'Finish'
                          : 'Next'}
                      </Button>
                    ))}
                </Box> */}
      </React.Fragment>
    </div>
  );
}

export default ContactDetails;
