import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleSnackBar } from '../../../../../../redux/Reducer/Utils';
import { reviewPartner } from '../../../../../../services/Auth';
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LoadingButton
} from '../../../../../Containers/index';

function ConfirmModel({
  open,
  handleClose,
  type,
  role,
  token,
  handleStatus,
  handleLoadingButton
}) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleReviewRequest = async (type) => {
    // setReviewToken(token);
    const params = {
      token: token,
      type: type,
      role: role
    };
    try {
      setIsLoading(true);
      const response = await reviewPartner(params);
      handleStatus();
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'success',
          message: response?.data?.message
        })
      );
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error
        })
      );
    } finally {
      setIsLoading(false);
      handleLoadingButton(false);
      closeModal();
    }
  };
  const closeModal = () => {
    handleClose(false);
  };
  return (
    <Dialog
      open={open}
      aria-labelledby='sign-in-form'
      aria-describedby='sign-in-form'
      disableAutoFocus
      className='modal-form'
    >
      <Card sx={{ my: 4, boxShadow: 'none' }}>
        <Container>
          <Grid container spacing={2} className='mt-3 mb-3'>
            <Grid item xs={12} className=' text-center'>
              <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
                Confrim
              </DialogTitle>
              <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                <DialogContentText className='mt-10 '>
                  {`Are you sure you want to ${
                    type === 'approve' ? 'Approve' : 'Reject'
                  } the request?`}
                </DialogContentText>
              </DialogContent>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={isLoading}
                disabled={isLoading}
                fullWidth
                variant='contained'
                onClick={async () => {
                  handleReviewRequest(type);
                }}
              >
                {type === 'approve' ? 'Approve' : 'Reject'}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant='text' onClick={closeModal}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Card>
    </Dialog>
  );
}

export default ConfirmModel;
