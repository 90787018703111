import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../../constants/Icons';
import { IMAGES } from '../../../../../constants/ImageConstants';
import { ROUTE } from '../../../../../constants/Routes';
import {
  getTeam,
  handleAddUserTab,
} from '../../../../../redux/Reducer/Profile';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  Toolbar,
  Typography,
  makeStyles,
} from '../../../../Containers/index';
const useStyles = makeStyles({
  root: {
    '& img': {
      // width: '100%',
      // maxWidth: '800px',
      // height: 'auto',
    },
    '& .MuiSvgIcon-root': {
      // fill: 'white',
    },
    '& .MuiIconButton-root.Mui-selected': {
      background: '#17236E',
      borderRadius: '24px',
      height: '40px',
    },
    '& .MuiIconButton-root.Mui-selected svg.svglocal': {
      fill: 'white',
      stroke: 'white',
    },
    '& .MuiIconButton-root.Mui-selected.i1 svg.svglocal': {
      marginLeft: '0px !important',
      width: '28px',
    },
    '& .MuiBadge-badge': {
      color: 'red',
      backgroundColor: 'red',
      top: '2px',
      right: '6px',
    },
    '& .MuiIconButton-root.Mui-selected.i2 svg.svglocal': {
      marginLeft: '6px !important',
      marginTop: '2px !important',
      width: '24px',
    },
    '& .MuiIconButton-root svg.svglocal': {
      fill: 'black',
      stroke: '#38383be0',
    },
  },
});
function DashBoard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isInternal, setIsInternal] = useState(false);
  const { notification, userData, teamMembers, isLoadingProfile } = useSelector(
    (state) => state?.user
  );

  const { isRabcOne, isRabcUsers, isChannel, isBDF, isUNO } = useSelector(
    (state) => state?.auth
  );

  useEffect(() => {
    dispatch(getTeam());

    if (
      userData?.roles?.includes('PARTNER_SUPER_ADMIN') ||
      userData?.roles?.includes('PARTNER_ADMIN')
    ) {
      setIsInternal(true);
    }
  }, [userData]);
  return (
    <Box sx={{ display: 'flex' }} className={classes.root}>
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          // height: '97vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Toolbar />
        <Container maxWidth='lg' sx={{ mt: 3, mb: 4 }}>
          <Card>
            {' '}
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                marginTop='10px'
                marginLeft={'10px'}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: 2,
                  }}
                >
                  <Typography
                    variant='h5'
                    component='h3'
                    sx={{ fontWeight: 500, pt: 8 }}
                  >
                    {' '}
                    {userData?.first_name} {userData?.last_name}!
                  </Typography>
                  <Typography
                    color='primary.main'
                    sx={{ fontWeight: 400, pt: 8, mt: 1, ml: 2 }}
                  >
                    (
                    {userData?.roles?.some((v) => v.includes('ADMIN'))
                      ? 'Admin'
                      : 'User'}
                    )
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: 2,
                  }}
                >
                  <Typography
                    // variant='h5'
                    // component='h3'
                    sx={{
                      fontWeight: 400,
                      // px: 8,
                      // mt: 0,
                      fontSize: '14px',
                      color: 'text.secondary',
                    }}
                  >
                    {' '}
                    {userData?.platformDetail?.company_info?.corporate_name ||
                      ''}{' '}
                  </Typography>
                  {!isInternal && (
                    <List
                      sx={
                        {
                          // width: '100%',
                          // maxWidth: 360,
                          // bgcolor: 'background.paper',
                        }
                      }
                    >
                      <ListItem disablePadding>
                        <ListItemButton sx={{ pr: 1 }}>
                          <ListItemIcon sx={{ minWidth: 0 }}>
                            <ICONS.PROFILE sx={{ fontSize: '20px' }} />
                          </ListItemIcon>
                        </ListItemButton>
                        <ListItemText
                          primary={`${teamMembers?.length} ${
                            teamMembers?.length > 1 ? 'Users' : 'User'
                          }`}
                          sx={{
                            '& span': {
                              color: 'grey',
                              fontSize: '14px',
                            },
                          }}
                        />
                      </ListItem>
                    </List>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                container
                spacing={2}
                sx={{
                  border: '0.1rem solid #D3D3D3',
                  borderRadius: 2,
                  m: 4,
                  p: 8,
                  width: { md: 0.93, lg: 1, sm: 0.93, xl: 1, xs: 0.93 },
                }}
              >
                {/* <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    marginTop='10px'
                    // marginLeft={'10px'}
                  > */}
                <Grid item lg={6} xl={6} md={6} xs={10} sm={8}>
                  <Typography
                    variant='h5'
                    component='h3'
                    sx={{ fontWeight: 600, padding: 0, fontSize: '24px' }}
                  >
                    {' '}
                    Welcome to the Proxtera Partner Portal!
                  </Typography>
                  {!isRabcUsers &&
                    !isLoadingProfile &&
                    !userData?.roles?.includes('PARTNER_ADMIN') &&
                    !userData?.roles?.includes('PARTNER_SUPER_ADMIN') && (
                      <Card
                        elevation={0}
                        // variant='outlined'
                        onClick={() => {
                          navigate(ROUTE.ONBOARD);
                          dispatch(handleAddUserTab(true));
                        }}
                        sx={{
                          mb: 2,
                          mt: 12,
                          py: 4,
                          px: 4,
                          boxShadow: 2,
                          cursor: 'pointer',
                          ':hover': {
                            boxShadow: 4, // theme.shadows[20]
                          },
                          '& button': {
                            textTransform: 'none',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                          }}
                        >
                          <Avatar
                            sx={{
                              height: 48,
                              width: 48,
                              bgcolor: 'primary.main',
                              // p: 1,
                            }}
                            src={IMAGES.HANDS}
                          >
                            {/* <ICONS.HANDSHAKE fill={'black'} /> */}
                          </Avatar>
                          <Box
                            sx={{
                              display: 'flex',
                              width: 1,
                              // flexDirection: 'column',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              mt: 2,
                              ml: 4,
                            }}
                          >
                            <Typography
                              variant='p'
                              component='div'
                              sx={{
                                fontWeight: 500,
                                // color: 'primary.main',
                                fontSize: 16,
                              }}
                            >
                              Add Team Members
                            </Typography>
                            <Typography
                              variant='p'
                              component='div'
                              sx={{
                                fontWeight: 500,
                                fontSize: 18,
                                color: 'primary.main',
                              }}
                            >
                              +
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    )}
                </Grid>
                <Grid
                  item
                  xs={0}
                  sm={0}
                  md={6}
                  lg={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    '& img': {
                      maxWidth: '300px',
                      // width: 0,
                    },
                  }}
                >
                  <img
                    src={IMAGES.COWORK}
                    alt='dashboard'
                    width={'300px'}
                    height='auto'
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={8}
                sx={{ display: 'flex', justifyContent: 'start', padding: 4 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  // sx={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <Card
                    sx={{
                      cursor: 'pointer',
                      height: '150px',
                      ':hover': {
                        boxShadow: 4, // theme.shadows[20]
                      },
                    }}
                    onClick={() => navigate(ROUTE.SFE)}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                        }}
                      >
                        <Avatar
                          // className='Mui-selected i1'
                          src={IMAGES.EDUCATION}
                          sx={{
                            height: 48,
                            width: 48,

                            //   '& svg': {
                            //     fill: 'white',
                            //   },
                            //   bgcolor: 'primary.main',
                            //   p: 1,
                          }}
                        >
                          {/* <ICONS.SCHOOL fill={'white'} /> */}
                        </Avatar>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 4,
                          }}
                        >
                          <Typography
                            variant='p'
                            component='div'
                            sx={{
                              fontWeight: 500,
                              fontSize: 18,
                            }}
                          >
                            Education Analytics
                          </Typography>
                          <Typography
                            variant='body2'
                            color='text.secondary'
                            my={3}
                          >
                            {'  '}
                          </Typography>
                        </Box>
                        {/* {notification &&
                          notification?.pending_actions_in_trusted_credentials >
                            0 && (
                            <Badge
                              color='secondary'
                              variant='dot'
                              sx={{ ml: 16, mt: -8 }}
                            >
                              {/* <ICONS.CHEVRON_RIGHT /> *
                            </Badge>
                          )} */}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                {!isChannel && !isBDF && !isUNO && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    // sx={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <Card
                      sx={{
                        cursor: 'pointer',
                        height: '150px',
                        ':hover': {
                          boxShadow: 4, // theme.shadows[20]
                        },
                      }}
                      onClick={() => navigate(ROUTE.FINANCING_ANALYTICS)}
                    >
                      <CardContent>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                          }}
                        >
                          <Avatar
                            // className='Mui-selected i1'
                            src={IMAGES.FIN_ANALYTICS}
                            sx={{
                              height: 48,
                              width: 48,

                              //   '& svg': {
                              //     fill: 'white',
                              //   },
                              //   bgcolor: 'primary.main',
                              //   p: 1,
                            }}
                          >
                            {/* <ICONS.SCHOOL fill={'white'} /> */}
                          </Avatar>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              mt: 4,
                            }}
                          >
                            <Typography
                              variant='p'
                              component='div'
                              sx={{
                                fontWeight: 500,
                                fontSize: 18,
                              }}
                            >
                              Financing Analytics
                            </Typography>
                            <Typography
                              variant='body2'
                              color='text.secondary'
                              my={3}
                            >
                              {'  '}
                            </Typography>
                          </Box>
                          {/* {notification &&
                          notification?.pending_actions_in_trusted_credentials >
                            0 && (
                            <Badge
                              color='secondary'
                              variant='dot'
                              sx={{ ml: 16, mt: -8 }}
                            >
                              {/* <ICONS.CHEVRON_RIGHT /> *
                            </Badge>
                          )} */}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {isInternal && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    // sx={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <Card
                      sx={{
                        cursor: 'pointer',
                        height: '150px',
                        ':hover': {
                          boxShadow: 4, // theme.shadows[20]
                        },
                      }}
                      onClick={() => navigate(ROUTE.IMPACT_METRICS)}
                    >
                      <CardContent>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                          }}
                        >
                          <Avatar
                            // className='Mui-selected i1'
                            src={IMAGES.IMAPACT}
                            sx={{
                              height: 48,
                              width: 48,

                              //   '& svg': {
                              //     fill: 'white',
                              //   },
                              //   bgcolor: 'primary.main',
                              //   p: 1,
                            }}
                          >
                            {/* <ICONS.SCHOOL fill={'white'} /> */}
                          </Avatar>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              mt: 4,
                            }}
                          >
                            <Typography
                              variant='p'
                              component='div'
                              sx={{
                                fontWeight: 500,
                                fontSize: 18,
                              }}
                            >
                              Impact Metrics
                            </Typography>
                            <Typography
                              variant='body2'
                              color='text.secondary'
                              my={3}
                            >
                              {'  '}
                            </Typography>
                          </Box>
                          {/* {notification &&
                          notification?.pending_actions_in_trusted_credentials >
                            0 && (
                            <Badge
                              color='secondary'
                              variant='dot'
                              sx={{ ml: 16, mt: -8 }}
                            >
                              {/* <ICONS.CHEVRON_RIGHT /> *
                            </Badge>
                          )} */}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {isRabcOne && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      // sx={{ display: 'flex', justifyContent: 'space-around' }}
                    >
                      <Card
                        sx={{
                          cursor: 'pointer',

                          height: '150px',
                          ':hover': {
                            boxShadow: 4, // theme.shadows[20]
                          },
                        }}
                        onClick={() => navigate(ROUTE.DATA_VALIDATION)}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                            }}
                          >
                            <Avatar
                              // className='Mui-selected i1'
                              src={IMAGES.DATA_VALIDATION}
                              sx={{
                                // p: 2,
                                height: 48,
                                width: 48,

                                // bgcolor: 'primary.main',

                                // '& svg.svglocal': {
                                //   fill: 'white',
                                //   strokeWidth: 2,
                                // },
                              }}
                            >
                              {/* <ICONS.DATA_VALIDATION /> */}
                            </Avatar>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                mt: 4,
                              }}
                            >
                              <Typography
                                variant='p'
                                component='div'
                                sx={{
                                  fontWeight: 500,
                                  fontSize: 18,
                                }}
                              >
                                Data Validation
                              </Typography>
                              <Typography
                                variant='body2'
                                color='error'
                                mt={2}
                                sx={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                }}
                              >
                                {
                                  notification?.pending_actions_in_data_validation_requests
                                }{' '}
                                Applications Pending
                              </Typography>
                            </Box>
                            {/* {notification &&
                              notification?.pending_actions_in_data_validation_requests >
                                0 && (
                                <Badge
                                  color='secondary'
                                  variant='dot'
                                  sx={{ ml: 16, mt: -8 }}
                                >
                                  {/* <ICONS.CHEVRON_RIGHT /> 
                                </Badge>
                              )} */}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      // sx={{ display: 'flex', justifyContent: 'space-around' }}
                    >
                      <Card
                        sx={{
                          cursor: 'pointer',
                          height: '150px',
                          ':hover': {
                            boxShadow: 4, // theme.shadows[20]
                          },
                        }}
                        onClick={() => navigate(ROUTE.FINANCING_REQUEST)}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                            }}
                          >
                            <Avatar
                              src={IMAGES.FINANCE}
                              sx={{
                                height: 48,
                                width: 48,

                                //   bgcolor: 'primary.main',
                                //   p: 1,
                                //   '& svg.svglocal': {
                                //     fill: 'white',
                                //   },
                              }}
                              // className='Mui-selected i2'
                            >
                              {/* <ICONS.FINANCING
                                fill='white'
                                className='Mui-selected i2'
                              /> */}
                            </Avatar>
                            {/* <IconButton
                            className='Mui-selected i2'
                            // sx={{
                            //   borderRadius: 8,
                            // }}
                          >
                            <ICONS.FINANCING />
                          </IconButton> */}
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                mt: 4,
                              }}
                            >
                              <Typography
                                variant='p'
                                component='div'
                                sx={{
                                  fontWeight: 500,
                                  fontSize: 18,
                                }}
                              >
                                Financing Applications
                              </Typography>
                              <Typography
                                variant='body2'
                                color='error'
                                mt={2}
                                sx={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                }}
                              >
                                {
                                  notification?.pending_actions_in_financing_requests
                                }{' '}
                                Applications Pending
                              </Typography>
                            </Box>
                            {/* {notification &&
                              notification?.pending_actions_in_financing_requests >
                                0 && (
                                <Badge
                                  color='secondary'
                                  variant='dot'
                                  sx={{ ml: 4, mt: -8 }}
                                >
                                  {/* <MailIcon /> 
                                </Badge>
                              )} */}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                marginTop='10px'
                marginBottom='10px'
                marginLeft={'10px'}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {/* <img src={IMAGES.DASHBOARD} alt='dashboard' /> */}
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </Box>
  );
}

export default DashBoard;
