import * as yup from 'yup';

const emailRules = yup
  .string('Enter_your_email')
  .email('Enter_a_valid_email')
  .trim()
  .required('Email_is_required');

const contactEmailRules = yup
  .string('Contract_Enter_your_email')
  .email('Contract_a_valid_email')
  .trim()
  .required('Contract_Email_is_required');
const passwordRules = yup
  .string('Enter_your_password')
  .trim()
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.[{}()"%/,><':;|_~`+=]).{8,}$/,
    'Password_policy'
  )
  .required('Password_is_required');
const changepasswordRules = yup
  .string('New_password_is_required')
  .trim()
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.[{}()"%/,><':;|_~`+=]).{8,}$/,
    'Password_policy'
  )
  .required('New_password_is_required');
const newPasswordRules = yup
  .string('New_password_is_required')
  .trim()
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.[{}()"%/,><':;|_~`+=]).{8,}$/,
    'Password_policy'
  )
  .required('New_password_is_required');
const loginPasswordRules = yup
  .string('Enter_your_password')
  .trim()
  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.[{}()"%/,><':;|_~`+=]).{8,}$/,
  //   'Password_policy'
  // )
  .required('Password_is_required');

const confirmPasswordRules = yup
  .string('Enter_your_password')
  .trim()
  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.[{}()"%/,><':;|_~`+=]).{8,}$/,
  //   'Password_policy'
  // )
  .required('Confirm_password_is_required')
  .oneOf([yup.ref('password'), null], 'Both_password_need_to_be_the_same')
  .required('Confirm_password_is_required');
const confirmPassRules = yup
  .string('Confirm_password_is_required')
  .trim()
  .max(99, 'Maximum of 99 characters is allowed')
  .oneOf([yup.ref('password'), null], 'Both_password_need_to_be_the_same')
  .required('Confirm_password_is_required');
const designationRules = yup
  .string('Designation_is_required')
  .trim()
  .required('Designation_is_required');

const firstNameRules = yup
  .string('First_name_is_required')
  .trim()
  .required('First_name_is_required');

const lastNameRules = yup
  .string('Last_name_is_required')
  .trim()
  .required('Last_name_is_required');

const codeRules = yup
  .string('Phone_Code_is_required')
  .trim()
  .required('Phone_Code_is_required');

const phoneRules = yup
  .string('Phone_number_is_required')
  .trim()
  .required('Phone_number_is_required')
  .matches(/^\d+$/, 'Phone_number_is_not_valid');
// .matches(new RegExp('[0-9]{7}'), 'Phone_number_is_not_valid');
yup.addMethod(
  yup.string,
  'phone',
  function (messageError = 'Phone number is not valid') {
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    return this.test('phone', messageError, (value) => {
      if (value && value.length > 0) {
        return phoneRegExp.test(value);
      }
      return true;
    });
  }
);
const urlRules = yup
  .string()
  .trim()
  .required('URL_is_required')
  // eslint-disable-next-line
  .matches(
    // eslint-disable-next-line
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    'Link_should_be_a_valid_URL'
  );
// const primaryIndustryRules = yup
//   .object('Primary_Industry_is_required')
//   // .trim()
//   .required('Primary_Industry_is_required');
const taxIdRules = yup
  .string('TaxId_is_required')
  .trim()
  .required('TaxId_is_required');
const corporateNameRules = yup
  .string('Corporate_name_is_required')
  .trim()
  .required('Corporate_name_is_required');

const registrationRules = yup
  .string('Registration_is_required')
  // .trim()
  .required('Registration_is_required');

const acceptCreateAccountRules = yup
  .bool()
  .oneOf([true], 'Accept_create_account_is_required');

const acceptPrivacyPolicyRules = yup
  .bool()
  .oneOf([true], 'Accept_privacy_policy_is_required');

const acceptTermsAndConditionsRules = yup
  .bool()
  .oneOf([true], 'Accept_terms_and_condtions_is_required');

const acceptTermsOfUseRules = yup
  .bool()
  .oneOf([true], 'Accept_terms_of_use_is_required');

const countryRules = yup
  .string('Enter_your_country')
  .required('Country_is_required')
  .typeError('Country_type');
const stateRules = yup
  .string('Enter_your_state')
  .required('State_is_required')
  .typeError('State_type');
const cityRules = yup
  .string('Enter_your_city')
  .required('City_is_required')
  .typeError('City_type');
const postalCodeRules = yup
  .string('Enter_your_postCodee')
  .min('1', 'Minimum_One_Charater_Is_Required')
  .max('20', 'Maximum_Twenty_Charaters')
  .required('postCode_is_required');
// .matches(/^[0-9 ]+$/, 'postCode_type');
const primaryIndustryRules = yup
  .object()
  .nullable()
  .required('Primary Industry is required');
// const locationRules = yup
//   .string('Enter_your_location')
//   .required('Location_is_required');
const addressLineOneRules = yup
  .string('Enter_your_addressLineOne')
  .required('AddressLineOne_is_required');
// const toLatitudeRules = yup.number().required(`${'Latitude_is_required'}`);
// const toLongitudeRules = yup.number().required(`${'Longitude_is_required'}`);
const ghanapostGps = yup.string().when('registration', {
  is: 'Ghana',
  then: yup.string().required('Ghanapost_Gps_Is_Required'),
  otherwise: yup.string(),
});
export const LoginValidationSchema = yup.object({
  email: emailRules,
  password: loginPasswordRules,
});

export const ForgetPasswordValidationSchema = yup.object({
  email: emailRules,
});

export const SignUpValidationSchema = yup.object().shape({
  email: contactEmailRules,
  password: passwordRules,
  confirmPassword: confirmPasswordRules,
  firstName: firstNameRules,
  lastName: lastNameRules,
  designation: designationRules,
  code: codeRules,
  phone: phoneRules,
  corporateName: corporateNameRules,
  registration: registrationRules,
  taxId: taxIdRules,
  url: urlRules,
  acceptTermsOfUse: acceptTermsOfUseRules,
  acceptTermsAndConditions: acceptTermsAndConditionsRules,
  acceptPrivacyPolicy: acceptPrivacyPolicyRules,
  acceptCreateAccount: acceptCreateAccountRules,
  country: countryRules,
  state: stateRules,
  ghanapost_gps: ghanapostGps,

  // latitude: toLatitudeRules,
  // longitude: toLongitudeRules,
  // address: locationRules,
  addressLineOne: addressLineOneRules,
  postalCode: postalCodeRules,
  city: cityRules,
  primaryIndustry: primaryIndustryRules,
});

export const ResetPasswordValidationSchema = yup.object({
  password: passwordRules,
  confirmPassword: confirmPasswordRules,
});

export const SetPasswordValidationSchema = yup.object({
  password: newPasswordRules,
  // currentPassword: passwordRules,
  confirmPassword: confirmPassRules,
});
export const changePasswordValidationSchema = yup.object({
  password: changepasswordRules,
  confirmPassword: confirmPasswordRules,
});
