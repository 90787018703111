import React from 'react';

import AfterVerify from './AfterVerify';

const ApproveMemberRequest = () => {
  // const location = useLocation().search;
  // const token = new URLSearchParams(location).get('token');
  // const dispatch = useDispatch();

  // const [isVerified, setIsVerified] = useState(false);
  // const [isTokenFound, setTokenFound] = useState(false);
  // const [acceptMessage, setAcceptMessage] = useState('');
  // const handleEmailVerify = async (token) => {
  //   const params = {
  //     token: token,
  //     source: 'dashboard',
  //   };
  //   try {
  //     const res = await approveMember(params);
  //     setAcceptMessage(res?.data?.message);
  //     setIsVerified(true);
  //   } catch (error) {
  //     setAcceptMessage(error);
  //     setIsVerified(true);

  //     dispatch(
  //       toggleSnackBar({
  //         isOpen: true,
  //         type: 'error',
  //         message: error,
  //       })
  //     );
  //   }
  // };
  // useEffect(() => {
  //   if (token !== '' && token !== null && token !== undefined) {
  //     setTokenFound(true);
  //     handleEmailVerify(token);
  //   }
  //   // eslint-disable-next-line
  // }, [token]);

  // if (isTokenFound && !isVerified) {
  //   return (
  //     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
  //       <CircularProgress />
  //     </Box>
  //   );
  // } else if (isTokenFound && isVerified) {
  return <AfterVerify />;
  // }
};

export default ApproveMemberRequest;
