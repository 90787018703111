import { TextField } from '@mui/material';
import TreeSelect, {
  DefaultOption,
  FreeSoloNode,
  getDefaultOptionProps,
} from 'mui-tree-select';
import React, { useEffect, useState } from 'react';
let sampleData = [];

class Node {
  constructor(value) {
    this.value = value;
  }
  getParent() {
    const parent = (() => {
      if (this.value && 'L2' in this.value) {
        return null;
      } else if (this.value && 'L3' in this.value) {
        return (
          sampleData?.find(({ L2 }) =>
            L2.some(({ id }) => id === this.value.id)
          ) || null
        );
      } else if (this.value && 'L4' in this.value) {
        return (
          sampleData?.find(({ L3 }) =>
            L3?.some(({ id }) => id === this.value.id)
          ) || null
        );
      } else {
        for (const { L2 } of sampleData) {
          const state = L2?.find(({ L3 }) =>
            L3.some(({ id }) => id === this.value.id)
          );
          if (state) {
            return state;
          }
        }
        return null;
      }
    })();
    return parent ? new Node(parent) : parent;
  }
  getChildren() {
    if (this.value && 'L2' in this.value) {
      return this.value.L2.map((L2) => new Node(L2));
    } else if (this.value && 'L3' in this.value) {
      return this.value.L3.map((L3) => new Node(L3));
    } else if (this.value && 'L4' in this.value) {
      return this.value.L4.map((L4) => new Node(L4));
    } else {
      return null;
    }
  }
  isBranch() {
    return 'L2' in this.value || 'L3' in this.value || 'L4' in this.value;
  }
  isEqual(to) {
    return to.value.id === this.value.id;
  }
  toString() {
    return this.value.id
      ? this.value.id + ' - ' + this.value.name
      : this.value.name;
  }
}
const syncOrAsync = function (value, returnAsync) {
  if (returnAsync) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(value), Math.random() * 500)
    );
  }
  return value;
};
const MultiTreeSelect = ({
  label,
  onChangeSelect,
  industryMultiTree,
  defaultIndustries = null,
  error,
  readOnly,
  helperText,
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (defaultIndustries !== null) {
      setValue(() => new Node(defaultIndustries));
    } else {
      setValue(() => defaultIndustries);
    }
  }, [defaultIndustries]);

  sampleData = industryMultiTree;
  return (
    <div>
      <TreeSelect
        getChildren={(node) =>
          syncOrAsync(
            node
              ? node.getChildren()
              : industryMultiTree.map((country) => new Node(country)),
            false
          )
        }
        getOptionDisabled={(option) => {
          var _a;
          return (
            option.isBranch() &&
            !((_a = option.getChildren()) === null || _a === void 0
              ? void 0
              : _a.length)
          );
        }}
        filterOptions={(options, { inputValue }) =>
          options.filter((option) => {
            const searchDescendants = (node) => {
              const children = node.getChildren();
              if (children?.length) {
                return children.some((cNode) => {
                  return (
                    cNode.value.name
                      .toLowerCase()
                      .includes(inputValue.toLowerCase()) ||
                    searchDescendants(cNode)
                  );
                });
              }
              return false;
            };

            return (
              option.value.name
                .toLowerCase()
                .includes(inputValue.toLowerCase()) || searchDescendants(option)
            );
          })
        }
        renderOption={(...args) => {
          const [, option, { inputValue, pathDirection }] = args;

          if (pathDirection === 'down' && inputValue.trim()) {
            const getNumMatches = (node, numMatches = 0) =>
              (node.getChildren() || []).reduce((numMatches, cNode) => {
                if (
                  cNode.value.name
                    .toLowerCase()
                    .includes(inputValue.trim().toLowerCase())
                ) {
                  numMatches++;
                }
                return getNumMatches(cNode, numMatches);
              }, numMatches);

            const numMatchedDescendants = getNumMatches(option);

            if (numMatchedDescendants) {
              const { ListItemTextProps, ...props } = getDefaultOptionProps(
                ...args
              );

              return (
                <DefaultOption
                  {...props}
                  ListItemTextProps={{
                    ...ListItemTextProps,
                    secondary: `${numMatchedDescendants} Matches`,
                  }}
                />
              );
            }
          }

          return <DefaultOption {...getDefaultOptionProps(...args)} />;
        }}
        getParent={(node) => syncOrAsync(node.getParent(), false)}
        isBranch={(node) => syncOrAsync(node.isBranch(), false)}
        isOptionEqualToValue={(option, value) => {
          return option instanceof FreeSoloNode ? false : option.isEqual(value);
        }}
        value={value}
        renderInput={(params) =>
          readOnly ? (
            value !== null ? (
              <TextField
                {...params}
                disableClearable
                multiline
                sx={{ mt: 2 }}
                fullWidth
                variant={'outlined'}
                disableUnderline={readOnly}
                disabled={readOnly}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                value='N/A'
                readOnly
                label={label}
                error={error}
                helperText={helperText}
              />
            ) : (
              <TextField
                disableClearable
                variant={'outlined'}
                fullWidth
                disableUnderline={readOnly}
                disabled={readOnly}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                value='N/A'
                readOnly
                label={label}
                error={error}
                helperText={helperText}
              />
            )
          ) : (
            <TextField
              {...params}
              label={label}
              disabled={readOnly}
              fullWidth
              error={error}
              helperText={helperText}
            />
          )
        }
        sx={{ mb: 3 }}
        onChange={(_, value, reason) => {
          onChangeSelect(value);
        }}
      />
    </div>
  );
};

export default MultiTreeSelect;
