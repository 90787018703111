import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ICONS } from '../../../../../../constants/Icons';
import { useViewport } from '../../../../../../hooks';
import { getCountries, getRoles } from '../../../../../../services/Auth';
import { GOOGLE_MAP_KEY } from '../../../../../../services/Env';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  LoadingButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '../../../../../Containers/index';
// import MultiTreeSelect from '../../../../Containers/MultiTree';
import { useNavigate } from 'react-router-dom';
import {
  getTeam,
  handleAddUserTab,
} from '../../../../../../redux/Reducer/Profile';
import { toggleSnackBar } from '../../../../../../redux/Reducer/Utils';
import {
  addTeamMember,
  deleteInvite,
  resendInvite,
} from '../../../../../../services/Sfe';
import { memberValidation } from '../../../../../../utils/ValidationSchema/onBoard';
import MemberCard from './MemberCard';
const useStyles = makeStyles({
  root: {},
});

Geocode.setApiKey(GOOGLE_MAP_KEY);
Geocode.setLanguage('en');
const steps = ['Business Details', 'Contact Details', 'Team Members'];

function MemberDetails({ next }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const [contriesList, setContriesList] = useState([]);
  const { isRabcUsers } = useSelector((state) => state?.auth);

  const [showAddCard, setShowAddCard] = useState(false);
  const [roles, setRoles] = useState([
    { name: 'Admin', value: 'admin' },
    { name: 'User', value: 'user' },
  ]);
  const [info, setInfo] = useState([
    {
      name: 'Admin',
      permissions: [
        'Unrestricted access to all features within the Partner Portal',
        'Manage team members (add or remove members)',
        'Authorised to edit and maintain accuracy of company’s business profile',
      ],
    },
    {
      name: 'User',
      permissions: [
        'Access to Data Validation and Financing application review features',
        "Restricted from modifying the company's business profile",
        'Unable to manage team members',
      ],
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isMemberDetails, setIsMemberDetails] = useState(true);
  const [ud, setUd] = useState([]);
  const { userData, teamMembers, isTeamLoading, navToAddUserTab } = useSelector(
    (state) => state.user
  );
  const toggleAddCard = () => setShowAddCard(!showAddCard);
  // const handleForm = () => {
  //   // console.log(formik);
  // };
  const getCountriesList = async () => {
    var res = await getCountries();
    // console.log(res);

    if (res) {
      const newData = res?.data?.data?.countries?.filter(
        (li, idx, self) =>
          self.map((itm) => itm.dialCode).indexOf(li.dialCode) === idx
      );
      setContriesList(newData);
    }
  };

  const handleExpand = (index, flag) => {
    const updatedData = [...ud];
    updatedData[index] = {
      ...updatedData[index],
      flag: flag,
    };
    // console.log(updatedData);
    setUd(updatedData);
    // setUd(updatedData);
  };
  const sortByProperty = (property) => {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;

      return 0;
    };
  };
  const getRolesForAccess = async () => {
    try {
      const result = await getRoles();
      if (result) {
        setRoles(result?.data?.data?.roles);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const handleDeleteInvite = async (userId) => {
    setSelectedId(userId);
    const params = {
      user_id: userId,
      source: 'dashboard',
    };
    // // console.log(params);

    try {
      setIsDeleteLoading(true);
      const res = await deleteInvite(params);
      // setShowAddCard(false);
      // console.log(res);
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'success',
          message: res?.data?.message,
        })
      );
      dispatch(getTeam());

      // next(2);
    } catch (e) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: e,
        })
      );
    } finally {
      setIsDeleteLoading(false);
      setSelectedId('');
    }
  };
  const handleResendInvite = async (userId) => {
    setSelectedId(userId);
    const params = {
      user_id: userId,
      source: 'dashboard',
    };

    try {
      setIsResendLoading(true);
      const res = await resendInvite(params);

      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'success',
          message: res?.data?.message,
        })
      );
    } catch (e) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: e,
        })
      );
    } finally {
      setIsResendLoading(false);
      setSelectedId('');
    }
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      designation: '',
      email: '',
      code: '',
      phone: '',
      role: '',
      // other_phone_numbers: [],
      // ghanapost_gps: '',
    },
    validationSchema: memberValidation,
    onSubmit: async (values, { resetForm }) => {
      // console.log(values);

      const params = {
        first_name: values.firstName,
        last_name: values.lastName,
        designation: values.designation,
        email: values.email,
        country_code: values.code,
        phone: values.phone,
        role: values.role,
        source: 'dashboard',
      };
      // // console.log(params);

      try {
        setIsLoading(true);
        const res = await addTeamMember(params);
        resetForm();
        setShowAddCard(false);
        dispatch(getTeam());
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'success',
            message: res?.data.message,
          })
        );
        // next(2);
      } catch (e) {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: e,
          })
        );
      } finally {
        setIsLoading(false);
      }
    },
  });
  const handleAddObject = () => {
    if (formik.values.code !== '' && formik.values.phone !== '') {
      const currentOtherPhoneNumbers = formik.values.other_phone_numbers;

      const newPhoneNumber = {
        code: '',
        phone: '',
      };
      const lastObject =
        currentOtherPhoneNumbers[currentOtherPhoneNumbers.length - 1];

      if (lastObject && lastObject.code === '' && lastObject.phone === '') {
        // Don't push the newPhoneNumber object
      } else {
        currentOtherPhoneNumbers.push(newPhoneNumber);
      }

      formik.setFieldValue('other_phone_numbers', currentOtherPhoneNumbers);
    }
  };
  useEffect(() => {
    // if (false) {
    // if (userData?.platformDetail?.country_of_incorporation === 'Singapore') {
    // getEntityType('SingaporeEntityType');
    // } else {
    // }
    // getRolesForAccess();
    getCountriesList();
  }, []);

  useEffect(() => {
    if (teamMembers) {
      setUd(teamMembers);
    }
  }, [teamMembers]);

  useEffect(() => {
    if (navToAddUserTab) {
      setShowAddCard(true);
      dispatch(handleAddUserTab(false));
    }
  }, [navToAddUserTab]);
  return (
    <div>
      <React.Fragment>
        <FormikProvider value={formik}>
          <Card
            sx={{
              p: 8,
              mt: 4,
            }}
          >
            <Grid item xs={12} md={12} lg={12} id='top'>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant='h5'
                  component='h3'
                  sx={{ fontWeight: 700, py: 2 }}
                >
                  {steps[2]}
                </Typography>
                {/* <Tooltip title={!isMemberDetails ? 'Edit' : 'Read-only'} arrow>
                  <IconButton
                    // title='Edit'
                    sx={{ p: 2 }}
                    onClick={() => {
                      setIsMemberDetails(!isMemberDetails);
                    }}
                  >
                    {isMemberDetails ? <ICONS.VISIBILITY /> : <ICONS.EDIT />}
                  </IconButton>
                </Tooltip> */}
              </Box>
            </Grid>
            <Divider sx={{ mb: 4 }} />
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Grid item lg={12} xl={12} md={12} xs={12} sm={12}>
                <Typography
                  variant='span'
                  component='span'
                  sx={{
                    fontSize: 13,
                    mb: 2,
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontWeight: 500,
                  }}
                >
                  System Roles:
                </Typography>
                {info?.map((e, i) => (
                  <Box sx={{ ml: 1 }} key={i}>
                    <List
                      sx={{
                        // listStyleType: 'decimal',
                        fontSize: 12,
                        m: 0,
                        p: 0,
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontWeight: 600,
                      }}
                    >
                      <ListItemText
                        sx={{
                          display: 'list-item',
                          fontSize: 12,
                          m: 0,
                          p: 0,

                          color: 'grey',
                          fontWeight: 600,
                        }}
                        // variant='h6'
                        secondary={i + 1 + '. ' + e?.name}
                      />
                    </List>
                    <List
                      sx={{
                        listStyleType: 'lower-alpha',
                        fontSize: 12,
                        m: 0,
                        p: 0,
                        pl: 8,
                        color: 'grey',
                        fontWeight: 600,
                      }}
                    >
                      {e?.permissions?.map((k, i) => (
                        <ListItem
                          key={i}
                          sx={{
                            display: 'list-item',
                            fontSize: 12,
                            m: 0,
                            p: 0,
                            pl: 1,
                            color: 'grey',
                            fontWeight: 600,
                          }}
                        >
                          <ListItemText
                            secondary={k}
                            sx={{ fontSize: 12, color: 'grey' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ))}
              </Grid>
              {!isRabcUsers && (
                <Grid item lg={12} xl={12} md={12} xs={12} sm={12}>
                  <Card
                    elevation={0}
                    sx={{
                      mb: 2,
                      boxShadow: 4,
                      '& button': {
                        textTransform: 'none',
                      },
                    }}
                  >
                    {!showAddCard && (
                      <Button
                        // variant='outlined'
                        fullWidth
                        onClick={() => setShowAddCard(true)}
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          ml: 4,
                        }}
                      >
                        + Add more team members
                      </Button>
                    )}
                  </Card>
                </Grid>
              )}
            </Grid>
            {showAddCard && (
              <Card
                elevation={0}
                sx={{
                  p: 4,
                  // mt: 8,
                  boxShadow: 4,
                }}
              >
                <CardContent sx={{ px: 8 }}>
                  <Grid container spacing={2}>
                    <TextField
                      inputProps={{
                        'data-testid': 'signup-firstname',
                      }}
                      sx={{ my: 2 }}
                      label={t('First_name')}
                      variant={false ? 'standard' : 'outlined'}
                      // InputProps={{
                      //   readOnly: isMemberDetails,
                      //   disableUnderline: isMemberDetails,
                      // }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      // freeSolo={isMemberDetails}
                      required
                      fullWidth
                      name='firstName'
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      value={formik.values.firstName
                        .trimStart()
                        .replace(/\s\s+/g, '')
                        .replace(/\p{Emoji_Presentation}/gu, '')}
                      onChange={(e) => {
                        const regx = /^[a-zA-Z ]*$/;
                        if (
                          e.target.value === '' ||
                          regx.test(e.target.value)
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      helperText={
                        formik.touched.firstName &&
                        formik.errors.firstName &&
                        t(formik.errors.firstName)
                      }
                    />

                    <TextField
                      inputProps={{
                        'data-testid': 'signup-lastname',
                      }}
                      sx={{ my: 2 }}
                      variant={false ? 'standard' : 'outlined'}
                      // InputProps={{
                      //   readOnly: isMemberDetails,
                      //   disableUnderline: isMemberDetails,
                      // }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      // freeSolo={isMemberDetails}
                      label={t('Last_name')}
                      required
                      fullWidth
                      name='lastName'
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      value={formik.values.lastName
                        .trimStart()
                        .replace(/\s\s+/g, '')
                        .replace(/\p{Emoji_Presentation}/gu, '')}
                      onChange={(e) => {
                        const regx = /^[a-zA-Z ]*$/;
                        if (
                          e.target.value === '' ||
                          regx.test(e.target.value)
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      helperText={
                        formik.touched.lastName &&
                        formik.errors.lastName &&
                        t(formik.errors.lastName)
                      }
                    />

                    <TextField
                      inputProps={{ 'data-testid': 'signup-designation' }}
                      fullWidth
                      // required
                      variant={false ? 'standard' : 'outlined'}
                      // InputProps={{
                      //   readOnly: isMemberDetails,
                      //   disableUnderline: isMemberDetails,
                      // }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      // freeSolo={isMemberDetails}
                      required
                      sx={{ my: 2 }}
                      label={t('Designation')}
                      name='designation'
                      error={
                        formik.touched.designation &&
                        Boolean(formik.errors.designation)
                      }
                      value={formik.values.designation
                        .trimStart()
                        .replace(/\s\s+/g, '')
                        .replace(/\p{Emoji_Presentation}/gu, '')}
                      onChange={(e) => formik.handleChange(e)}
                      helperText={
                        formik.touched.designation &&
                        formik.errors.designation &&
                        t(formik.errors.designation)
                      }
                    />

                    <Grid container spacing={2}>
                      <Grid item lg={12} xl={12} xs={12} md={12} sm={12}>
                        <TextField
                          inputProps={{ 'data-testid': 'signup-email' }}
                          fullWidth
                          variant={false ? 'standard' : 'outlined'}
                          // InputProps={{
                          //   readOnly: isMemberDetails,
                          //   disableUnderline: isMemberDetails,
                          // }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          // freeSolo={isMemberDetails}
                          required
                          sx={{ my: 2 }}
                          label={t('Email')}
                          name='email'
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          value={formik.values.email
                            .trimStart()
                            .replace(/\s\s+/g, '')
                            .replace(/\p{Emoji_Presentation}/gu, '')}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          helperText={
                            formik.touched.email &&
                            formik.errors.email &&
                            t(formik.errors.email)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={4} xl={4} md={4} xs={12} sm={4}>
                        <FormGroup>
                          <Autocomplete
                            id='code'
                            name={'code'}
                            label={`${t('Code')} *`}
                            onBlur={() => {
                              formik.setFieldValue(
                                'code',
                                formik.values?.code?.trim()
                              );
                            }}
                            onInputChange={(event, value, reason) => {
                              if (reason === 'input') {
                                let matchedOption = contriesList?.find(
                                  (option) => option?.dialCode === '+' + value
                                );
                                if (matchedOption) {
                                  formik.setFieldValue(
                                    'code',
                                    matchedOption?.dialCode
                                    // matchedOption
                                  );
                                } else {
                                  formik.setFieldValue('code', '');
                                }
                              }
                            }}
                            fullWidth
                            sx={{ my: 2 }}
                            value={formik.values?.code || ''}
                            onChange={(event, item) => {
                              if (item !== null) {
                                formik.setFieldValue('code', item?.label);
                              } else {
                                formik.setFieldValue('code', '');
                              }
                            }}
                            error={
                              formik.touched.code && Boolean(formik.errors.code)
                            }
                            helperText={
                              formik.touched.code && formik.errors.code
                            }
                            options={contriesList
                              // ?.sort(sortByProperty('dialCode'))
                              ?.sort((a, b) => a?.dialCode - b?.dialCode)
                              ?.map((item) => ({
                                label: item?.dialCode,
                                value: item?.dialCode,
                              }))}
                            isOptionEqualToValue={(option, value) =>
                              option?.value === value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t('Code')} *`}
                                error={
                                  formik.touched.code &&
                                  Boolean(formik.errors.code)
                                }
                                helperText={
                                  formik.touched.code && formik.errors.code
                                    ? t(formik.errors.code)
                                    : ''
                                }
                                variant={'outlined'}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                                value={formik.values?.code || ''}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item lg={8} xl={8} xs={12} md={8} sm={8}>
                        <TextField
                          // inputProps={{ 'data-testid': 'signup-phone' }}
                          required
                          sx={{ my: 2 }}
                          label={t('Mobile_number')}
                          fullWidth
                          name='phone'
                          variant={false ? 'standard' : 'outlined'}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          value={formik.values.phone
                            .trim()
                            .replace(/\s\s+/g, '')}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === '' ||
                              re.test(e.target.value)
                            ) {
                              formik.handleChange(e);
                            }
                          }}
                          inputProps={{
                            maxlength: 15,
                            minLength: 6,
                            'data-testid': 'signup-phone',
                          }}
                          helperText={
                            formik.touched.phone &&
                            formik.errors.phone &&
                            t(formik.errors.phone)
                          }
                        />
                      </Grid>
                    </Grid>
                    <FormControl
                      fullWidth
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      helperText={
                        formik.touched.role &&
                        formik.errors.role &&
                        t(formik.errors.role)
                      }
                      sx={{ my: 2 }}
                    >
                      <InputLabel id='roles'>System Role *</InputLabel>

                      <Select
                        labelId='roles'
                        value={formik.values.role}
                        label='System Role *'
                        name='role'
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={
                          formik.touched.role && Boolean(formik.errors.role)
                        }
                        helperText={
                          formik.touched.role &&
                          formik.errors.role &&
                          t(formik.errors.role)
                        }
                      >
                        {roles?.map((e) => (
                          <MenuItem value={e.value} key={e.name}>
                            {e.name}
                          </MenuItem>
                        ))}
                      </Select>

                      {formik.touched.role && formik.errors.role && (
                        <FormHelperText>{t(formik.errors.role)}</FormHelperText>
                      )}
                    </FormControl>
                    {/* <TextField
                      id='role'
                      label='System Role *'
                      sx={{ my: 2 }}
                      variant={isMemberDetails ? 'standard' : 'outlined'}
                      InputProps={{
                        readOnly: isMemberDetails,
                        disableUnderline: isMemberDetails,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      freeSolo={isMemberDetails}
                      fullWidth
                      name='role'
                      value={formik.values.role}
                      onChange={(e) => {
                        //  formik.handleChange(e);
                        formik.setFieldValue(
                          'role',
                          e.target?.value?.trimStart().replace(/\s\s+/g, '')
                        );
                      }}
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      helperText={
                        formik.touched.role &&
                        formik.errors.role &&
                        t(formik.errors.role)
                      }
                    /> */}

                    <Grid container spacing={2} mt={2}>
                      <Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
                        <Button
                          variant='outlined'
                          fullWidth
                          // onClick={formik.handleSubmit}

                          onClick={toggleAddCard}
                          sx={{ mr: 1 }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
                        <LoadingButton
                          loading={isLoading}
                          variant='contained'
                          fullWidth
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                          sx={{ mr: 1 }}
                        >
                          Add & Send Invite
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            {ud?.map((e, i) => (
              <Card
                // variant='outlined'
                sx={{
                  mt: 4,
                  boxShadow: 4,
                  // pointer: 'cursor',
                  // '& :hover': {
                  //   boxShadow: 4,
                  // },
                  px: 4,
                }}
                key={e?.id}
                elevation={0}
              >
                <Grid container spacing={2}>
                  <List sx={{ width: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <ListItem>
                          {/* <Avatar
                            sx={{ width: 32, height: 32, fontSize: 14, mr: 2 }}
                            src={e?.first_name}
                          >
                            {e?.first_name?.split('')?.[0]?.toUpperCase()}K
                          </Avatar> */}
                          <ListItemText
                            primary={`${e?.first_name} ${e?.last_name}`}
                            secondary={e?.status || 'Active'}
                            secondaryTypographyProps={{
                              color:
                                e?.status === 'Invitation pending'
                                  ? '#D95D39'
                                  : '#0E8A0D',
                            }}
                            sx={{ pl: 1 }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <ListItem
                          sx={{
                            '& .MuiListItemText-root': {
                              display: 'flex',
                              justifyContent: 'flex-end',
                              mr: 2,
                            },
                          }}
                          onClick={() => {
                            // if (e?.flag == false) {
                            // }
                          }}
                        >
                          <ListItemText
                            color='primary'
                            primary={
                              e?.roles &&
                              e?.roles[0].toLowerCase().includes('user')
                                ? 'User'
                                : 'Admin'
                            }
                            sx={{ pl: 1 }}
                          />
                          {isMemberDetails &&
                            !isRabcUsers &&
                            e?.status?.toLowerCase() === 'active' && (
                              <Box
                                sx={{
                                  pointer: 'cursor',
                                }}
                              >
                                {/* <LoadingButton
                                  sx={{ minWidth: '16px', px: 0 }}
                                  onClick={() =>
                                    !e?.flag
                                      ? handleExpand(i, true)
                                      : handleExpand(i, false)
                                  }
                                >
                                  {e?.flag ? (
                                    <ICONS.ARROW_UP
                                      color='primary'
                                      sx={{ fontSize: 18 }}
                                    />
                                  ) : (
                                    <ICONS.ARROW_DOWN
                                      color='primary'
                                      sx={{ fontSize: 18 }}
                                    />
                                  )}
                                </LoadingButton> */}
                                {!e?.flag && (
                                  <LoadingButton
                                    sx={{ minWidth: '16px', ml: 1, px: 0 }}
                                    onClick={() => handleExpand(i, true)}
                                  >
                                    <ICONS.EDIT
                                      color='primary'
                                      sx={{ fontSize: 16 }}
                                    />
                                  </LoadingButton>
                                )}
                                {e?.flag && userData?.email !== e?.email && (
                                  <LoadingButton
                                    sx={{ minWidth: '18px', px: 0 }}
                                    loading={
                                      isDeleteLoading && selectedId === e?._id
                                    }
                                    disabled={
                                      isDeleteLoading && selectedId === e?._id
                                    }
                                  >
                                    {!isDeleteLoading &&
                                      selectedId !== e?._id && (
                                        <ICONS.DELETE_OUTLINED
                                          color='error'
                                          sx={{ fontSize: 20 }}
                                          onClick={() =>
                                            handleDeleteInvite(e?._id)
                                          }
                                        />
                                      )}
                                  </LoadingButton>
                                )}
                              </Box>
                            )}
                        </ListItem>
                      </Grid>
                    </Grid>
                    <ListItem>
                      <ICONS.EMAIL color='primary' fontSize='16px' />
                      <ListItemText primary={e?.email} sx={{ ml: 2 }} />
                    </ListItem>

                    <ListItem>
                      <ICONS.ACCOUNTS_CIRCLE color='primary' fontSize='16px' />
                      {e?.phone && (
                        <ListItemText
                          primary={'+' + e?.country_code + ' ' + e?.phone}
                          sx={{ ml: 2 }}
                        />
                      )}
                    </ListItem>

                    <ListItem>
                      <ICONS.ARTICLE color='primary' fontSize='16px' />
                      <ListItemText primary={e?.designation} sx={{ ml: 2 }} />
                    </ListItem>
                  </List>
                  {e?.status?.toLowerCase() !== 'active' && (
                    <Grid
                      container
                      spacing={2}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      {!isRabcUsers && (
                        <Box
                          sx={{
                            display: 'flex',
                            width: 1,
                            gap: 2,
                            pl: 4,
                            mb: 4,
                          }}
                        >
                          <Grid item xs={6}>
                            <LoadingButton
                              loading={isDeleteLoading && selectedId === e?._id}
                              disabled={
                                isDeleteLoading && selectedId === e?._id
                              }
                              fullWidth
                              variant='outlined'
                              sx={{ textTransform: 'none' }}
                              onClick={() => handleDeleteInvite(e?._id)}
                            >
                              {' '}
                              Delete Request{' '}
                            </LoadingButton>
                          </Grid>
                          <Grid item xs={6}>
                            <LoadingButton
                              loading={isResendLoading && selectedId === e?._id}
                              disabled={
                                isResendLoading && selectedId === e?._id
                              }
                              fullWidth
                              variant='contained'
                              sx={{ textTransform: 'none' }}
                              onClick={() => handleResendInvite(e?._id)}
                            >
                              Resend Invite
                            </LoadingButton>
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                  )}
                </Grid>
                {e?.flag && (
                  <MemberCard
                    contriesList={contriesList}
                    memberData={e}
                    handleExpand={handleExpand}
                    index={i}
                  />
                )}
              </Card>
            ))}
            {/* {!isMemberDetails && ( */}
            <Grid item xs={12} mt={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 2,
                  justifyContent: 'space-between',
                }}
              >
                {/* <Button
                  variant='outlined'
                  // onClick={formik.handleSubmit}
                  onClick={() => next(1)}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button> */}
                <Button
                  variant='contained'
                  fullWidth
                  onClick={() => navigate('/')}
                  sx={{
                    mr: 1,
                    textTransform: 'none',
                  }}
                >
                  Go To Dashboard
                </Button>
              </Box>
            </Grid>
            {/* )} */}
          </Card>
        </FormikProvider>
      </React.Fragment>
    </div>
  );
}

export default MemberDetails;
export const BlockInvalidChar = (e) =>
  ['e', 'E', '+', '-'].includes(e?.key) && e?.preventDefault();
