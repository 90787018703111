import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ICONS } from '../../../../../../constants/Icons';
import { useViewport } from '../../../../../../hooks';
import { handleBusinessDetails } from '../../../../../../redux/Reducer/OnBoard';
import { getUsersData } from '../../../../../../redux/Reducer/Profile';
import { toggleSnackBar } from '../../../../../../redux/Reducer/Utils';
import {
  getCountries,
  getDropDownDataByType,
  getIndustryTypeEnum,
  getIndustryTypeEnumSingapore,
} from '../../../../../../services/Auth';
import { updateBusinessOnBoard } from '../../../../../../services/Sfe';
import { businessDetailsValidation } from '../../../../../../utils/ValidationSchema/onBoard';
import MultiTreeSelect from '../../../../../Containers/MultiTree';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LoadingButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '../../../../../Containers/index';
import LogoutModal from '../../../../Auth/Components/Logout';
import AccountVerification from './AccountVericationDrawer';
// import MultiTreeSelect from '../../../../Containers/MultiTree';

const useStyles = makeStyles({
  root: {},
});

const steps = ['Business Details', 'Contact Details', 'Team Members'];
export const sortByProperty = (property) => {
  return function (a, b) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;

    return 0;
  };
};
function BusinessDetails({ next }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const [industrySingaporeTree, setIndustrySingaporeTree] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isBusinessEdit, setIsBusinessEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [industryMultiTree, setIndustryMultiTree] = useState([]);
  const [contriesList, setContriesList] = useState([]);
  const [entityType, setEntityType] = useState([]);
  const [coi, setCoi] = useState('');
  const [logoutOpen, setLogoutOpen] = useState(false);
  const { businessDetails, contactDetails } = useSelector(
    (state) => state?.onboard
  );
  const { isRabcUsers } = useSelector((state) => state?.auth);
  // console.log('Business', businessDetails);
  const { userData, teamMembers, isTeamLoading, isLoadingProfile } =
    useSelector((state) => state.user);
  const toggleLogoutModal = (val) => {
    // setIsOpen()
    setLogoutOpen(val);
  };
  const getEntityType = async (type) => {
    try {
      const res = await getDropDownDataByType(type);
      setEntityType(res.data.data);
    } catch (err) {
      // dispatch(
      //   snackbarsData({
      //     snackbarIsOpen: true,
      //     snackbarType: 'error',
      //     snackbarMessage: err.message,
      //   })
      // );
    }
  };
  const getIndustryType = async () => {
    try {
      const res = await getIndustryTypeEnum();
      if (res) {
        setIndustryMultiTree(res?.data?.data);
      }
    } catch (e) {}
  };
  const getIndustryTypeSingapore = async () => {
    try {
      const res = await getIndustryTypeEnumSingapore();
      if (res) {
        setIndustrySingaporeTree(res?.data?.data);
      }
    } catch (e) {}
  };
  const getCountriesList = async () => {
    var res = await getCountries();
    if (res) {
      setContriesList(res?.data?.data?.countries);
    }
  };
  const toggleDrawer = (e, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setIsOpen(!isOpen);
  };
  const formik = useFormik({
    initialValues: {
      entityType: '',
      companyName: '',
      registration: '',
      taxId: '',
      url: '',
      // ghanapost_gps: '',
      primaryIndustry: null,
    },
    validationSchema: businessDetailsValidation,
    onSubmit: async (values) => {
      dispatch(handleBusinessDetails(values));

      const params = {
        corporate_name: values.companyName,
        country_of_incorporation: values.registration,
        entity_type: values.entityType,
        tax_id: values.taxId,
        website: values.url,
        primary_industry: values.primaryIndustry,
      };

      try {
        setIsLoading(true);
        const res = await updateBusinessOnBoard(params);
        if (!businessDetails?.companyName) {
          next(1);
        } else {
          if (!contactDetails?.addressLineOne) {
            next(1);
          }
          dispatch(
            toggleSnackBar({
              isOpen: true,
              type: 'success',
              message: res?.data?.message,
            })
          );
        }
        dispatch(getUsersData());
      } catch (e) {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: e,
          })
        );
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    // if (false) {
    // if (userData?.platformDetail?.country_of_incorporation === 'Singapore') {
    // getEntityType('SingaporeEntityType');
    // } else {

    getCountriesList();
    // getEntityType('EntityType');
    if (userData?.platformDetail?.country_of_incorporation === 'Singapore') {
      getEntityType('SingaporeEntityType');
      getIndustryTypeSingapore();
    } else {
      getEntityType('EntityType');
      getIndustryType();
    }
    // }
  }, []);

  useEffect(() => {
    // console.log('Business', businessDetails);
    if (Object.keys(businessDetails).length !== 0) {
      // setIsBusinessEdit(true);
      if (Object.keys(contactDetails).length !== 0) {
        setIsBusinessEdit(true);
      }
      formik.setFieldValue('entityType', businessDetails?.entityType);
      formik.setFieldValue('companyName', businessDetails?.companyName);
      formik.setFieldValue('registration', businessDetails?.registration);
      formik.setFieldValue('taxId', businessDetails?.taxId);
      formik.setFieldValue('url', businessDetails?.url);
      formik.setFieldValue('primaryIndustry', businessDetails?.primaryIndustry);
    }
  }, [businessDetails]);

  useEffect(() => {
    // console.log(userData);
    if (userData?.platformDetail?.country_of_incorporation === 'Singapore') {
      setCoi('Singapore');
      getEntityType('SingaporeEntityType');
    } else {
      setCoi('');
      getEntityType('EntityType');
    }
  }, [userData]);

  useEffect(() => {
    if (coi === 'Singapore') {
      getIndustryTypeSingapore();
    } else {
      getIndustryType();
    }
  }, [coi]);

  const handleCapcha = async (e) => {
    e?.preventDefault();
    if (isBusinessEdit) {
      next(1);
      return;
    }
    const err = await formik?.validateForm();
    // console.log(err);
    if (Object.keys(err).length === 0) {
      // setShowRecapcha(true);
      formik.handleSubmit();
    } else {
      Object.keys(err).forEach((k) => {
        formik.setFieldTouched(k, true);
      });
    }
  };
  // if (isLoadingProfile) {
  //   return (
  //     <Card
  //       sx={{
  //         p: 8,
  //         mt: 8,
  //       }}
  //     >
  //       <Box sx={{ display: 'flex', justifyContent: 'center' }}>
  //         <CircularProgress />
  //       </Box>
  //     </Card>
  //   );
  // }
  return (
    <div>
      <React.Fragment>
        <Card
          sx={{
            p: 8,
            mt: 4,
          }}
        >
          <Grid item xs={12} md={12} lg={12} id='top'>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box mb={2}>
                {' '}
                <Typography
                  variant='h5'
                  component='h3'
                  sx={{ fontWeight: 700, py: 2 }}
                >
                  {steps[0]}
                </Typography>
                <Typography
                  variant='p'
                  component='p'
                  sx={{ fontSize: 12, mb: 2, color: 'grey', fontWeight: 600 }}
                >
                  Please provide required information for verification.
                </Typography>
              </Box>

              {businessDetails.companyName &&
                contactDetails?.addressLineOne &&
                !isRabcUsers && (
                  <Box>
                    {!isLoadingProfile && (
                      <Tooltip
                        title={isBusinessEdit ? 'Edit' : 'Read-only'}
                        arrow
                      >
                        <IconButton
                          onClick={() => {
                            setIsBusinessEdit(!isBusinessEdit);
                          }}
                        >
                          {!isBusinessEdit ? (
                            <ICONS.VISIBILITY />
                          ) : (
                            <ICONS.EDIT />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                )}
            </Box>
          </Grid>
          <Divider sx={{ mb: 4 }} />
          {!isLoadingProfile ? (
            <>
              {' '}
              <TextField
                inputProps={{ 'data-testid': 'signup-corporatename' }}
                fullWidth
                required
                sx={{ my: 2 }}
                label='Company Name'
                name='companyName'
                variant={isBusinessEdit ? 'standard' : 'outlined'}
                InputProps={{
                  readOnly: isBusinessEdit,
                  disableUnderline: isBusinessEdit,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                freeSolo={isBusinessEdit}
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                value={formik.values.companyName
                  .trimStart()
                  .replace(/\s\s+/g, '')
                  .replace(/\p{Emoji_Presentation}/gu, '')}
                onChange={(e) => formik.handleChange(e)}
                helperText={
                  formik.touched.companyName &&
                  formik.errors.companyName &&
                  t(formik.errors.companyName)
                }
              />
              <Grid item lg={12} xl={12} xs={12} md={12} sm={12}>
                <Autocomplete
                  name='registration'
                  label='Country/Place of Incorporation/Registration *'
                  sx={{ my: 2 }}
                  fullWidth
                  value={formik.values.registration}
                  freeSolo={isBusinessEdit}
                  readOnly={isBusinessEdit}
                  onChange={(event, item) => {
                    formik.setFieldValue('registration', item?.label);
                    if (item?.label === 'Singapore') {
                      getEntityType('SingaporeEntityType');
                      setCoi('Singapore');
                      formik.setFieldValue('entityType', '');
                      formik.setFieldValue('primaryIndustry', null);
                      // getIndustryTypeSingapore();
                    } else if (
                      (item?.label !== 'Singapore' &&
                        userData?.platformDetail?.country_of_incorporation ===
                          'Singapore') ||
                      coi === 'Singapore'
                    ) {
                      formik.setFieldValue('entityType', '');
                      formik.setFieldValue('primaryIndustry', null);
                      setCoi('');

                      getEntityType('EntityType');
                    }
                  }}
                  options={contriesList
                    ?.sort(sortByProperty('name'))
                    ?.map((item) => ({
                      label: item.name,
                      value: item.name,
                    }))}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Country/Place of Incorporation/Registration *'}
                      variant={isBusinessEdit ? 'standard' : 'outlined'}
                      error={
                        formik.touched.registration &&
                        Boolean(formik.errors.registration)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        readOnly: isBusinessEdit,
                        disableUnderline: isBusinessEdit,
                      }}
                      helperText={
                        formik.touched.registration &&
                        formik.errors.registration &&
                        t(formik.errors.registration)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid>
                <FormControl fullWidth className='form-item'>
                  <FormControl fullWidth className='form-item'>
                    <Autocomplete
                      disableClearable
                      name='entityType'
                      sx={{ my: 2 }}
                      options={entityType.map((item) => item?.name)}
                      value={
                        !isBusinessEdit
                          ? formik.values.entityType
                              ?.trimStart()
                              .replace(/\s\s+/g, '')
                          : formik.values.entityType
                              ?.trimStart()
                              .replace(/\s\s+/g, '')
                          ? formik.values.entityType
                              ?.trimStart()
                              .replace(/\s\s+/g, '')
                          : 'N/A'
                      }
                      onChange={(e, item) => {
                        formik.setFieldValue('entityType', item);
                      }}
                      readOnly={isBusinessEdit}
                      freeSolo={isBusinessEdit}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t('Type_Of_Entity')} *`}
                          error={
                            formik.touched.entityType &&
                            formik.errors.entityType
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant={isBusinessEdit ? 'standard' : 'outlined'}
                          helperText={
                            formik.touched.entityType &&
                            formik.errors.entityType &&
                            t(formik.errors.entityType)
                          }
                          InputProps={{
                            ...params.InputProps,
                            readOnly: isBusinessEdit,
                            disableUnderline: isBusinessEdit,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </FormControl>
              </Grid>
              <Grid item lg={12} xl={12} xs={12} md={12} sm={12}>
                <TextField
                  inputProps={{ 'data-testid': 'signup-taxid' }}
                  required
                  sx={{ my: 2 }}
                  label='Tax Identification Number '
                  fullWidth
                  name='taxId'
                  variant={isBusinessEdit ? 'standard' : 'outlined'}
                  InputProps={{
                    readOnly: isBusinessEdit,
                    disableUnderline: isBusinessEdit,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  freeSolo={isBusinessEdit}
                  error={formik.touched.taxId && Boolean(formik.errors.taxId)}
                  value={formik.values.taxId
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  onChange={(e) => formik.handleChange(e)}
                  helperText={
                    formik.touched.taxId &&
                    formik.errors.taxId &&
                    t(formik.errors.taxId)
                  }
                />
              </Grid>
              <TextField
                inputProps={{ 'data-testid': 'signup-url' }}
                required
                fullWidth
                sx={{ my: 2, mb: 3 }}
                label='Company Website'
                name='url'
                variant={isBusinessEdit ? 'standard' : 'outlined'}
                InputProps={{
                  readOnly: isBusinessEdit,
                  disableUnderline: isBusinessEdit,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                freeSolo={isBusinessEdit}
                error={formik.touched.url && Boolean(formik.errors.url)}
                value={formik.values.url
                  .trimStart()
                  .replace(/\s\s+/g, '')
                  .replace(/\p{Emoji_Presentation}/gu, '')}
                onChange={(e) => formik.handleChange(e)}
                helperText={
                  formik.touched.url &&
                  formik.errors.url &&
                  t(formik.errors.url)
                }
              />
              {isBusinessEdit ? (
                <TextField
                  // required
                  fullWidth
                  sx={{ my: 2, mb: 3 }}
                  label={`${t('PrimaryIndustry')} *`}
                  name='primaryIndustry'
                  variant={isBusinessEdit ? 'standard' : 'outlined'}
                  InputProps={{
                    readOnly: isBusinessEdit,
                    disableUnderline: isBusinessEdit,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  freeSolo={isBusinessEdit}
                  // error={formik.touched.url && Boolean(formik.errors.url)}
                  value={formik.values.primaryIndustry?.name || ''}
                  // onChange={(e) => formik.handleChange(e)}
                  // helperText={
                  //   formik.touched.url && formik.errors.url && t(formik.errors.url)
                  // }
                />
              ) : coi === 'Singapore' ? (
                // ||
                // userData?.platformDetail?.country_of_incorporation ===
                //   'Singapore'

                <Grid item xs={12}>
                  <FormControl fullWidth className='form-item'>
                    <Autocomplete
                      disableClearable
                      name='primaryIndustry'
                      options={industrySingaporeTree?.map((item) => ({
                        id: item?._id,
                        code: item?.code,
                        name: item?.name,
                      }))}
                      getOptionLabel={(option) => option.name}
                      value={formik.values.primaryIndustry}
                      // value={
                      //   !isBusinessEdit
                      //     ? formik.values.primaryIndustry
                      //     : formik.values.primaryIndustry
                      //     ? formik.values.primaryIndustry
                      //     : 'N/A'
                      // }

                      onChange={(e, item) => {
                        if (item) {
                          formik.setFieldValue('primaryIndustry', item);
                        }
                      }}
                      readOnly={isBusinessEdit}
                      freeSolo={isBusinessEdit}
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t('PrimaryIndustry')} *`}
                          variant={isBusinessEdit ? 'standard' : 'outlined'}
                          // InputProps={{
                          //   ...params.InputProps,
                          //   readOnly: isEditMode,
                          //   disableUnderline: isEditMode,
                          // }}
                          error={
                            formik.touched.primaryIndustry &&
                            Boolean(formik.errors.primaryIndustry)
                          }
                          helperText={
                            formik.touched.primaryIndustry &&
                            formik.errors.primaryIndustry &&
                            t(formik.errors.primaryIndustry)
                          }
                          InputProps={{
                            ...params.InputProps,

                            readOnly: isBusinessEdit,
                            disableUnderline: isBusinessEdit,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </FormControl>

                  {/* {(formik.touched.primary_industry &&
                    formik.errors.primary_industry) ||
                  (formik.submitCount > 0 && formik.errors.primary_industry) ? (
                    <div className='custom-validation-error'>
                      {t(formik.errors.primary_industry)}
                    </div>
                  ) : null}
                  {isPrimaryAndSecondarySame ? (
                    <div className='custom-validation-error'>
                      {t(
                        'Primary_And_Secondary_Industry_Should_Not_Be_The_Same'
                      )}
                    </div>
                  ) : null} */}
                </Grid>
              ) : (
                <MultiTreeSelect
                  label={`${t('PrimaryIndustry')} *`}
                  name='primaryIndustry'
                  sx={{ my: 3 }}
                  variant={isBusinessEdit ? 'standard' : 'outlined'}
                  InputProps={{
                    readOnly: isBusinessEdit,
                    disableUnderline: isBusinessEdit,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  freeSolo={isBusinessEdit}
                  defaultIndustries={formik.values.primaryIndustry}
                  onChangeSelect={(val) => {
                    if (val) {
                      formik.setFieldValue('primaryIndustry', val?.value);
                    } else {
                      formik.setFieldValue('primaryIndustry', null);
                    }
                  }}
                  error={
                    formik.touched.primaryIndustry &&
                    Boolean(formik.errors.primaryIndustry)
                  }
                  helperText={
                    formik.touched.primaryIndustry &&
                    formik.errors.primaryIndustry &&
                    t(formik.errors.primaryIndustry)
                  }
                  industryMultiTree={industryMultiTree}
                />
              )}
              {true && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      pt: 2,
                      justifyContent:
                        !businessDetails.companyName ||
                        !contactDetails?.addressLineOne
                          ? 'space-between'
                          : 'flex-end',
                    }}
                  >
                    {(!businessDetails.companyName ||
                      !contactDetails?.addressLineOne) && (
                      <Button
                        variant='outlined'
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        sx={{ mr: 1 }}
                      >
                        Cancel
                      </Button>
                    )}
                    {!isBusinessEdit && (
                      <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        variant='contained'
                        // onClick={formik.handleSubmit}
                        onClick={(e) => handleCapcha(e)}
                        sx={{ mr: 1 }}
                      >
                        {!businessDetails.companyName ||
                        !contactDetails?.addressLineOne
                          ? 'Next'
                          : 'Update'}
                      </LoadingButton>
                    )}
                  </Box>
                </Grid>
              )}
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </Card>
        {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color='inherit'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                    Next
                  </Button> *
                  {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Typography
                        variant='caption'
                        sx={{ display: 'inline-block' }}
                      >
                        Step {activeStep + 1} already completed
                      </Typography>
                    ) : (
                      <Button onClick={handleComplete}>
                        {completedSteps() === totalSteps() - 1
                          ? 'Finish'
                          : 'Next'}
                      </Button>
                    ))}
                </Box> */}

        <AccountVerification
          isOpen={isOpen}
          handleClose={toggleDrawer}
          handleLogout={toggleLogoutModal}
        />
        <LogoutModal open={logoutOpen} handleClose={toggleLogoutModal} />
      </React.Fragment>
    </div>
  );
}

export default BusinessDetails;
