import { ICONS } from '../../../../../constants/Icons';
import { ROUTE } from '../../../../../constants/Routes';

export const menu = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    path: ROUTE.ROOT,
    content: <ICONS.DASHBOARD_ICON />,
  },
  {
    title: 'Education Analytics',
    key: 'sfe',
    path: ROUTE.SFE,
    content: <ICONS.SCHOOL />,
  },
  {
    title: 'Financing Analytics',
    key: 'finAnalytics',
    path: ROUTE.FINANCING_ANALYTICS,
    content: <ICONS.PAYMENTS />,
  },
  {
    title: 'Data Validation',
    key: 'data_validation',
    path: ROUTE.DATA_VALIDATION,
    content: <ICONS.DATA_VALIDATION />,
  },
  {
    title: 'Financing',
    key: 'financing',
    path: ROUTE.FINANCING_REQUEST,
    content: <ICONS.FINANCING />,
  },
  {
    title: 'Impact Metrics',
    key: 'imp',
    path: ROUTE.IMPACT_METRICS,
    content: <ICONS.IMACT_ICON />,
  },

  {
    title: 'Dev Dashboard',
    key: 'dev',
    path: ROUTE.DEV_DASHBOARD,
    content: <ICONS.DEV_DASHBOARD />,
  },
  // {
  //   title: 'My Profile',
  //   key: 'profile',
  //   path: ROUTE.ONBOARD,
  //   content: <ICONS.PROFILE />,
  // },
  {
    title: 'My Profile',
    key: 'myprofile',
    path: ROUTE.MY_PROFILE,
    content: <ICONS.PROFILE />,
  },
  {
    title: 'Invite Member',
    key: 'invite',
    path: ROUTE.INVITE,
    content: <ICONS.GROUP_ADD />,
  },
  {
    title: 'Settings',
    key: 'settings',
    path: ROUTE.SETTINGS,
    content: <ICONS.SETTINGS />,
  },
  {
    title: 'Logout',
    key: 'logout',
    path: ROUTE.LOGOUT,
    content: <ICONS.LOGOUT_ICON />,
  },
];
