import { Tab, Tabs } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useViewport } from '../../../../../../hooks';
import {
  handleBusinessDetails,
  handleContactDetails,
} from '../../../../../../redux/Reducer/OnBoard';
import { getTeam, getUsersData } from '../../../../../../redux/Reducer/Profile';
import {
  getCountries,
  getDropDownDataByType,
  getIndustryTypeEnum,
} from '../../../../../../services/Auth';
import {
  Box,
  Container,
  Grid,
  SnackBar,
  Step,
  StepButton,
  Stepper,
  Typography,
  makeStyles,
} from '../../../../../Containers/index';
import BusinessDetails from './BusinessDetails';
import ContactDetails from './ContactDetails';
import MemberDetails from './MemberDetails';

const useStyles = makeStyles({
  root: {
    '& .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: '#17236E',
    },
    '& .MuiBox-root.caution img': {
      width: '100px',
      height: '100px',
    },
    '& .Mui-TableHeadCell-Content-Wrapper.MuiBox-root': {
      color: '#ffff',
      fontWeight: 400,
    },

    '& .MuiTablePagination-actions .Mui-disabled.MuiButtonBase-root.MuiIconButton-root':
      {
        color: '#808080',
      },
    '& .MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root': {
      color: 'black',
    },

    '& a': {
      textDecoration: 'none',
      color: 'black',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
});

const steps = ['Business Details', 'Contact Details', 'Team Members'];
const sortByProperty = (property) => {
  return function (a, b) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;

    return 0;
  };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function OnBoardScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  const [isLoading, setIsLoading] = useState(false);
  const [isBusinessEdit, setIsBusinessEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [completed, setCompleted] = useState({});
  const [industryMultiTree, setIndustryMultiTree] = useState([]);
  const [contriesList, setContriesList] = useState([]);
  const [entityType, setEntityType] = useState([]);
  const { activeStepper, maxStepper } = useSelector((state) => state?.auth);
  const {
    userData,
    teamMembers,
    isTeamLoading,
    isLoadingProfile,
    navToAddUserTab,
  } = useSelector((state) => state.user);

  const [activeStep, setActiveStep] = useState(0);
  const [value, setValue] = useState(0);
  const { businessDetails, contactDetails } = useSelector(
    (state) => state?.onboard
  );

  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };
  const getEntityType = async (type) => {
    try {
      const res = await getDropDownDataByType(type);
      setEntityType(res.data.data);
    } catch (err) {}
  };
  const getIndustryType = async () => {
    try {
      const res = await getIndustryTypeEnum();
      if (res) {
        setIndustryMultiTree(res?.data?.data);
      }
    } catch (e) {}
  };
  const getCountriesList = async () => {
    var res = await getCountries();
    if (res) {
      setContriesList(res?.data?.data?.countries);
    }
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      designation: '',
      entity_type: '',
      email: '',
      code: '',
      phone: '',
      companyName: '',
      registration: '',
      taxId: '',
      password: '',
      url: '',
      confirmPassword: '',
      acceptTermsOfUse: false,
      acceptTermsAndConditions: false,
      acceptPrivacyPolicy: false,
      acceptCreateAccount: false,
      address: '',
      addressLineOne: '',
      addressLineTwo: '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
      latitude: '',
      longitude: '',
      ghanapost_gps: '',
      primaryIndustry: null,
    },

    onSubmit: async (values) => {},
  });
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = (step) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setActiveStep(step);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = (step) => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  useEffect(() => {
    dispatch(getUsersData());
    dispatch(getTeam());

    getIndustryType();
    getCountriesList();
    getEntityType('EntityType');
  }, []);

  useEffect(() => {
    if (
      userData?.platformDetail &&
      userData?.platformDetail?.company_info?.corporate_name !== ''
    ) {
      const businessDetails = {
        entityType: userData?.platformDetail?.entity_type,
        companyName: userData?.platformDetail?.company_info?.corporate_name,
        registration: userData?.platformDetail?.country_of_incorporation,
        taxId: userData?.platformDetail?.company_info?.tax_id,
        url: userData?.platformDetail?.website,
        primaryIndustry: userData?.platformDetail?.primary_industry,
      };
      dispatch(handleBusinessDetails(businessDetails));
    }
    if (
      userData?.platformDetail &&
      userData?.platformDetail?.company_info?.address?.address_line_one !== ''
    ) {
      const contactDetails = {
        addressLineOne:
          userData?.platformDetail?.company_info?.address?.address_line_one,
        addressLineTwo:
          userData?.platformDetail?.company_info?.address?.address_line_two,
        country: userData?.platformDetail?.company_info?.address?.country,
        state: userData?.platformDetail?.company_info?.address?.state,
        city: userData?.platformDetail?.company_info?.address?.city,
        postalCode:
          userData?.platformDetail?.company_info?.address?.country !== 'Ghana'
            ? userData?.platformDetail?.company_info?.address?.postal_code
            : userData?.platformDetail?.company_info?.address?.ghanapost_gps,
      };
      dispatch(handleContactDetails(contactDetails));
    }
  }, [userData]);

  useEffect(() => {
    if (navToAddUserTab) {
      setActiveStep(2);
    }
  }, [navToAddUserTab]);
  return (
    <Grid container className={classes.root}>
      <SnackBar />
      <Grid container spacing={2}>
        <Container maxWidth='md'>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Box sx={{ width: '100%', px: 24 }}>
              {businessDetails.companyName && contactDetails?.addressLineOne ? (
                <>
                  <Tabs
                    value={activeStep}
                    onChange={handleChange}
                    aria-label='simple tabs example'
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    {steps.map((label, index) => (
                      <Tab label={label} key={index} />
                    ))}
                  </Tabs>

                  <TabPanel value={activeStep} index={0}>
                    <BusinessDetails next={handleComplete} />
                  </TabPanel>
                  <TabPanel value={activeStep} index={1}>
                    <ContactDetails
                      next={handleComplete}
                      back={handleComplete}
                      contriesList={contriesList}
                    />
                  </TabPanel>
                  <TabPanel value={activeStep} index={2}>
                    <MemberDetails
                      next={handleComplete}
                      back={handleComplete}
                    />
                  </TabPanel>
                </>
              ) : (
                <>
                  <Stepper alternativeLabel activeStep={activeStep}>
                    {steps.map((label, index) => (
                      <Step
                        key={label}
                        completed={completed[index]}
                        // onClick={() => handleComplete(index)}
                      >
                        {/* <StepButton color='inherit' onClick={handleStep(index)}> */}
                        <StepButton
                          color='inherit'
                          onClick={() => handleComplete(index)}
                        >
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    <React.Fragment>
                      {activeStep === 0 && (
                        <BusinessDetails next={handleComplete} />
                      )}
                      {activeStep === 1 && (
                        <ContactDetails
                          next={handleComplete}
                          back={handleComplete}
                          contriesList={contriesList}
                        />
                      )}
                      {activeStep === 2 && (
                        <MemberDetails
                          next={handleComplete}
                          back={handleComplete}
                        />
                      )}
                    </React.Fragment>
                  </div>
                </>
              )}
            </Box>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default OnBoardScreen;
