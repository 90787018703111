import React from 'react';
import {
  Box,
  Card,
  Container,
  makeStyles,
  Toolbar
} from '../../../../Containers/index';
import DataValidation from './Components/DataValidation';
const useStyles = makeStyles({
  root: {
    '& img': {
      width: '100%',
      maxWidth: '300px',
      height: 'auto'
    }
  }
});
function Index() {
  const classes = useStyles();

  return (
    <Box sx={{ display: 'flex' }} className={classes.root}>
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Toolbar />
        <Container maxWidth='xl' sx={{ mt: 3, mb: 4 }}>
          <Card>
            <DataValidation />
          </Card>
        </Container>
      </Box>
    </Box>
  );
}

export default Index;
