import { apiGet, apiPut } from '../../utils/Axios/axios';
import { AUTH_SERVER_URL } from '../Env';

const PROFILE_URL = AUTH_SERVER_URL + '/profile';
const SET_PASS_URL = PROFILE_URL + '/change_password';

export const getProfileDetail = () => {
  return apiGet(PROFILE_URL);
};

export const setPasswordNew = (apiData) => {
  return apiPut(SET_PASS_URL, apiData);
};
