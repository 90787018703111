import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMAGES } from '../../../../../constants/ImageConstants';
import {
  Box,
  Link,
  makeStyles,
  Typography
} from '../../../../Containers/index';

const useStyles = makeStyles({
  root: {
    '& .MuiLink-root': {
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
});
const Poster = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      data-testid='poster-component'
      sx={{
        display: { xs: 'none', sm: 'flex', md: 'flex' },
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${IMAGES.LOGIN_BG})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: { xs: '15vh', sm: '20vh', md: '100vh' }
      }}
    >
      <Box
        sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
        justifyContent='center'
        alignItems='left'
        flexDirection='column'
      >
        <Typography
          variant='h2'
          component='p'
          sx={{ color: 'white', fontWeight: 700 }}
        >
          {t('Poster_title')}
        </Typography>
        <Typography
          // variant='h5'
          // component='p'
          variant='h2'
          component='p'
          sx={{ color: 'white', fontWeight: 700 }}
          // sx={{ color: 'white', fontWeight: 400, justifyContent: 'center' }}
          // px={48}
          // py={28}
        >
          {t('Poster_sub_title')}
        </Typography>
        <Typography
          alignItems='flex-end'
          justifyContent='center'
          variant='p'
          component='p'
          sx={{
            color: 'white',
            fontWeight: 400,
            pb: 8,
            position: 'fixed',
            bottom: 0
          }}
        >
          {'@ ' + new Date().getFullYear() + t('Copy_right')}
          <Link
            sx={{
              textDecoration: 'none',
              color: 'secondary.light',
              fontWeight: 500
            }}
            title='terms-and-conditions'
            href='https://proxtera.com/terms-and-conditions/'
            target='_blank'
            rel='noreferrer noopener'
            aria-hidden='true'
          >
            {t('Terms_and_conditions')}
          </Link>
          {' & '}
          <Link
            sx={{
              textDecoration: 'none',
              color: 'secondary.light',
              fontWeight: 500
            }}
            title='privacy-policy'
            href='https://proxtera.com/privacy-policy/'
            target='_blank'
            rel='noreferrer noopener'
            aria-hidden='true'
          >
            {t('Privacy_policy_poster')}
          </Link>{' '}
        </Typography>
      </Box>
    </Box>
  );
};

export default Poster;
