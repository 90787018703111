import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businessDetails: [],
  contactDetails: [],
  teamMemberDetails: [],

  errorMessage: ''
};

let onBoardSlice = createSlice({
  name: 'onboad',
  initialState: initialState,
  reducers: {
    handleBusinessDetails: function (state, action) {
      state.businessDetails = action.payload;
    },
    handleContactDetails: function (state, action) {
      state.contactDetails = action.payload;
    }
  },
  extraReducers: {}
});
export const { handleBusinessDetails, handleContactDetails } =
  onBoardSlice.actions;

export default onBoardSlice.reducer;
