import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ICONS } from '../../../../../constants/Icons';
import { useViewport } from '../../../../../hooks';
import { getUsersData } from '../../../../../redux/Reducer/Profile';
import { toggleSnackBar } from '../../../../../redux/Reducer/Utils';
import {
  getCountries,
  getRoles,
  updateUserProfile,
} from '../../../../../services/Auth';
import { profileValidation } from '../../../../../utils/ValidationSchema/onBoard';
import {
  Autocomplete,
  Box,
  Card,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  LoadingButton,
  SnackBar,
  TextField,
  Typography,
  makeStyles,
} from '../../../../Containers/index';
// import MultiTreeSelect from '../../../../Containers/MultiTree';

const useStyles = makeStyles({
  root: {
    '& .MuiInput-input.Mui-disabled': {
      '-webkit-text-fill-color': 'black',
    },
  },
});

function ProfileCard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  // const [contriesList, setContriesList] = useState([]);
  const [showAddCard, setShowAddCard] = useState(false);
  const [contriesList, setContriesList] = useState([]);
  const [roles, setRoles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isMemberDetails, setIsMemberDetails] = useState(false);
  const { userData, isLoadingProfile } = useSelector((state) => state.user);
  const [isEdit, setIsEdit] = useState(true);

  const toggleAddCard = () => setShowAddCard(!showAddCard);
  const getCountriesList = async () => {
    var res = await getCountries();
    if (res) {
      const newData = res?.data?.data?.countries?.filter(
        (li, idx, self) =>
          self.map((itm) => itm.dialCode).indexOf(li.dialCode) === idx
      );

      setContriesList(newData);
    }
  };
  const getRolesForAccess = async () => {
    try {
      const result = await getRoles();
      if (result) {
        setRoles(result?.data?.data?.roles);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      designation: '',
      email: '',
      countryCode: '',
      phone: '',
      aboutMe: '',
      online_pages: [' '],
    },
    validationSchema: profileValidation,
    onSubmit: async (values, { resetForm }) => {
      const params = {
        last_name: values?.lastName,
        first_name: values?.firstName,
        phone_number: values?.phone,
        country_code: values?.countryCode,
        about_me: values?.aboutMe,
        designation: values?.designation,
        email: values?.email,
        source: 'dashboard',
      };
      let postData = { ...params };
      let online_pages_array = [];
      values?.online_pages?.filter((val) => {
        if (val) {
          online_pages_array?.push({
            url: val,
            name: val,
          });
        }
      });

      postData.online_pages = online_pages_array;

      try {
        setIsLoading(true);
        const response = await updateUserProfile(postData);
        // resetForm();
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'success',
            message: response?.data?.message,
          })
        );
        dispatch(getUsersData());
      } catch (error) {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: error,
          })
        );
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    getRolesForAccess();
    getCountriesList();
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length) {
      formik.setFieldValue('firstName', userData?.first_name || '');
      formik.setFieldValue('lastName', userData?.last_name || '');
      formik.setFieldValue('email', userData?.email);
      formik.setFieldValue('designation', userData?.designation || '');
      formik.setFieldValue('phone', userData?.phone || '');
      if (userData?.country_code) {
        formik.setFieldValue('countryCode', '+' + userData?.country_code || '');
      }
      formik.setFieldValue('aboutMe', userData?.about_me || '');
      formik.setFieldValue(
        'online_pages',
        userData?.online_pages && userData?.online_pages.length > 0
          ? userData?.online_pages.map((link) => link.url)
          : ['']
      );
    }
  }, [userData]);
  function findDuplicates(arr) {
    if (!arr) {
      return;
    }
    if (Array.isArray(arr)) {
      const filtered = arr.filter((item, index) => arr.indexOf(item) !== index);
      return [...new Set(filtered)];
    }
  }

  const duplicates = (arr) => {
    return findDuplicates(arr);
  };
  return (
    <Box
      component={Card}
      className={classes.root}
      sx={{
        my: 4,
        p: 4,
      }}
    >
      <FormikProvider value={formik}>
        <SnackBar />
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} lg={6} marginTop='10px'>
            <Typography
              variant='h5'
              component='h3'
              sx={{ fontWeight: 700, pr: 4, pl: 2, py: 4, fontSize: '24px' }}
            >
              {' '}
              Individual Profile
            </Typography>
          </Grid>
          {isEdit && (
            <Grid
              item
              xs={6}
              md={6}
              lg={6}
              marginTop='10px'
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton onClick={() => setIsEdit(false)}>
                <ICONS.EDIT sx={{ fontSize: '24px' }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12} mb={8}>
          <Divider />
        </Grid>
        <Container maxWidth='sm' sx={{ mb: 8 }}>
          {!isLoadingProfile ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl fullWidth>
                  <TextField
                    inputProps={{
                      'data-testid': 'signup-firstname',
                    }}
                    sx={{ my: 2 }}
                    label={t('First_name')}
                    required
                    fullWidth
                    name='firstName'
                    variant={isEdit ? 'standard' : 'outlined'}
                    InputProps={{
                      readOnly: isEdit,
                      disableUnderline: isEdit,
                    }}
                    freeSolo={isEdit}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    value={formik.values.firstName
                      .trimStart()
                      .replace(/\s\s+/g, '')
                      .replace(/\p{Emoji_Presentation}/gu, '')}
                    onChange={(e) => {
                      const regx = /^[a-zA-Z ]*$/;
                      if (e.target.value === '' || regx.test(e.target.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    helperText={
                      formik.touched.firstName &&
                      formik.errors.firstName &&
                      t(formik.errors.firstName)
                    }
                  />
                </FormControl>
                <TextField
                  inputProps={{
                    'data-testid': 'signup-lastname',
                  }}
                  sx={{ my: 2 }}
                  label={t('Last_name')}
                  required
                  fullWidth
                  name='lastName'
                  variant={isEdit ? 'standard' : 'outlined'}
                  InputProps={{
                    readOnly: isEdit,
                    disableUnderline: isEdit,
                  }}
                  freeSolo={isEdit}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  value={formik.values.lastName
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  onChange={(e) => {
                    const regx = /^[a-zA-Z ]*$/;
                    if (e.target.value === '' || regx.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  helperText={
                    formik.touched.lastName &&
                    formik.errors.lastName &&
                    t(formik.errors.lastName)
                  }
                />

                <Grid container spacing={2}>
                  <Grid item lg={12} xl={12} xs={12} md={12} sm={12}>
                    <TextField
                      inputProps={{ 'data-testid': 'signup-email' }}
                      fullWidth
                      required
                      disabled
                      sx={{ my: 2 }}
                      label={t('Email_Address')}
                      variant={isEdit ? 'standard' : 'outlined'}
                      InputProps={{
                        readOnly: isEdit,
                        disableUnderline: isEdit,
                      }}
                      freeSolo={isEdit}
                      name='email'
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      value={formik.values.email
                        .trimStart()
                        .replace(/\s\s+/g, '')
                        .replace(/\p{Emoji_Presentation}/gu, '')}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      helperText={
                        formik.touched.email &&
                        formik.errors.email &&
                        t(formik.errors.email)
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={4} xl={4} md={4} xs={12} sm={4}>
                    <Autocomplete
                      id='countryCode'
                      name={'countryCode'}
                      label={`${t('Code')} *`}
                      onBlur={() => {
                        formik.setFieldValue(
                          'countryCode',
                          formik.values?.countryCode?.trim()
                        );
                      }}
                      onInputChange={(event, value, reason) => {
                        if (reason === 'input') {
                          let matchedOption = contriesList?.find(
                            (option) => option?.dialCode === '+' + value
                          );
                          if (matchedOption) {
                            formik.setFieldValue(
                              'countryCode',
                              matchedOption?.dialCode
                              // matchedOption
                            );
                          } else {
                            formik.setFieldValue('countryCode', '');
                          }
                        }
                      }}
                      fullWidth
                      sx={{ my: 2 }}
                      value={formik.values?.countryCode || ''}
                      onChange={(event, item) => {
                        if (item !== null) {
                          formik.setFieldValue('countryCode', item?.label);
                        } else {
                          formik.setFieldValue('countryCode', '');
                        }
                      }}
                      error={
                        formik.touched.countryCode &&
                        Boolean(formik.errors.countryCode)
                      }
                      helperText={
                        formik.touched.countryCode && formik.errors.countryCode
                      }
                      options={contriesList
                        // ?.sort(sortByProperty('dialCode'))
                        ?.sort((a, b) => a?.dialCode - b?.dialCode)
                        ?.map((item) => ({
                          label: item?.dialCode,
                          value: item?.dialCode,
                        }))}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value
                      }
                      disabled={isEdit}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t('Code')} *`}
                          disabled={isEdit}
                          error={
                            formik.touched.countryCode &&
                            Boolean(formik.errors.countryCode)
                          }
                          helperText={
                            formik.touched.countryCode &&
                            formik.errors.countryCode
                              ? t(formik.errors.countryCode)
                              : ''
                          }
                          InputProps={{
                            readOnly: isEdit,
                            disableUnderline: isEdit,
                            ...params.InputProps,
                          }}
                          variant={isEdit ? 'standard' : 'outlined'}
                          freeSolo={isEdit}
                          value={formik.values?.countryCode || ''}
                        />
                      )}
                    />
                    {/* <Autocomplete
                      required
                      data-testid='countryCode'
                      sx={{ my: 2 }}
                      name={'countryCode'}
                      label={t('Code')}
                      fullWidth
                      disabled={isEdit}
                      value={formik.values.countryCode}
                      onChange={(event, item) => {
                        formik.setFieldValue('countryCode', item?.label);
                      }}
                      options={contriesList
                        ?.sort((a, b) => a?.dialCode - b?.dialCode)

                        ?.map((item) => ({
                          label: item.dialCode,
                          value: item.dialCode,
                        }))}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value
                      }
                      freeSolo={false}
                      onInputChange={(event, value, reason) => {
                        if (reason === 'input') {
                          let matchedOption = contriesList?.find(
                            (option) => option?.dialCode === '+' + value
                          );
                          if (matchedOption) {
                            formik.setFieldValue(
                              'countryCode',
                              matchedOption?.dialCode
                              // matchedOption
                            );
                          } else {
                            formik.setFieldValue('countryCode', '');
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={t('Code')}
                          error={
                            formik.touched.countryCode &&
                            Boolean(formik.errors.countryCode)
                          }
                          variant={isEdit ? 'standard' : 'outlined'}
                          freeSolo={isEdit}
                          helperText={
                            formik.touched.countryCode &&
                            formik.errors.countryCode &&
                            t(formik.errors.countryCode)
                          }
                          InputProps={{
                            readOnly: isEdit,
                            disableUnderline: isEdit,
                            ...params.InputProps,
                          }}
                          value={formik?.values?.countryCode}
                        />
                      )}
                    /> */}
                  </Grid>
                  <Grid item lg={8} xl={8} xs={12} md={8} sm={8}>
                    <TextField
                      // inputProps={{ 'data-testid': 'signup-phone' }}
                      required
                      sx={{ my: 2 }}
                      label={t('Mobile_number')}
                      fullWidth
                      disabled={isEdit}
                      name='phone'
                      // type='number'
                      // disabled
                      variant={isEdit ? 'standard' : 'outlined'}
                      InputProps={{
                        readOnly: isEdit,
                        disableUnderline: isEdit,
                      }}
                      freeSolo={isEdit}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      value={formik.values.phone.replace(/\s\s+/g, '')}
                      // .replace(/\s\s+/g, '')
                      // .replace(/\p{Emoji_Presentation}/gu, '')}
                      // onChange={(e) => formik.handleChange(e)}
                      onChange={(e) => {
                        const re = /^-?[0-9]\d*(\.\d+)?$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          formik.handleChange(e);
                        }
                      }}
                      inputProps={{
                        maxlength: 15,
                        minLength: 6,
                        'data-testid': 'signup-phone',
                      }}
                      helperText={
                        formik.touched.phone &&
                        formik.errors.phone &&
                        t(formik.errors.phone)
                      }
                    />
                  </Grid>
                </Grid>
                <TextField
                  inputProps={{ 'data-testid': 'signup-designation' }}
                  fullWidth
                  required
                  sx={{ my: 2 }}
                  label={t('Designation')}
                  name='designation'
                  variant={isEdit ? 'standard' : 'outlined'}
                  InputProps={{
                    readOnly: isEdit,
                    disableUnderline: isEdit,
                  }}
                  freeSolo={isEdit}
                  error={
                    formik.touched.designation &&
                    Boolean(formik.errors.designation)
                  }
                  value={formik.values.designation
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  onChange={(e) => formik.handleChange(e)}
                  // onChange={(e) => {
                  //   // const re1 = /^[0-9\b]+$/;
                  //   const re = /^([0-9]*[1-9][0-9]*)$/;

                  //   if (e.target.value === '' || re.test(e.target.value)) {
                  //     formik.handleChange(e);
                  //   }
                  // }}
                  helperText={
                    formik.touched.designation &&
                    formik.errors.designation &&
                    t(formik.errors.designation)
                  }
                />
                <TextField
                  inputProps={{ 'data-testid': 'signup-aboutMe' }}
                  fullWidth
                  // required
                  multiline
                  minRows={2}
                  sx={{ my: 2 }}
                  label={t('About Me')}
                  variant={isEdit ? 'standard' : 'outlined'}
                  InputProps={{
                    readOnly: isEdit,
                    disableUnderline: isEdit,
                  }}
                  freeSolo={isEdit}
                  name='aboutMe'
                  error={
                    formik.touched.aboutMe && Boolean(formik.errors.aboutMe)
                  }
                  value={formik.values.aboutMe
                    .trimStart()
                    .replace(/\s\s+/g, '')
                    .replace(/\p{Emoji_Presentation}/gu, '')}
                  onChange={(e) => formik.handleChange(e)}
                  helperText={
                    formik.touched.aboutMe &&
                    formik.errors.aboutMe &&
                    t(formik.errors.aboutMe)
                  }
                />
                <Grid
                  item
                  lg={12}
                  xl={12}
                  xs={12}
                  md={12}
                  sm={12}
                  sx={{ pl: 0, mt: 1 }}
                >
                  <FieldArray name='online_pages' key={'on'}>
                    {({ push, remove }) => (
                      <Fragment key={'on_key'}>
                        {formik.values.online_pages?.map((value, index) => {
                          return (
                            <>
                              <FormGroup className='form-item' key={index}>
                                <TextField
                                  fullWidth
                                  key={index}
                                  // type='number'
                                  // required
                                  variant={isEdit ? 'standard' : 'outlined'}
                                  freeSolo={isEdit}
                                  sx={{ my: 2 }}
                                  className={classes.textfieldClass}
                                  onKeyDown={BlockInvalidChar}
                                  label={`${t('Online Pages')}`}
                                  name={`online_pages[${index}]`}
                                  value={formik.values?.online_pages[index]
                                    .replace(/\s\s+/g, '')
                                    .replace(/\p{Emoji_Presentation}/gu, '')}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `online_pages[${index}]`,
                                      e.target?.value
                                    );
                                    // formik.handleChange(e);
                                  }}
                                  // InputLabelProps={{
                                  //   shrink:
                                  //     formik.values.online_pages[index] === ' '
                                  //       ? false
                                  //       : true,
                                  // }}
                                  InputProps={{
                                    readOnly: isEdit,
                                    disableUnderline: isEdit,
                                    endAdornment: !isEdit && (
                                      <Box>
                                        {index === 0 ? (
                                          <InputAdornment
                                            sx={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              let pushResult =
                                                formik?.values?.online_pages.filter(
                                                  (item) => item === ''
                                                ).length === 0;
                                              if (pushResult) {
                                                push('');
                                              }
                                            }}
                                            position='end'
                                          >
                                            <ICONS.ADD_CIRCLE color='primary' />
                                          </InputAdornment>
                                        ) : (
                                          <InputAdornment
                                            sx={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => remove(index)}
                                            position='end'
                                          >
                                            <ICONS.DELETE color='primary' />
                                          </InputAdornment>
                                        )}
                                      </Box>
                                    ),
                                    // readOnly: isMemberDetails,
                                    // disableUnderline: isMemberDetails,
                                  }}
                                  error={
                                    (formik.touched?.online_pages &&
                                      formik.touched?.online_pages[index] &&
                                      Boolean(
                                        formik.errors?.online_pages &&
                                          Array.isArray(
                                            formik.errors?.online_pages
                                          ) &&
                                          formik.errors?.online_pages[index] &&
                                          formik.errors?.online_pages[index]
                                            ?.length > 1
                                      )) ||
                                    (formik.errors?.online_pages &&
                                      Array.isArray(
                                        duplicates(formik.values?.online_pages)
                                      ) &&
                                      duplicates(
                                        formik.values?.online_pages
                                      )?.includes(
                                        formik?.values?.online_pages[index]
                                      ))
                                  }
                                />
                                {!formik.touched.online_pages &&
                                  !formik.errors.online_pages &&
                                  // formik.errors?.online_pages[0] === '' &&
                                  index === 0 && (
                                    <Box ml={2}>
                                      {!isEdit && (
                                        <FormHelperText>
                                          {t('Online_pages_helper_text')}
                                        </FormHelperText>
                                      )}
                                    </Box>
                                  )}

                                {formik.errors?.online_pages && (
                                  <Fragment>
                                    {formik.touched?.online_pages &&
                                      formik.errors?.online_pages &&
                                      Array.isArray(
                                        formik.errors?.online_pages
                                      ) &&
                                      formik.errors?.online_pages[index]
                                        ?.length > 1 &&
                                      formik.errors?.online_pages[index] && (
                                        <FormHelperText
                                          sx={{
                                            color: 'error.main',
                                            fontSize: '0.75rem',
                                            fontWeight: 400,
                                            marginTop: '-3px',
                                            marginLeft: '14px',
                                          }}
                                        >
                                          {t(
                                            formik?.errors?.online_pages || ''
                                          )}
                                        </FormHelperText>
                                      )}
                                  </Fragment>
                                )}
                                {formik.errors?.online_pages &&
                                  Array.isArray(
                                    duplicates(formik.values?.online_pages)
                                  ) &&
                                  duplicates(
                                    formik.values?.online_pages
                                  )?.includes(
                                    formik?.values?.online_pages[index]
                                  ) && (
                                    <FormHelperText
                                      sx={{
                                        color: 'error.main',
                                        fontSize: '0.75rem',
                                        fontWeight: 400,
                                        marginTop: '-3px',
                                        marginLeft: '14px',
                                      }}
                                    >
                                      {t('URL_need_te_be_unique')}
                                    </FormHelperText>
                                  )}
                              </FormGroup>
                            </>
                          );
                        })}
                      </Fragment>
                    )}
                  </FieldArray>
                </Grid>

                {!isEdit && (
                  <Grid container spacing={2} sx={{ mt: 8 }}>
                    <Grid item lg={6} xl={6} md={6} xs={12} sm={12}>
                      <LoadingButton
                        variant='outlined'
                        fullWidth
                        onClick={() => setIsEdit(true)}
                      >
                        Cancel
                      </LoadingButton>
                    </Grid>
                    <Grid item lg={6} xl={6} md={6} xs={12} sm={12}>
                      <LoadingButton
                        loading={isLoading}
                        variant='contained'
                        fullWidth
                        onClick={formik.handleSubmit}
                      >
                        Update
                      </LoadingButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '30vh',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Container>
      </FormikProvider>
    </Box>
  );
}

export default ProfileCard;
export const BlockInvalidChar = (e) =>
  ['e', 'E', '+', '-'].includes(e?.key) && e?.preventDefault();
