import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '../../../../../constants/Routes';
import { toggleSnackBar } from '../../../../../redux/Reducer/Utils';
import { forgetPassWordEmail } from '../../../../../services/Auth';
import TurnstileWidget from '../../../../../utils/Turnstile/TurnstileWidget';
import { ForgetPasswordValidationSchema } from '../../../../../utils/ValidationSchema';
import {
  Box,
  Grid,
  SnackBar,
  TextField,
  Typography,
} from '../../../../Containers/index';

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const { isVerified, reCapchaToken } = useSelector((state) => state?.auth);
  const [showRecapcha, setShowRecapcha] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: ForgetPasswordValidationSchema,
    onSubmit: async (values) => {
      const params = {
        email: values.email,
        source: 'dashboard',
        token: reCapchaToken,
      };
      setErrorMessage('');
      setIsLoading(true);

      try {
        const response = await forgetPassWordEmail(params);
        // navigate(ROUTE.ROOT);

        dispatch(
          toggleSnackBar({
            isOpen: true,
            message: response?.data?.message,
            type: 'success',
          })
        );
        setTimeout(() => {
          navigate(ROUTE.ROOT);
          dispatch(
            toggleSnackBar({
              isOpen: false,
              type: 'success',
              message: response?.data?.message,
            })
          );
        }, 500);
      } catch (error) {
        setErrorMessage(error);
        // dispatch(
        //   toggleSnackBar({
        //     isOpen: true,
        //     type: 'error',
        //     message: error,
        //   })
        //);
      } finally {
        setIsLoading(false);
        setShowRecapcha(false);
      }
    },
  });
  const handleCapcha = async (e) => {
    e?.preventDefault();

    const err = await formik?.validateForm();
    // console.log(err);
    if (Object.keys(err).length === 0) {
      setShowRecapcha(true);
    } else {
      Object.keys(err).forEach((k) => {
        formik.setFieldTouched(k, true);
      });
    }
  };
  useEffect(() => {
    if (reCapchaToken) {
      formik.handleSubmit();
    } else {
      setIsLoading(false);
    }
  }, [reCapchaToken]);
  useEffect(() => {
    setIsLoading(false);
    setTimeout(() => {
      setShowRecapcha(false);
    }, 5000);
  }, [isVerified]);
  return (
    <Box>
      <SnackBar />
      <Typography
        my={4}
        sx={{
          fontWeight: 900,
          fontSize: '24px',
          fontStyle: 'normal',
          color: 'primary.main',
        }}
      >
        {t('Forgot_password_title')}
      </Typography>
      <Typography
        my={4}
        sx={{ fontWeight: 400, fontSize: '16px', fontStyle: 'normal' }}
      >
        {t('Forgot_password_description')}
      </Typography>
      <TextField
        inputProps={{ 'data-testid': 'signin-email-or-name' }}
        fullWidth
        sx={{ my: 2 }}
        label={`${t('Email Address')} *`}
        name='email'
        error={formik.touched.email && Boolean(formik.errors.email)}
        value={formik.values.email
          .trimStart()
          .replace(/\s\s+/g, '')
          .replace(/\p{Emoji_Presentation}/gu, '')}
        onChange={(e) => {
          setErrorMessage('');

          formik.handleChange(e);
        }}
        helperText={
          formik.touched.email && formik.errors.email && t(formik.errors.email)
        }
      />

      {errorMessage && (
        <Typography>
          <Box
            sx={{
              color: 'error.main',
            }}
          >
            {errorMessage}
          </Box>
        </Typography>
      )}
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        fullWidth
        variant='contained'
        onClick={(e) => handleCapcha(e)}
        sx={{
          my: 2,
          textTransform: 'none',
          '& .MuiCircularProgress-root ': {
            color: '#ffff',
          },
        }}
      >
        {t('Submit')}
      </LoadingButton>
      {showRecapcha && (
        <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
          <TurnstileWidget />
        </Box>
      )}
      <Grid container display='flex' justifyContent='space-between' mt={2}>
        {!isLoading && (
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box
              color='primary.main'
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(ROUTE.ROOT);
              }}
            >
              {t('Back_to_login')}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ResetPassword;
