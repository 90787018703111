import { DataGrid } from '@mui/x-data-grid';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { ICONS } from '../../../../../../constants/Icons';
import { useViewport } from '../../../../../../hooks';
import { getPendingNotifications } from '../../../../../../redux/Reducer/Profile';
import { toggleSnackBar } from '../../../../../../redux/Reducer/Utils';
import { getValidationDetails } from '../../../../../../services/DataValidation';
import {
  getFinancingRequests,
  getRejectedReasons,
  sumbitFinanceRequest,
} from '../../../../../../services/Finance';
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LoadingButton,
  MaterialReactTable,
  MenuItem,
  Select,
  SnackBar,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '../../../../../Containers/index';

function CustomNoRowsOverlay() {
  return (
    <div>
      <Box
        sx={{
          mt: 12,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        No results found.
      </Box>
    </div>
  );
}
const useStyles = makeStyles({
  root: {
    '& .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: '#17236E',
    },
    '& li': {
      whiteSpace: 'normal',
    },
    '& .MuiBox-root.caution img': {
      width: '100px',
      height: '100px',
    },
    '& .Mui-TableHeadCell-Content-Wrapper.MuiBox-root': {
      color: '#ffff',
      fontWeight: 400,
    },
    // '& .MuiButtonBase-root.MuiIconButton-root': {
    //   color: '#ffff',
    // },
    '& .MuiTablePagination-actions .Mui-disabled.MuiButtonBase-root.MuiIconButton-root':
      {
        color: '#808080',
      },
    '& .MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root': {
      color: 'black',
    },
    // '& .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon':
    //   {
    //     color: '#ffff',
    //   },
    '& a': {
      textDecoration: 'none',
      color: 'black',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
  // certificate: {
  //   '& certificate a:hover': {
  //     textDecoration: 'underline',
  //   },
  // },
  alert: {
    '& .MuiPaper-root.MuiAlert-root svg': {
      display: 'none',
    },
    '& .MuiPaper-root.MuiAlert-root.success': {
      color: 'white',
      background: '#0A5A25',
    },
    '& .MuiPaper-root.MuiAlert-root.error': {
      color: 'white',
      background: '#CC0000',
    },
  },
});
const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix=''
    />
  );
});
const sourceList = [
  {
    id: 'GIFE',
    status: 'GIFE',
  },
  {
    id: 'RICE',
    status: 'RICE',
  },
  {
    id: 'invoice',
    status: 'Invoice',
  },
  {
    id: 'purchase_order',
    status: 'Purchase Order',
  },
  {
    id: 'working_capital',
    status: 'Working Capital',
  },
  {
    id: 'letters_of_credit',
    status: 'Letters Of Credit',
  },
];
function Finance() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSumbit, setIsLoadingSumbit] = useState(false);
  const [reason, setReason] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [pageCount, setPageCount] = useState(0);

  const [rowCountState, setRowCountState] = useState(pageCount || 0);
  const [validationStatus, setValidationStatus] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  // const [{ pageIndex, pageSize }, setPagination] = useState({
  //   pageIndex: 0,
  //   pageSize: 50,
  // });

  const [validationData, setValidationData] = useState([]);
  const [rejectedReasons, setRejectedReasons] = useState([]);
  const [tenureList, setTenureList] = useState([
    '1 Month',
    '2 Months',
    '3 Months',
    '4 Months',
    '5 Months',
    '6 Months',
    '7 Months',
    '8 Months',
    '9 Months',
    '10 Months',
    '11 Months',
    '12 Months',
  ]);
  const [tenure, setTenure] = useState(1);
  const [validationDetails, setValidationDetails] = useState(null);
  const [actionRequired, setActionrequired] = useState(false);
  const [alertType, setAlertType] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isClear, setIsClear] = useState(true);
  const [source, setSource] = useState('');

  const [validationDetailsLoading, setValidationDetailsLoading] =
    useState(false);
  // const [pageCount, setPageCount] = useState(0);
  const [id, setId] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [facilityType, setFacilityType] = useState('');
  const [loanTenure, setLoanTenure] = useState('');
  const { isDBG, isChannel, isBNR, isBDF } = useSelector(
    (state) => state?.auth
  );

  const [approvedAmount, setApprovedAmount] = useState(1);
  const handleSort = async (newSort) => {
    // console.log(newSort);
    if (newSort.length) {
      setSortField(newSort[0].field);
      setSortOrder(newSort[0].sort);
    } else {
      setSortField('');
      setSortOrder('');
    }
  };
  const getRequestDetails = async (id) => {
    try {
      setIsLoading(true);
      setValidationDetailsLoading(true);
      const result = await getValidationDetails(id, 'financing');

      if (result) {
        setAlertType(result?.data?.data);
        setLoanAmount(result?.data?.data?.amount);
        setFacilityType(result?.data?.data?.facility_type);
        setTenure(result?.data?.data?.preferred_tenure);
        setLoanTenure(result?.data?.data?.preferred_tenure);

        setValidationDetails(result?.data?.data?.data_validation_requests);
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      setValidationDetailsLoading(false);

      setIsLoading(false);
    }
  };
  const requestsColumns = [
    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'source',
      headerName: 'Type of Financing',
    },
    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'company_name',
      headerName: 'Company Name',
    },

    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'date_of_request',
      headerName: 'Date of Request',
      renderCell: ({ row }) =>
        `${new Date(row.date_of_request).toLocaleDateString('en-GB')}`,
    },

    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'amount',
      headerName: 'Loan Amount (GHS)',
      renderCell: ({ row }) =>
        `${parseFloat(row.amount || 0)
          .toFixed(2)
          ?.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
    },
    {
      flex: 0.1,
      minWidth: 200,
      sortable: true,
      field: 'approved_amount',
      headerName: 'Approved Amount (GHS)',
      renderCell: ({ row }) =>
        `${
          row.approved_amount === ''
            ? '-'
            : parseFloat(row.approved_amount)
                .toFixed(2)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }`,
    },

    {
      flex: 0.1,
      minWidth: 170,
      sortable: false,
      field: 'status',
      // enableSorting: false,

      headerName: 'Status ',
      renderCell: ({ row }) => {
        if (row.status === 'pending') {
          return (
            <Chip
              sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
              color='error'
              label={row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
            />
          );
        } else if (row.status === 'validated') {
          return (
            <Chip
              // color='success'
              // sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
              sx={{
                background: '#125688',
                color: 'white',
                minWidth: 17,
                width: 170,
                maxWidth: 170,
              }}
              label={row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
            />
          );
        } else if (row.status === 'approved') {
          return (
            <Chip
              sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
              color='success'
              // sx={{ background: '#125688', color: 'white' }}
              // label={row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
              label={'Loan Approved'}
            />
          );
        } else if (row.status === 'rejected') {
          return (
            <Chip
              color='error'
              sx={{ color: 'white', minWidth: 17, width: 170, maxWidth: 170 }}
              // label={row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
              label={'Loan Rejected'}
            />
          );
        } else if (row.status === 'under_review') {
          return (
            <Chip
              // color='success'
              sx={{
                background: 'grey',
                color: 'white',
                minWidth: 17,
                width: 170,
                maxWidth: 170,
              }}
              label={'Under Review'}
              // label={
              //   row.status?.charAt(0).toUpperCase() +
              //   row.status?.slice(1).replace('_', ' ')
              // }
            />
          );
        }
      },
    },
    {
      // accessorKey: 'status',
      flex: 0.1,
      minWidth: 170,
      headerName: 'Action',
      renderCell: ({ row }) => (
        <p
          onClick={() => {
            setId(row?._id);
            getRequestDetails(row?._id);
            dispatch(getPendingNotifications());
            if (row?.status === 'validated' || row?.status === 'under_review') {
              setActionrequired(true);
            } else {
              setActionrequired(false);
            }
          }}
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color:
              row?.status === 'validated' || row?.status === 'under_review'
                ? '#c32e31'
                : 'black',
          }}
        >
          {row?.status === 'validated' || row?.status === 'under_review'
            ? 'Action Required'
            : 'View Details'}
        </p>
      ),
    },
  ];
  const detailsColumns = [
    {
      accessorKey: 'data_field',
      header: 'Data Field',
      size: 300,
    },

    {
      accessorKey: 'sme_data',
      header: 'SME Data ',
      size: 100,
      Cell: ({ cell }) => {
        if (cell.row.original.field_name === 'sfe_certificates') {
          let urls = cell.row.original.sme_data.split(',');
          let newUrls = '';
          urls?.map((e) => {
            newUrls += `<a href=${e} target="_blank" >` + e + `</a> <br/>`;
          });
          return (
            <div
              className='certificate'
              dangerouslySetInnerHTML={{ __html: newUrls }}
            />
          );
          //return newUrls;
        } else {
          return `${
            typeof cell.row.original.sme_data === 'object'
              ? cell.row.original.sme_data?.name
              : cell.row.original.sme_data
          }`;
        }
      },
      // accessorFn: (row) =>
      //   `${
      //     typeof row?.sme_data === 'object' ? row.sme_data?.name : row?.sme_data
      //   }`,
    },

    {
      accessorKey: ' ',
      header: 'Is this information correct? ',
      Cell: ({ cell }) => {
        if (
          cell.row.original.field_name !== 'sfe_certificates' &&
          cell.row.original.field_name !== 'coupon_code' &&
          cell.row.original.field_name !== 'current_bank' &&
          cell.row.original.field_name !== 'gender' &&
          cell.row.original.field_name !== 'designation' &&
          cell.row.original.field_name !== 'facility_type' &&
          cell.row.original.field_name !== 'entity_type'
        ) {
          return `${
            cell.row.original.verified_status === 'yes' ? 'Yes' : 'No'
          }`;
        }
      },
      // <FormControl sx={{ m: 1, minWidth: 120 }} disabled={true}>
      //   <Select
      //     // defaultValue={'yes'}
      //     className='MuiOutlinedInput-root	'
      //     variant='standard'
      //     InputProps={{
      //       disableUnderline: false,
      //       readOnly: true,
      //     }}
      //     defaultValue={cell.row.original.verified_status || 'yes'}
      //     onChange={(e) => {
      //       let value = e.target.value;
      //       cell.row.original.verified_status = value;
      //     }}
      //   >
      //     <MenuItem value='yes'>Yes</MenuItem>
      //     <MenuItem value='no'>No</MenuItem>
      //   </Select>
      // </FormControl>
    },
  ];
  const options = [
    {
      id: 'all',
      status: 'All',
    },
    {
      id: 'approved',
      status: 'Approved',
    },
    {
      id: 'validated',
      status: 'Validated',
    },
    {
      id: 'under_review',
      status: 'Under Review',
    },
    {
      id: 'rejected',
      status: 'Rejected',
    },
  ];
  const [statusId, setStatusId] = useState('all');

  const handleStatusChange = (event) => {
    const id = event.target.value;
    setStatusId(id);
    getFinancingRequestsData(id);
  };
  const handleToFChange = (event) => {
    const id = event.target.value;
    setSource(id);
    // getValidationRequests(statusId);
    // getValidationRequests(id);
  };
  const getFinancingRequestsData = async (status) => {
    try {
      setIsLoading(true);
      const params = {
        page: paginationModel?.page + 1,
        size: paginationModel?.pageSize,
        status: status === 'all' ? '' : status,
        search: searchKeyword,
        sort_field: sortField,
        sort_order: sortOrder,
        source: source,
      };
      const result = await getFinancingRequests(params);
      if (result) {
        setValidationData(result?.data?.data?.data);
        setPageCount(parseInt(result?.data?.data?.total));
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getFinancingRequestsData(statusId);
  }, [source]);
  // const pagination = useMemo(
  //   () => ({
  //     pageIndex,
  //     pageSize,
  //   }),
  //   [pageIndex, pageSize]
  // );
  const handleChange = (event) => {
    const val = event.target.value;

    if (val !== '') {
      setApprovedAmount(loanAmount);

      setReason(val);
    }
  };
  useEffect(() => {
    getFinancingRequestsData(statusId);
    // eslint-disable-next-line
  }, [paginationModel, sortOrder, sortField, isClear]);

  const getRejectedReasonsData = async () => {
    try {
      const result = await getRejectedReasons();

      if (result) {
        setRejectedReasons(result?.data?.data);
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getRejectedReasonsData();
    // eslint-disable-next-line
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    let params = null;

    if (
      parseInt(approvedAmount) < 1 ||
      approvedAmount === null ||
      approvedAmount === undefined ||
      approvedAmount === '' ||
      tenure === '' ||
      tenure === null
    ) {
    }
    if (reason === 'approved') {
      params = {
        request_id: id,
        status: reason,
        approved_amount: approvedAmount,
        approved_tenure: tenure,
      };
    } else {
      params = {
        request_id: id,
        status: 'rejected',
        rejected_reason: reason,
      };
    }
    try {
      setIsLoadingSumbit(true);

      const result = await sumbitFinanceRequest(params);
      if (result) {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'success',
            message: result?.data?.message,
          })
        );
        // if (reason === 'approved') {
        getFinancingRequestsData(statusId);
        // }
        setValidationDetails([]);
        setId('');
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      setIsLoadingSumbit(false);
      setReason('');
      dispatch(getPendingNotifications());
    }
  };

  const ScrollToTop = () => {
    const element = document.getElementById('top');
    element.scrollIntoView(
      {
        behavior: 'smooth',
      },
      1000
    );
  };

  useEffect(() => {
    ScrollToTop();
  }, [validationData]);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageCount !== undefined ? pageCount : prevRowCountState
    );
  }, [pageCount, setRowCountState]);
  // useEffect(() => {
  //   if (searchKeyword.length >= 3) {
  //     getFinancingRequestsData(statusId);
  //   }
  // }, [searchKeyword]);
  return (
    <Grid container className={classes.root} sx={{ mb: 8 }}>
      <SnackBar />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Typography
            variant='h5'
            component='h3'
            sx={{ fontWeight: 700, px: 8, py: 4 }}
            id='top'
          >
            {id === ''
              ? 'Financing Requests'
              : id !== '' && validationDetails && validationDetails[0]?.sme_data
              ? validationDetails[0]?.sme_data
              : ''}
            {id !== '' && !validationDetailsLoading && !isLoading && (
              <Tooltip title={t('Verified_by_cbg')} placement='right' arrow>
                <ICONS.CHECKCIRCLE sx={{ color: 'green', mt: 1 }} />
              </Tooltip>
            )}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          {id !== '' && validationDetails && (
            <>
              <Button
                variant='outlined'
                sx={{ mr: 8 }}
                onClick={() => {
                  getFinancingRequestsData(statusId);
                  setValidationDetails([]);
                  dispatch(getPendingNotifications());
                  // dispatch(getPendingNotifications());
                  setId('');
                }}
              >
                {t('Back')}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      {id === '' && (
        <Grid container px={8} py={4} spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label='Search'
              placeholder='Search here'
              id='outlined-start-adornment'
              fullWidth
              // sx={{ m: 1, width: '25ch' }}
              value={searchKeyword}
              // sx={{ minWidth: isMobile ? 200 : 440, maxWidth: '100%' }}
              onKeyDown={(ev) => {
                // console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  // if (searchKeyword.length >= 3) {
                  getFinancingRequestsData(statusId);
                  // }
                }
              }}
              onChange={(e) => setSearchKeyword(e.target.value)}
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <ICONS.SEARCH />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    {searchKeyword !== '' && (
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => {
                          setSearchKeyword('');
                          setTimeout(() => {
                            setIsClear(!isClear);
                            // getValidationRequests(statusId);
                          }, 500);
                        }}
                        edge='end'
                      >
                        <ICONS.HIGHLIGHTSOFF
                          sx={{
                            color: 'black',
                            fontSize: '16px',
                          }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl
              // sx={{ minWidth: isMobile ? 235 : 410, maxWidth: '100%' }}
              fullWidth
              size='small'
            >
              <InputLabel id='statusId'>Status</InputLabel>
              <Select
                labelId='statusId'
                id='statusId-select'
                value={statusId}
                label='Status'
                fullWidth
                onChange={handleStatusChange}
              >
                {options.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      sx={{ whiteSpace: 'normal' }}
                    >
                      {item.status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl
              // sx={{ minWidth: isMobile ? 235 : 410, maxWidth: '100%' }}
              fullWidth
              size='small'
            >
              <InputLabel id='tof'>Type of Financing</InputLabel>
              <Select
                labelId='tof'
                id='tof-select'
                value={source}
                label='Type of Financing'
                fullWidth
                onChange={handleToFChange}
              >
                {sourceList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12} sx={{ px: 8, py: 4 }}>
        {id === '' && (
          <DataGrid
            sx={{
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-columnHeaders ': {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: '#fff',
                '& .MuiButtonBase-root.MuiIconButton-root ': {
                  color: '#fff',
                },
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
              },
              '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor': {
                // borderColor: `${theme.palette.primary.main}`,
              },

              '& .MuiDataGrid-columnSeparator ': {
                color: '#fff',
              },
              '& .MuiDataGrid-virtualScroller': {
                // border: `1px solid ${theme.palette.primary.main}`,
                border: `.25px solid grey`,
              },
            }}
            autoHeight
            rowHeight={62}
            rows={validationData}
            loading={isLoading}
            enableHiding={false}
            enableColumnActions={false}
            enableColumnFilterModes={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableGlobalFilter={false}
            enableTopToolbar={false}
            disableColumnMenu={true}
            // onRowClick={(row) => {
            //   // // console.log(row);
            //   if (row?.row?.total_applications > 0) {
            //     handleApplications(row?.row);
            //   }
            // }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            onSortModelChange={(newSortModel) => handleSort(newSortModel)}
            getRowId={(row) => row._id}
            columns={requestsColumns}
            disableRowSelectionOnClick
            rowCount={rowCountState}
            paginationMode='server'
            pageSizeOptions={[5, 10, 25, 50, 100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            columnBuffer={5}
          />
          // <MaterialReactTable
          //   columns={requestsColumns}
          //   data={validationData}
          //   enableHiding={false}
          //   enableDensityToggle={false}
          //   enableColumnActions={false}
          //   enableColumnFilters={false}
          //   enableFullScreenToggle={false}
          //   enableGlobalFilter={false}
          //   enableTopToolbar={false}
          //   manualPagination={true}
          //   rowCount={pageCount}
          //   state={{ pagination, isLoading }}
          //   onPaginationChange={setPagination}
          // />
        )}
        {id !== '' && validationDetailsLoading && isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {id !== '' && validationDetails && (
          <>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
              <Button
                variant='outlined'
                onClick={() => {
                  getFinancingRequestsData();
                  setValidationDetails([]);
                  setId('');
                }}
              >
                Back
              </Button>
            </Box> */}
            {id !== '' && !validationDetailsLoading && !isLoading && (
              <Grid
                container
                spacing={4}
                sx={{
                  mb: 2,
                }}
              >
                {id !== '' && loanAmount && (
                  <Grid
                    item
                    xs={12}
                    md={9}
                    lg={9}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          // display: 'flex',
                          // alignItems: 'center',
                          // alignContent: 'center',
                          // mr: 2,
                        }}
                      >
                        Loan Amount (GHS) :{' '}
                      </Typography>{' '}
                      {parseFloat(loanAmount)
                        .toFixed(2)

                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Typography>
                  </Grid>
                )}

                {/* </Box> */}
              </Grid>
            )}
            {id !== '' && (
              <Grid
                container
                spacing={4}
                sx={{
                  mb: 4,
                }}
              >
                {facilityType && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Typography sx={{ display: 'flex' }}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          display: 'flex',
                          // mr: 2,
                        }}
                      >
                        {'  '}
                        Facility type :{' '}
                      </Typography>
                      {'  '} {facilityType}
                    </Typography>
                  </Grid>
                )}
                {loanTenure && (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={3}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Typography sx={{ display: 'flex' }}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          display: 'flex',
                          // flexDirection: 'row',
                          // mr: 2,
                        }}
                      >
                        {'  '}
                        Loan Tenure :{' '}
                      </Typography>
                      {'  '} {loanTenure}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            {id !== '' &&
              !validationDetailsLoading &&
              !isLoading &&
              !actionRequired && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ my: 4, mr: 8 }}
                  className={classes.alert}
                >
                  {/* <Box display='flex' justifyContent='stretch' mb={2}> */}
                  <Typography

                  // sx={{ fontWeight: 700, px: 8, py: 4 }}
                  >
                    <Alert
                      sx={{ width: 1 }}
                      className={
                        alertType?.status === 'rejected' ? 'error' : 'success'
                      }
                      severity={
                        alertType?.status === 'rejected' ? 'error' : 'success'
                      }
                      iconMapping={{
                        success: '',
                        error: '',
                      }}
                    >
                      <Typography sx={{ fontWeight: 700, pb: 2 }}>
                        {alertType?.status === 'rejected'
                          ? 'Loan Rejected'
                          : 'Loan Approved'}
                      </Typography>
                      <Grid container spacing={4} sx={{ width: 1 }}>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                          <Typography sx={{ fontSize: 14, pb: 2, width: 450 }}>
                            {alertType?.status === 'rejected'
                              ? alertType?.rejected_reason
                              : `Approved Amount : ${parseFloat(
                                  alertType?.approved_amount
                                )
                                  .toFixed(2)
                                  ?.toString()
                                  .replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )} GHS    `}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                          {alertType?.status !== 'rejected' && (
                            <Typography sx={{ fontSize: 14, pb: 2 }}>
                              {'  '} Approved Tenure :{' '}
                              {alertType?.approved_tenure}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ fontSize: 14, pb: 2 }}>
                          {alertType?.status === 'rejected'
                            ? alertType?.rejected_reason
                            : `Approved Amount : ${parseFloat(
                                alertType?.approved_amount
                              )
                                .toFixed(2)
                                ?.toString()
                                .replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )} GHS    `}
                        </Typography>
                        {alertType?.status !== 'rejected' && (
                          <Typography sx={{ fontSize: 14, pb: 2, ml: 4 }}>
                            {'  '} Approved Tenure :{' '}
                            {alertType?.approved_tenure}
                          </Typography>
                        )}
                      </Box> */}
                    </Alert>
                  </Typography>
                  {/* </Box> */}
                </Grid>
              )}

            <MaterialReactTable
              columns={detailsColumns}
              data={validationDetails}
              enableHiding={false}
              enableDensityToggle={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableFullScreenToggle={false}
              enableGlobalFilter={false}
              enableTopToolbar={false}
              enablePagination={false}
              // manualPagination={true}
              // rowCount={pageCount}
              state={{ isLoading }}
              // onPaginationChange={setPagination}
            />
          </>
        )}
        {id !== '' &&
          !validationDetailsLoading &&
          !isLoading &&
          actionRequired &&
          !isDBG &&
          !isChannel &&
          !isBDF &&
          !isBNR && (
            <>
              <Typography
                variant='h5'
                component='h3'
                sx={{ fontWeight: 700, pt: 8, pb: 2, mb: 8 }}
              >
                Action
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                  <Box display='flex' justifyContent='flex-start'>
                    <FormControl
                      sx={{
                        width: 1,
                        maxWidth: '100%',
                        // minWidth: isMobile ? 200 : 410,
                        // maxWidth: '100%',
                      }}
                    >
                      <InputLabel id='reason'>Actions</InputLabel>
                      <Select
                        labelId='reason'
                        id='reason-select'
                        value={reason}
                        label='Select'
                        fullWidth
                        onChange={(e) => handleChange(e)}
                        sx={{}}
                      >
                        <MenuItem key={1} value='approved'>
                          Approve
                        </MenuItem>
                        {rejectedReasons.map((item) => {
                          return (
                            <MenuItem
                              key={item._id}
                              value={item.name}
                              sx={{ whiteSpace: 'normal' }}
                            >
                              Reject - {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  {reason === 'approved' && (
                    <Box display='flex' justifyContent='flex-start'>
                      <FormControl
                        sx={{
                          // minWidth: isMobile ? 200 : 280,
                          width: 1,
                          maxWidth: '100%',
                        }}
                      >
                        {/* <InputLabel id='approvedAmount'>{t('Amount')}</InputLabel>s */}
                        <TextField
                          inputProps={{
                            'data-testid': 'signup-email',
                          }}
                          fullWidth
                          required
                          // labelId='approvedAmount'
                          label={`${t('Amount')} (GHS)`}
                          name='approvedAmount'
                          // type='number'
                          // sx={{ my: 2 }}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                          error={
                            parseInt(approvedAmount) < 1 ||
                            approvedAmount === null ||
                            approvedAmount === undefined ||
                            approvedAmount === '' ||
                            parseInt(approvedAmount) > parseInt(loanAmount)
                          }
                          value={approvedAmount}
                          onChange={(e) => {
                            // const re = /^[0-9\b]+$/;
                            // if (re.test(e.target.value) || e.target.value.length===1) {
                            setApprovedAmount(
                              e.target.value
                                ?.trimStart()
                                .replace(/\s\s+/g, '')
                                .replace(/[^0-9.]+/g, '')
                            );
                            // }
                          }}
                          helperText={
                            parseInt(approvedAmount) < 1 ||
                            approvedAmount === null ||
                            approvedAmount === undefined ||
                            approvedAmount === '' ||
                            (parseInt(approvedAmount) > parseInt(loanAmount) &&
                              t('Approved_Amount_Validation'))
                          }
                        />
                      </FormControl>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  {reason === 'approved' && (
                    <Box display='flex' justifyContent='flex-start'>
                      <FormControl
                        sx={{
                          width: 1,
                          maxWidth: '100%',
                        }}
                        fullWidth
                        // size='small'
                      >
                        <InputLabel id='tebure'>Tenure *</InputLabel>
                        <Select
                          labelId='tebure'
                          id='tebure-select'
                          value={tenure}
                          label='Tenure *'
                          fullWidth
                          onChange={(e) => setTenure(e.target.value)}
                        >
                          {tenureList?.map((item) => {
                            return (
                              <MenuItem
                                key={item}
                                value={item}
                                sx={{ whiteSpace: 'normal' }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          Please note loan tenure is between 1-12 months.
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {reason !== '' && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: {
                          xs: 'flex-start',
                          md: 'flex-start',
                          lg: 'flex-end',
                          xl: 'flex-end',
                        },
                      }}
                      mt={4}
                    >
                      <FormControl
                        sx={{
                          minWidth: 200,
                          width: {
                            xs: 1,
                            md: 1,
                            lg: 0.1,
                            xl: 0.1,
                          },
                        }}
                      >
                        <LoadingButton
                          loading={isLoadingSumbit}
                          disabled={
                            parseInt(approvedAmount) < 1 ||
                            approvedAmount === null ||
                            approvedAmount === undefined ||
                            approvedAmount === '' ||
                            parseInt(approvedAmount) > parseInt(loanAmount) ||
                            isLoadingSumbit ||
                            tenure === '' ||
                            tenure === null
                          }
                          fullWidth
                          variant='contained'
                          onClick={handleSubmit}
                          sx={{
                            // my: 2,
                            textTransform: 'none',
                            '& .MuiCircularProgress-root ': {
                              color: '#ffff',
                            },
                          }}
                        >
                          {t('Submit')}
                        </LoadingButton>
                      </FormControl>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        {
          // <Grid item xs={12} md={12} lg={12} sx={{}}>
          //   <Box display='flex' justifyContent='flex-start' mt={4}>
          //     <Typography>Note: All the amounts are in GHS</Typography>
          //   </Box>
          // </Grid>
        }
      </Grid>
    </Grid>
  );
}

export default Finance;
